import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-flohh-text-body" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "flex justify-between items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_HeadedModal = _resolveComponent("HeadedModal")!

  return (_openBlock(), _createBlock(_component_HeadedModal, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
    onOnClose: _ctx.handleModalClose,
    title: _ctx.title,
    width: "35vw"
  }, {
    body: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, [
        _createElementVNode("span", { innerHTML: _ctx.message }, null, 8, _hoisted_2)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args))),
          class: "text-flohh-text-body font-flohh-font-medium"
        }, _toDisplayString(_ctx.cancelButtonLabel), 1),
        _createVNode(_component_PrimaryButton, {
          label: _ctx.confirmButtonLabel,
          onClick: _ctx.handleConfirm,
          class: "!ml-auto !mr-0 !text-flohh-text-body",
          color: "flohh-secondary-green"
        }, null, 8, ["label", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onOnClose", "title"]))
}