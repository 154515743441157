<template>
  <div
    v-if="activePlan && plans"
    class="flex flex-col justify-center flex-1 gap-6 py-12 px-14"
  >
    <PlanInfoComponent :activePlan="activePlan" />
    <hr class="border-flohh-neutral-70" />
    <UpgradePlanComponent
      v-if="activePlan.subscription.plan.slug === 'free'"
      :plans="plans"
      :activePlan="activePlan"
    />
    <SeatCountComponent
      v-if="activePlan.subscription.plan.slug !== 'free'"
      :activePlan="activePlan"
      :plans="plans"
    />
    <hr
      v-if="activePlan.subscription.plan.slug !== 'free'"
      class="border-flohh-neutral-70"
    />
    <BillingCycleComponent
      v-if="activePlan.subscription.plan.slug !== 'free'"
      :activePlan="activePlan"
    />
    <hr
      v-if="activePlan.subscription.plan.slug !== 'free'"
      class="border-flohh-neutral-70"
    />
    <CancelSubscriptionComponent :activePlan="activePlan" />
  </div>
  <div v-else class="grid py-40 place-items-center">
    <ProgressLoader />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import { ActivePlan, Plan } from "@/components/PlanAndBilling/type";
import { useActivePlanStore } from "@/stores/activePlan";
import { usePlansStore } from "@/stores/plans";

import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import FormInput from "@/components/utilities/AppFormFields/FormInput.vue";
import SeatCountComponent from "@/components/Manage/SeatCount/SeatCountComponent.vue";
import BillingCycleComponent from "@/components/Manage/BillingCycle/BillingCycleComponent.vue";
import PlanInfoComponent from "@/components/Manage/PlanInfo/PlanInfoComponent.vue";
import CancelSubscriptionComponent from "@/components/PlanAndBilling/CancelSubscription/CancelSubscriptionComponent.vue";
import UpgradePlanComponent from "@/components/PlanAndBilling/UpgradePlan/UpgradePlanComponent.vue";

@Component({
  components: {
    ProgressLoader,
    FormInput,
    SeatCountComponent,
    BillingCycleComponent,
    PlanInfoComponent,
    CancelSubscriptionComponent,
    UpgradePlanComponent,
  },
})
export default class ManageComponent extends Vue {
  plansStore = usePlansStore();
  activePlanStore = useActivePlanStore();
  plans!: Plan[];
  activePlan!: ActivePlan | null;

  @Watch("plansStore.plans")
  plansWatcher(plans: Plan[]) {
    this.plans = plans;
  }

  @Watch("activePlanStore.activePlan")
  activePlanWatcher(activePlan: ActivePlan) {
    if (activePlan?.ownershipType === "consumer") {
      this.$router.push({ name: "Members" });
    } else {
      this.activePlan = activePlan;
    }
  }

  created() {
    this.fetchPlans();
  }

  async fetchPlans() {
    this.plans = await this.plansStore.fetchPlans();

    const activePlan = await this.activePlanStore.fetchActivePlan();
    if (activePlan?.ownershipType === "consumer") {
      this.$router.push({ name: "Members" });
    } else {
      this.activePlan = activePlan;
    }
  }
}
</script>

<style scoped lang="scss">
.heading-bg {
  background: linear-gradient(
    133.87deg,
    #ffde7f 10.94%,
    #f4cddc 49.43%,
    #cec3ff 61.14%,
    #abead9 96.06%
  );
  position: relative;
  flex: 1;
  display: grid;
  place-items: center;
  padding: 24px;
}
</style>
