import axios from "axios";
import { UpdatePassword } from "@/components/Account/type";

const AUTH_BASE_URL = "/authentication";
const PASSWORD_URL = `${AUTH_BASE_URL}/password`;
const UPDATE_PASSWORD = "/users/update-password";

export default class AuthenticationService {
  logout() {
    return axios.post("/accounts/logout");
  }

  login(email: string, password: string) {
    const userType = "teacher";
    const authenticatingAs = userType;
    return axios.post(`${AUTH_BASE_URL}/login`, {
      email,
      password,
      userType,
      authenticatingAs,
    });
  }

  sendOTPAccess(token: string) {
    return axios.post(
      "/accounts/request-access",
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  }

  verifyOTP(
    code: string,
    token: string,
    type = "teacher_account_portal_access"
  ) {
    return axios.post(`${AUTH_BASE_URL}/otp/verify`, {
      code,
      type,
      token,
    });
  }

  requestResetPassword(email: string) {
    return axios.post(`${PASSWORD_URL}/request-reset`, { email });
  }

  resetPassword(token: string, password: string) {
    return axios.post(`${PASSWORD_URL}/reset`, { token, password });
  }

  onUpdatePassword(payload: UpdatePassword) {
    return axios.put(UPDATE_PASSWORD, payload);
  }
}
