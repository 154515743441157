<template>
  <div class="w-full relative">
    <InputText
      v-model="value"
      :type="type"
      :placeholder="placeholder"
      :style="{
        outline: 'none',
        boxShadow: 'none',
        paddingLeft: `${iconPosition === 'left' ? '55px' : '10px'}`,
        paddingRight: `${iconPosition === 'right' ? '55px' : '10px'}`,
      }"
      class="border-flohh-neutral-70 rounded-lg border-solid border outline-none py-[8px] text-flohh-neutral-20 w-full"
      v-bind="$attrs"
    />
    <div
      class="absolute left-[24px] top-[50%] translate-y-[-50%] w-4"
      v-if="iconPosition === 'left'"
    >
      <span v-html="icons[icon]"></span>
    </div>
    <div v-else class="absolute right-[24px] top-[50%] translate-y-[-50%] w-4">
      <span v-html="icons[icon]"></span>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Model } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import { icons } from "@/const/icons";

@Component({ components: { Text, InputText } })
export default class IconizeInput extends Vue {
  icons = icons;
  @Model({
    type: String,
    default: "",
    required: true,
  })
  value!: string;

  @Prop({
    type: String,
    default: "text",
    validator: (value) => {
      return ["text", "number", "email"].includes(value);
    },
  })
  type!: string;

  @Prop({
    type: String,
    default: "",
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: "",
  })
  icon!: string;

  @Prop({
    type: String,
    default: "left",
  })
  iconPosition!: "left" | "right";
}
</script>
