<template>
  <nav v-if="expanded" class="px-0 pt-[16px] h-full">
    <ul
      role="list"
      class="flex flex-1 flex-col gap-[2px] w-full relative mb-[32px]"
    >
      <li v-for="(navItem, i) in navigation" :key="i" class="relative w-full">
        <div
          v-if="!navItem.hidden"
          class="text-nowrap cursor-pointer w-full group flex gap-x-3 items-center rounded-md py-[6px] px-[12px] font-flohh-font-medium text-flohh-text-body hover:bg-flohh-primary-pink"
          :class="[
            {
              'bg-flohh-primary-pink': navItem.link === $route.path,
            },
          ]"
          @click="handleNavigate(navItem.link)"
        >
          <span
            v-html="icons[navItem.icon]"
            aria-hidden="true"
            class="[&>svg]:w-[24px] [&>svg]:h-[auto]"
          />
          {{ navItem.label }}
        </div>
      </li>
    </ul>
  </nav>

  <nav v-else class="flex flex-col items-center gap-[32px] px-0 pt-[16px]">
    <ul role="list" class="flex flex-1 flex-col gap-[2px] w-full relative">
      <div id="sub-menu-overlay" class="relative"></div>
      <li v-for="(navItem, i) in navigation" :key="i" class="relative w-full">
        <div
          v-if="!navItem.hidden"
          class="cursor-pointer w-full group flex justify-start py-[6px] px-[12px] rounded-lg hover:bg-flohh-primary-pink"
          :class="[
            {
              'bg-flohh-primary-pink': navItem.link === $route.path,
            },
          ]"
          v-tooltip="navItem.label"
          @click="handleNavigate(navItem.link)"
          id="menu-item"
        >
          <span
            v-html="icons[navItem.icon]"
            aria-hidden="true"
            class="[&>svg]:w-[24px] [&>svg]:h-[auto]"
          />
        </div>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import { icons } from "@/const/icons";
import { Navigation } from "../type";

@Component({
  components: {},
})
export default class SidePanelMenus extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  navigation!: Navigation[];

  @Prop({
    type: Boolean,
    required: true,
  })
  expanded!: boolean;

  icons = icons;

  navItemHovered = "";

  handleNavigate(link: string) {
    if (link.includes("http")) {
      window.location.href = link;
    } else {
      this.$router.push({ path: link });
    }
  }
}
</script>

<style lang="scss" scoped></style>
