<template>
  <div class="" v-show="activePlan.ownershipType === 'consumer'">
    <p class="mb-2 text-flohh-text-title font-flohh-font-bold">Leave team</p>
    <p class="mb-3 text-black text-flohh-text-body">
      This action will disconnect you from the team account, and you will lose
      access to shared resources and projects.
    </p>
    <PrimaryButton
      label="Leave Team Account"
      color="flohh-secondary-red"
      @click="dialogModalOpen = true"
      :loading="isLeaving"
      :disabled="isLeaving"
    />
  </div>
  <DialogModal
    v-model="dialogModalOpen"
    title="Leave Team Account?"
    message="Are you sure you want to leave this team? <br/>By leaving, you will lose access to all shared resources, projects, and team-specific features and you will be downgraded to <strong>Basic</strong>"
    @onConfirm="handleLeaveTeam"
    @onCancel="dialogModalOpen = false"
    @onClose="dialogModalOpen = false"
  />
  <SuccessModal
    v-model="successModalOpen"
    title="You have successfully left the team"
    subtitle="You no longer have access to the team plan. If there was a mistake or you wish to rejoin, please contact the subscription owner"
    @onClose="successModalOpen = false"
  />
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-facing-decorator";
import { ActivePlan, OwnerInfo } from "@/components/PlanAndBilling/type";
import MemberService from "@/services/MemberService";
import { AxiosResponse } from "axios";
import { useActivePlanStore } from "@/stores/activePlan";

import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SuccessModal from "@/components/utilities/AppModals/SuccessModal.vue";
import DialogModal from "@/components/utilities/AppModals/DialogModal.vue";

@Component({
  components: { PrimaryButton, SuccessModal, DialogModal },
})
export default class LeaveTeamComponent extends Vue {
  private memberService: MemberService = new MemberService();
  activePlanStore = useActivePlanStore();

  @Prop({
    required: true,
  })
  activePlan!: ActivePlan;

  owner!: OwnerInfo;
  isLeaving = false;
  successModalOpen = false;
  dialogModalOpen = false;

  @Watch("activePlan")
  activePlanWatcher(activePlan: ActivePlan) {
    this.setOwner(activePlan);
  }

  mounted() {
    this.setOwner(this.activePlan);
  }

  async handleLeaveTeam() {
    try {
      this.dialogModalOpen = false;
      this.isLeaving = true;

      const response: AxiosResponse =
        await this.memberService.leaveAsConsumer();

      if (response.data.ok) {
        await this.activePlanStore.fetchActivePlan(true);
        this.successModalOpen = true;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLeaving = false;
    }
  }

  setOwner(activePlan: ActivePlan) {
    this.owner = activePlan.owner;
  }
}
</script>

<style scoped lang="scss"></style>
