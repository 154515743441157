import { Consumer } from "@/components/PlanAndBilling/type";
/*
    USAGE:
    
    const consumers = [
        {
            name: "Foo Bar",
            price: 18,
            status: "active",
            urn: "<urn>"
        }, 
        {
            price: 18,
            status: "vacant",
        }
    ];

    getSeatCount(consumers)

    Return Type: Object
    Return Value: { occupied: 1, total: 2 }
*/
interface ReturnValue {
  occupied: number;
  total: number;
}

export const getSeatCount = (consumers: Consumer[]): ReturnValue => {
  const totalSeats = consumers.length as number;
  const occupied = consumers.filter(
    (consumer: Consumer) =>
      consumer.status === "active" || consumer.status === "reserved"
  );
  const occupiedSeats = occupied.length as number;

  return { occupied: occupiedSeats, total: totalSeats };
};

/*
    USAGE:
    
    const consumers = [
        {
            name: "Foo Bar",
            price: 18,
            status: "active",
            urn: "<urn>"
        }, 
        {
            price: 18,
            status: "vacant",
        }
    ];

    getSeatCount(consumers)

    Return Type: string
    Return Value: "1/2"
*/
interface ReturnValue {
  occupied: number;
  total: number;
}

export const formatSeatCount = (consumers: Consumer[]): string => {
  const totalSeats = consumers.length as number;
  const occupied = consumers.filter(
    (consumer: Consumer) =>
      consumer.status === "active" || consumer.status === "reserved"
  );
  const occupiedSeats = occupied.length as number;

  return `${occupiedSeats}/${totalSeats}`;
};
