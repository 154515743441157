import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full gap-2" }
const _hoisted_2 = { class: "relative w-full" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "ml-1 text-flohh-error-red text-flohh-text-caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_Text, {
          key: 0,
          color: "text-flohh-neutral-35",
          size: "body",
          weight: "md"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InputText, _mergeProps({
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        type: _ctx.inputType,
        placeholder: _ctx.placeholder,
        style: {
          outline: 'none',
          boxShadow: 'none',
        },
        class: ["border-flohh-neutral-70 text-flohh-text-body rounded-lg border-solid border outline-none py-2 text-flohh-neutral-20 w-full pl-[10px] pr-[55px]", [
          {
            'p-invalid': _ctx.errorMessage && _ctx.required,
          },
        ]]
      }, _ctx.$attrs), null, 16, ["modelValue", "type", "placeholder", "class"]),
      (_ctx.inputType === 'password')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "absolute right-[24px] top-[50%] translate-y-[-50%] w-4 cursor-pointer bg-white px-4",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleToggleVisibility && _ctx.handleToggleVisibility(...args))),
            type: "button"
          }, [
            _createElementVNode("span", {
              innerHTML: _ctx.icons.eyeIcon
            }, null, 8, _hoisted_3)
          ]))
        : (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "absolute right-[24px] top-[50%] translate-y-[-50%] w-4 cursor-pointer bg-white px-4",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleToggleVisibility && _ctx.handleToggleVisibility(...args))),
            type: "button"
          }, [
            _createElementVNode("span", {
              innerHTML: _ctx.icons.passwordEyeVisibleIcon
            }, null, 8, _hoisted_4)
          ]))
    ]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}