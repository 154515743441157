<template>
  <HeadedModal
    v-model="open"
    @onClose="handleModalClose"
    title="Invite Teachers"
    width="40vw"
  >
    <template #body>
      <p class="mb-2 text-flohh-text-body font-flohh-font-medium">
        Teachers' Emails
      </p>
      <div
        class="flex flex-wrap gap-2 p-2 mb-2 border border-solid rounded-lg border-flohh-neutral-85 cursor-text"
        :class="[
          {
            'border-flohh-secondary-red': errorMessage,
          },
        ]"
        @click="handleFieldClick"
      >
        <div
          v-for="(email, index) in emails"
          :key="index"
          class="flex items-center gap-2 px-4 py-2 rounded-md cursor-default bg-flohh-primary-light-pink"
        >
          <span v-html="icons.personIconSmallBlack" />
          <span class="text-flohh-text-caption font-flohh-font-medium">{{
            email
          }}</span>
          <button
            @click="handleRemoveEmail(index)"
            class="cursor-pointer remove-button"
          >
            <span v-html="icons.closeIconSmallBlack" />
          </button>
        </div>
        <input
          v-if="emails.length !== availableSeats || emails.length === 0"
          v-model="inputText"
          placeholder="Enter email"
          class="border-none outline-none text-flohh-text-caption font-flohh-font-medium min-w-[70px]"
          type="text"
          @blur="handleAddEmail"
          @keyup="handleKeyUp"
          @paste="handlePaste"
          :style="`width: ${(inputText.length + 1) * 8 + 'px'}`"
          ref="emailInput"
        />
      </div>
      <p
        v-if="errorMessage"
        class="ml-1 text-flohh-error-red text-flohh-text-caption"
      >
        {{ errorMessage }}
      </p>
    </template>
    <template #footer>
      <PrimaryButton
        label="Send Invitation"
        @click="handleInvite"
        class="!ml-auto !mr-0 !text-flohh-text-body"
        color="flohh-secondary-green"
        :disabled="isInviting"
        :loading="isInviting"
      />
    </template>
  </HeadedModal>
  <HeadedModal
    v-model="inviteFailedModalOpen"
    title="Invitation Failed"
    @onClose="inviteFailedModalOpen = false"
  >
    <template #body>
      <p class="text-flohh-text-subtitle">
        Failed to invite the following emails:
      </p>
      <ul class="list-disc pl-[1.5em]">
        <li v-for="(item, index) in failedInvitations" :key="index">
          <p class="text-flohh-text-body">
            <strong>{{ item.details.email }}</strong>
            ({{ item.message }})
          </p>
        </li>
      </ul></template
    >
  </HeadedModal>
  <SuccessModal
    v-model="inviteSuccessModalOpen"
    :title="`${formatWord(successInviteCount, 'Invitation')} Sent`"
    :subtitle="`Your invite was sent to ${successInviteCount} ${formatWord(
      successInviteCount,
      'teacher'
    )}.`"
    @onClose="inviteSuccessModalOpen = false"
  />
</template>

<script lang="ts">
import { Component, Vue, Model, Prop, Watch, Ref } from "vue-facing-decorator";
import { icons } from "@/const/icons";
import MemberService from "@/services/MemberService";
import { AxiosResponse } from "axios";
import { formatWord } from "@/utils/pluralFormatter";
import { useActivePlanStore } from "@/stores/activePlan";

import HeadedModal from "@/components/utilities/AppModals/HeadedModal.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SuccessModal from "@/components/utilities/AppModals/SuccessModal.vue";

interface FailedInvitations {
  ok: boolean;
  message: string;
  code: string;
  details: {
    email: string;
  };
}

@Component({
  components: { HeadedModal, PrimaryButton, SuccessModal },
})
export default class InviteTeacherModal extends Vue {
  private memberService: MemberService = new MemberService();
  icons = icons;
  activePlanStore = useActivePlanStore();

  @Model({
    type: Boolean,
    default: false,
    required: true,
  })
  open!: boolean;

  @Prop({
    type: Number,
    default: 0,
    required: true,
  })
  availableSeats!: number;

  inviteSuccessModalOpen = false;
  inviteFailedModalOpen = false;

  emails: string[] = [];
  inputText = "";
  errorMessage = "";
  isInviting = false;
  successInviteCount = 0;

  failedInvitations: FailedInvitations[] = [];

  @Ref("emailInput")
  emailInput!: HTMLInputElement;

  @Watch("inputText")
  availableSeatsWatcher() {
    this.errorMessage = "";
  }

  validatePayload() {
    if (this.emails.length === 0) {
      this.errorMessage = "Invalid email address";
      return false;
    } else if (this.emails.length > this.availableSeats) {
      `You have only paid for ${this.availableSeats} seats. If you would like more, please pay for more on the Plans & Billing page under “Manage Subscription”`;
      return false;
    }

    return true;
  }

  async handleInvite() {
    this.isInviting = true;
    try {
      const isValid = this.validatePayload();
      if (!isValid) {
        return;
      }

      const response: AxiosResponse = await this.memberService.inviteTeachers(
        this.emails
      );

      if (response.data.ok) {
        this.handleModalClose();
        this.handleResponse(response);

        await this.activePlanStore.fetchActivePlan(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.emails = [];
      this.isInviting = false;
    }
  }

  handleResponse(response: AxiosResponse) {
    const data = response.data.data;

    const failedInvites = data.filter((item: FailedInvitations) => !item.ok);

    if (failedInvites.length > 0) {
      this.failedInvitations = failedInvites;
      this.inviteFailedModalOpen = true;
    }

    const successInvites = data.filter(
      (item: Record<string, unknown>) => item.ok
    );

    if (successInvites.length > 0) {
      this.successInviteCount = successInvites.length;
      this.inviteSuccessModalOpen = true;
    }
  }

  handleAddEmail() {
    if (this.emails.length === this.availableSeats) {
      this.errorMessage = `You have only paid for ${this.availableSeats} seats. If you would like more, please pay for more on the Plans & Billing page under “Manage Subscription”`;
    } else {
      const isEmailValid = this.validateEmail(this.inputText);
      if (isEmailValid) {
        const email = this.inputText.replace(",", "").trim();
        if (email && !this.emails.includes(email)) {
          this.emails.push(email);
        }
        this.inputText = "";
      } else {
        this.errorMessage = "Invalid email address";
      }
    }
  }

  handleRemoveEmail(index: number) {
    this.emails.splice(index, 1);
    this.errorMessage = "";
  }

  handleKeyUp(event: KeyboardEvent) {
    if (event.key === "Enter" || event.key === " " || event.key === ",") {
      this.handleAddEmail();
    }
  }

  handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData("text") || "";
    const splitEmails = pastedText.split(/[\s,]+/);

    splitEmails.forEach((email: string) => {
      const trimmedEmail = email.trim();
      if (trimmedEmail && !this.emails.includes(trimmedEmail)) {
        const isEmailValid = this.validateEmail(trimmedEmail);
        if (isEmailValid) {
          if (this.emails.length > this.availableSeats) {
            this.errorMessage = `You have only paid for ${this.availableSeats} seats. If you would like more, please pay for more on the Plans & Billing page under “Manage Subscription”`;
          } else {
            this.emails.push(trimmedEmail);
          }
        } else {
          this.errorMessage = "Invalid email address not pasted";
        }
      }
    });

    this.inputText = "";
  }

  validateEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !email ? true : regex.test(email);
  }

  handleFieldClick() {
    this.emailInput.focus();
  }

  handleModalClose() {
    this.$emit("onClose", false);
  }

  formatWord(count: number, word: string) {
    return formatWord(count, word);
  }
}
</script>

<style scoped lang="scss"></style>
