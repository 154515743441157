import { defineStore } from "pinia";
import { ref } from "vue";
import { ICountry } from "@/const/type";
import { AxiosResponse } from "axios";
import AccountService from "@/services/AccountService";

const accountService = new AccountService();
const lastFetch = ref<number>(Date.now());
const CACHE_DURATION = 300000; // 5 minutes

export const countriesStore = defineStore("countries", () => {
  const countries = ref<ICountry[]>([]);
  const isLoading = ref(false);
  const error = ref<string | null>(null);

  const fetchCountries = async () => {
    const now = Date.now();
    if (countries.value.length > 0 && now - lastFetch.value < CACHE_DURATION) {
      return countries.value;
    }

    isLoading.value = true;
    error.value = null;
    try {
      const response: AxiosResponse = await accountService.fetchCountries();
      countries.value = response.data.data;
      lastFetch.value = Date.now();
    } catch (err) {
      error.value = "Failed to fetch countries";
      console.error(err);
    } finally {
      isLoading.value = false;
    }
    return countries.value;
  };

  return {
    countries,
    isLoading,
    error,
    fetchCountries,
  };
});
