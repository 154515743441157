<template>
  <div class="pb-[40px]">
    <div class="mb-6">
      <HeadingSix
        :label="pageTitle"
        weight="700"
        color="text-flohh-neutral-20"
      />
    </div>
    <hr class="mb-6" />
    <div
      v-if="
        selected === 'Members' &&
        activePlan &&
        (activePlan.subscription.plan.slug === 'teams' ||
          activePlan.subscription.plan.slug === 'organisation')
      "
      class="mb-10 banner"
    >
      <div class="p-10 bg-white rounded-xl">
        <div class="flex justify-between gap-2 mb-3">
          <div class="text-black">
            <h6 class="mb-2 text-flohh-h6 font-flohh-font-bold">
              An aligned team means better student outcomes
            </h6>
            <p class="mb-4 text-flohh-text-body">
              Invite more teachers on your Flohh plan to streamline grading and
              insights across your school.
            </p>
            <div class="flex items-center gap-1 mb-2">
              <img
                src="@/assets/banner-stay-consistent.svg"
                alt="Banner Image"
              />
              <p class="text-flohh-text-body">
                <strong>Stay consistent:</strong> Keep grading standards and
                learning goals aligned across all teachers.
              </p>
            </div>
            <div class="flex items-center gap-1 mb-2">
              <img src="@/assets/banner-save-time.svg" alt="Banner Image" />
              <p class="text-flohh-text-body">
                <strong>Save time:</strong> Grade formative and summative
                assessments—handwritten or digital—all in one place.
              </p>
            </div>
            <div class="flex items-center gap-1 mb-2">
              <img src="@/assets/banner-spot-needs.svg" alt="Banner Image" />
              <p class="text-flohh-text-body">
                <strong>Spot student needs faster:</strong> Access real-time
                data to support students effectively.
              </p>
            </div>
          </div>
          <img src="@/assets/banner-img.png" alt="Banner Image" />
        </div>
        <PrimaryButton
          label="Invite teachers"
          color="flohh-primary-light-pink"
          @click="handleInviteTeachers"
          class="w-full !text-flohh-text-body"
        />
      </div>
    </div>
    <div class="flex w-full border-b-4 border-flohh-neutral-70">
      <div v-for="(item, index) in menus" :key="index" class="relative">
        <div
          v-if="!item.hidden"
          class="py-[18px] px-[24px] cursor-pointer"
          @mouseenter="() => (hovered = item.name)"
          @mouseout="() => (hovered = '')"
          @click="handleNavigate(item.name)"
        >
          <Text
            v-if="item.name === selected || item.name === hovered"
            :label="item.label"
            weight="bd"
            type="subtitle"
            color="text-flohh-primary-pink"
          />
          <Text
            v-else
            :label="item.label"
            variant="bd"
            type="subtitle"
            color="text-flohh-neutral-70"
          />
        </div>
        <div
          v-if="item.name === selected || item.name === hovered"
          class="w-full h-[4px] bg-flohh-primary-pink absolute bottom-[-4px]"
        ></div>
      </div>
    </div>
  </div>

  <AbandonedCartComponent />

  <InviteTeacherModal
    v-model="inviteTeacherModalOpen"
    :availableSeats="availableSeats"
    @onClose="inviteTeacherModalOpen = false"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import { IAccountsNav } from "@/const/type";
import { useActivePlanStore } from "@/stores/activePlan";
import { ActivePlan } from "@/components/PlanAndBilling/type";
import { getSeatCount } from "@/utils/seatCountFormatter";
import { getNavigationList } from "@/const/accountsNav";

import Text from "@/components/utilities/AppTypographies/Text.vue";
import HeadingSix from "@/components/utilities/AppTypographies/HeadingSix.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import InviteTeacherModal from "@/components/Members/InviteTeacherModal.vue";
import AbandonedCartComponent from "@/components/PlanAndBilling/AbandonedCart/AbandonedCartComponent.vue";

@Component({
  components: {
    HeadingSix,
    Text,
    PrimaryButton,
    InviteTeacherModal,
    AbandonedCartComponent,
  },
})
export default class PersonAvatar extends Vue {
  activePlanStore = useActivePlanStore();
  activePlan!: ActivePlan | null;

  @Prop({
    type: String,
    default: "MyAccount",
  })
  selected!: string; // Route Name

  menus: IAccountsNav[] = [];

  hovered = "";
  pageTitle = "My Account";
  inviteTeacherModalOpen = false;
  availableSeats = 0;

  @Watch("activePlanStore.activePlan")
  activePlanWatcher(activePlan: ActivePlan) {
    this.activePlan = activePlan;
    this.setSeatCount(activePlan);
  }

  async fetchPlans() {
    this.activePlan = this.activePlanStore.activePlan;
    this.setSeatCount(this.activePlan);
  }

  created() {
    const selected = this.menus.find(
      (item: IAccountsNav) => item.name === this.selected
    );
    this.pageTitle = selected ? selected.label : "";
    this.fetchPlans();

    this.menus = getNavigationList();
  }

  handleInviteTeachers() {
    this.inviteTeacherModalOpen = true;
  }

  setSeatCount(activePlan: ActivePlan | null) {
    if (activePlan) {
      const seatCount = getSeatCount(activePlan.subscription.consumers);
      this.availableSeats = seatCount.total - seatCount.occupied;
    }
  }

  handleNavigate(routeName: string) {
    this.$router.push({
      name: routeName,
    });
  }
}
</script>

<style scoped lang="scss">
.banner {
  background: linear-gradient(
    320.33deg,
    #ffde7f 8.9%,
    #f4cddc 42.07%,
    #cec3ff 52.16%,
    #abead9 82.25%
  );
  padding: 16px;
  border-radius: 16px;
}
</style>
