import { StatusList } from "@/const/type";

export const statusList: StatusList[] = [
  {
    status: "issued",
    label: "Issued",
    color: "bg-flohh-secondary-green",
  },
  {
    status: "paid",
    label: "Paid",
    color: "bg-flohh-secondary-green",
  },
  {
    status: "voided",
    label: "Voided",
    color: "bg-flohh-secondary-red",
  },
  {
    status: "overdue",
    label: "Overdue",
    color: "bg-flohh-secondary-red",
  },
  {
    status: "pending",
    label: "Pending",
    color: "bg-flohh-secondary-yellow",
  },
  {
    status: "failed",
    label: "Failed",
    color: "bg-flohh-secondary-red",
  },
  {
    status: "available",
    label: "Available",
    color: "bg-flohh-secondary-green",
  },
  {
    status: "active",
    label: "Active Member",
    color: "bg-flohh-secondary-blue",
  },
  {
    status: "reserved",
    label: "Invited",
    color: "bg-flohh-secondary-yellow",
  },
];
