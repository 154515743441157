<template>
  <HeadedModal
    v-model="open"
    @onClose="handleModalClose"
    title="Failed"
    width="35vw"
  >
    <template #body>
      <div class="flex flex-col items-center justify-center gap-4">
        <span v-html="icons.modalFailedIcon"></span>
        <p class="text-flohh-text-title font-flohh-font-bold text-center">
          {{ title }}
        </p>
        <p class="text-flohh-text-body text-center">
          <span v-html="subtitle" />
        </p>
      </div>
    </template>
    <template v-if="buttonText" #footer>
      <PrimaryButton
        :label="buttonText"
        @click="handleClick"
        class="!ml-auto !mr-0 !text-flohh-text-body"
        color="flohh-secondary-green"
      />
    </template>
  </HeadedModal>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop } from "vue-facing-decorator";
import { icons } from "@/const/icons";

import HeadedModal from "@/components/utilities/AppModals/HeadedModal.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";

@Component({
  components: {
    HeadedModal,
    PrimaryButton,
  },
})
export default class FailedModal extends Vue {
  icons = icons;

  @Model({
    type: Boolean,
    default: false,
  })
  open!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  title!: string;

  @Prop({
    type: String,
    default: "",
  })
  subtitle!: string;

  @Prop({
    type: String,
    default: "",
  })
  buttonText!: string;

  handleRedirect() {
    this.$router.push({ name: "PlanAndBilling" });
  }

  handleClick() {
    this.$emit("onClick");
  }

  handleModalClose() {
    this.$emit("onClose", false);
  }
}
</script>

<style scoped lang="scss"></style>
