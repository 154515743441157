import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hidden lg:flex lg:items-center" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PersonAvatar = _resolveComponent("PersonAvatar")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Menu, {
      as: "div",
      class: "relative"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MenuButton, { class: "flex items-center gap-2 p-3 bg-white rounded-full" }, {
          default: _withCtx(() => [
            (_ctx.accountInfo)
              ? (_openBlock(), _createBlock(_component_PersonAvatar, {
                  key: 0,
                  firstName: _ctx.accountInfo.profile.firstName,
                  lastName: _ctx.accountInfo.profile.lastName,
                  background: false,
                  size: "lg"
                }, null, 8, ["firstName", "lastName"]))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_1, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.arrowDropDownBlack,
                "aria-hidden": "true"
              }, null, 8, _hoisted_2)
            ])
          ]),
          _: 1
        }),
        _createVNode(_Transition, {
          "enter-active-class": "transition duration-100 ease-out",
          "enter-from-class": "transform scale-95 opacity-0",
          "enter-to-class": "transform scale-100 opacity-100",
          "leave-active-class": "transition duration-75 ease-in",
          "leave-from-class": "transform scale-100 opacity-100",
          "leave-to-class": "transform scale-95 opacity-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MenuItems, { class: "absolute right-0 z-10 mt-2.5 w-40 origin-top-right rounded-lg overflow-hidden bg-white shadow-lg" }, {
              default: _withCtx(() => [
                _createVNode(_component_MenuItem, null, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      class: "flex items-center justify-start w-full px-4 py-2 cursor-pointer hover:bg-flohh-primary-light-pink text-flohh-text-body",
                      href: _ctx.TEACHER_PORTAL_URL
                    }, " Teacher Portal ", 8, _hoisted_3)
                  ]),
                  _: 1
                }),
                _createVNode(_component_MenuItem, null, {
                  default: _withCtx(() => [
                    _createElementVNode("button", {
                      class: "flex items-center justify-start w-full px-4 py-2 hover:bg-flohh-primary-light-pink text-flohh-text-body",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)))
                    }, " Logout ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ConfirmDialog)
  ], 64))
}