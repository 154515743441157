import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`py-[6px] px-[14px] ${_ctx.bgColor} rounded-lg inline-block`)
  }, [
    _createVNode(_component_Text, {
      weight: "md",
      size: "small",
      label: _ctx.text ? _ctx.text : _ctx.label
    }, null, 8, ["label"])
  ], 2))
}