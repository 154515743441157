import { Navigation } from "@/components/Layout/type";
import { getOwnershipType } from "@/utils/ownershipTypeGetter";

export const getNavigation = (): Navigation[] => {
  return [
    {
      icon: "navDashboard",
      label: "Teacher Portal",
      link: process.env.VUE_APP_TEACHER_PORTAL_URL,
      hidden: false,
      breadcrumbs: [],
    },
    {
      icon: "personIconSmallBlack",
      label: "My Account",
      link: "/",
      hidden: false,
      breadcrumbs: [
        {
          label: "My Account",
          name: "MyAccount",
        },
      ],
    },
    {
      icon: "navPlanAndBilling",
      label: "Plan & Billing",
      link: "/plan-and-billing",
      hidden: getOwnershipType() === "consumer",
      breadcrumbs: [
        {
          label: "Plan & Billing",
          name: "PlanAndBilling",
        },
      ],
    },
    {
      icon: "navMembers",
      label: "Members",
      link: "/members",
      hidden: false,
      breadcrumbs: [
        {
          label: "Members",
          name: "Members",
        },
      ],
    },
  ];
};
