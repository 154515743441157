<template>
  <PageViewContainer>
    <div
      class="bg-white rounded-lg border border-solid border-flohh-neutral-85 overflow-hidden"
    >
      <ManageComponent />
    </div>
  </PageViewContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

import PageViewContainer from "@/components/Layout/Partials/PageViewContainer.vue";
import ManageComponent from "@/components/Manage/ManageComponent.vue";

@Component({
  components: { PageViewContainer, ManageComponent },
})
export default class ManageView extends Vue {}
</script>

<style scoped lang="scss"></style>
