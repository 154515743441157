import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    type: _ctx.type,
    disabled: _ctx.disabled,
    class: [
      _ctx.getColor(),
      `flex justify-center items-center gap-x-2 rounded-lg text-flohh-text-caption py-2 px-4 hover:brightness-95 bg-transparent border border-solid font-flohh-font-medium`,
      {
        'cursor-not-allowed': _ctx.disabled,
        'cursor-progress': _ctx.loading,
      },
    ]
  }, _ctx.$attrs), [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "animate-spin",
          innerHTML: _ctx.icons.loadingIconBlack
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ])
  ], 16, _hoisted_1))
}