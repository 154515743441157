<template>
  <div class="flex flex-col gap-[8px] w-full">
    <div class="flex items-center gap-2">
      <input
        type="checkbox"
        v-model="value"
        v-bind="$attrs"
        class="cursor-pointer checkbox"
        :id="id"
      />
      <slot></slot>
    </div>
    <span v-if="errorMessage"
      ><p class="ml-1 text-flohh-error-red text-flohh-text-caption">
        {{ errorMessage }}
      </p></span
    >
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Model, Watch } from "vue-facing-decorator";
import Text from "@/components/utilities/AppTypographies/Text.vue";
import RadioButton from "primevue/radiobutton";

@Component({ components: { Text, RadioButton } })
export default class FormCheckbox extends Vue {
  @Model({
    type: Boolean,
    default: false,
    required: true,
  })
  value!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  invalidMessage!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  required!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  id!: string;

  errorMessage = "";

  @Watch("invalid")
  invalidWatcher(value: boolean) {
    this.errorMessage = value ? this.invalidMessage : "";
  }

  @Watch("value")
  valueWatcher(newValue: string, oldValue: string) {
    if (this.required) {
      if (!newValue && oldValue) {
        this.errorMessage = this.invalidMessage;
      } else if (newValue && !oldValue) {
        this.errorMessage = "";
      }
    }
  }
}
</script>

<style scoped lang="scss">
.checkbox {
  accent-color: #f9b2ce;
}
</style>
