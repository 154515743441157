/*
    USAGE:
    
    const dateString = "2024-10-26";
    const format = "MMMM dd, yyyy";

    formatDate(dateString, format)

    Return Type: String
    Return Value: "October 26, 2024"
*/
const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDate = (dateString: string, format: string): string => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "";
  }

  const options: {
    [key in "yyyy" | "yy" | "MMMM" | "MMM" | "MM" | "M" | "dd" | "d"]: string;
  } = {
    yyyy: date.getFullYear().toString(),
    yy: date.getFullYear().toString().slice(-2),
    MMMM: monthNames[date.getMonth()], // Full month name
    MMM: shortMonthNames[date.getMonth()],
    MM: String(date.getMonth() + 1).padStart(2, "0"),
    M: (date.getMonth() + 1).toString(),
    dd: String(date.getDate()).padStart(2, "0"),
    d: date.getDate().toString(),
  };

  return format.replace(
    /yyyy|yy|MMMM|MMM|MM|M|dd|d/g,
    (match) => options[match as keyof typeof options]
  );
};
