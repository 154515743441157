import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2 w-full" }
const _hoisted_2 = {
  key: 2,
  class: "text-flohh-error-red text-flohh-text-caption ml-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_Text, {
          key: 0,
          color: "text-flohh-neutral-35",
          size: "body",
          weight: "md"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.subLabel)
      ? (_openBlock(), _createBlock(_component_Text, {
          key: 1,
          color: "text-flohh-neutral-35 text-flohh-text-caption",
          size: "body",
          weight: "md"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.subLabel), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Textarea, _mergeProps({
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      style: { outline: 'none', boxShadow: 'none' },
      class: [
        {
          'p-invalid': _ctx.errorMessage && _ctx.required,
        },
        'border-flohh-neutral-70 rounded-lg border-solid border outline-none px-[10px] py-[8px] text-flohh-neutral-20 text-flohh-text-body w-full',
      ],
      required: _ctx.required
    }, _ctx.$attrs, {
      onKeydown: _ctx.blockInvalidKeys,
      onInput: _ctx.handleValidateInput
    }), null, 16, ["modelValue", "type", "placeholder", "class", "required", "onKeydown", "onInput"]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}