<template>
  <WizardModal
    v-model="open"
    :steps="steps"
    :currentPage="currentPage"
    :processing="processing"
    buttonColor="flohh-secondary-green"
    @onClose="handleModalClose"
    @onGoBack="handleGoBack"
    @onPrimaryButtonClicked="handlePrimaryBtnClick"
  >
    <template #body>
      <div v-if="currentPage === 0" class="flex flex-col gap-6">
        <p class="text-flohh-text-title font-flohh-font-bold">
          Account Information
        </p>
        <div class="flex gap-10">
          <FormInput
            v-model="firstName"
            label="First Name"
            :invalid="firstNameInvalid"
            invalidMessage="First name is required"
            required
          />
          <FormInput
            v-model="lastName"
            label="Last Name"
            :invalid="lastNameInvalid"
            invalidMessage="Last name is required"
            required
          />
        </div>
        <FormInput
          v-model="email"
          label="Email"
          :invalid="emailInvalid"
          invalidMessage="Email is required"
          :readonly="true"
          required
          type="email"
        />
        <FormInput
          v-model="schoolName"
          label="School Name"
          :invalid="schoolNameInvalid"
          invalidMessage="School name is required"
          required
        />
        <SelectCountry
          v-model="country"
          label="Country"
          :invalid="countryInvalid"
          required
        />
      </div>
      <div v-else>
        <div class="flex flex-col justify-center w-full gap-6 lg:flex-row">
          <div
            v-if="currentPlan"
            class="flex-1 p-6 border border-solid rounded-lg border-flohh-neutral-70 bg-flohh-neutral-95"
          >
            <p class="mb-1 text-flohh-text-title font-flohh-font-bold">
              Current Plan: {{ currentPlan.name }}
            </p>
            <div class="flex flex-wrap justify-between">
              <div class="flex-1 basis-[100px]">
                <h5 class="text-flohh-h5 font-flohh-font-bold">
                  ${{ currentPlan.price }}
                </h5>
                <p class="text-flohh-text-caption">per seat</p>
              </div>
              <div class="flex-1 basis-[100px]">
                <h5 class="text-right text-flohh-h5 font-flohh-font-bold">
                  1 seat
                </h5>
              </div>
            </div>
          </div>
          <div
            v-if="newPlan"
            class="flex-1 p-6 border border-solid rounded-lg border-flohh-neutral-70"
          >
            <p class="mb-1 text-flohh-text-title font-flohh-font-bold">
              New Plan: {{ newPlan.name }}
            </p>
            <div class="flex flex-wrap justify-between">
              <div class="flex-1 basis-[100px]">
                <h5 class="text-flohh-h5 font-flohh-font-bold">
                  {{ formatPrice(newPlan.price) }}
                </h5>
                <p class="text-flohh-text-caption">
                  {{ billingSchedule }}, per seat
                </p>
              </div>
              <div class="flex-1 basis-[100px]">
                <h5 class="text-right text-flohh-h5 font-flohh-font-bold">
                  {{ seatCountModel }} {{ formatWord(seatCountModel, "seat") }}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-6" />
        <form
          @submit.prevent="handleProceedToPayment"
          novalidate
          class="flex flex-col gap-6"
        >
          <p class="text-flohh-text-title font-flohh-font-bold">
            Team Information
          </p>
          <div>
            <FormInput
              type="number"
              v-model="seatCountModel"
              label="Seat Quantity"
              :invalid="seatCountModelInvalid"
              invalidMessage="Seat quantity is required"
              placeholder="Enter the number of people on your team"
              class="text-flohh-text-body"
              :maxNumber="10000"
              required
            />
          </div>
          <hr />
          <p class="text-flohh-text-title font-flohh-font-bold">
            Payment Preference
          </p>
          <FormDropdown
            v-model="billingSchedule"
            :options="billingSchedules"
            class="!w-[50%]"
          />
          <div class="flex gap-6">
            <div class="flex gap-2">
              <RadioButton
                v-model="paymentMethod"
                inputId="card"
                name="card"
                value="card"
                v-bind="$attrs"
              />
              <label class="cursor-pointer text-flohh-text-body" for="card"
                >Credit Card</label
              >
            </div>
          </div>

          <FormCheckbox
            v-model="agree"
            :invalid="agreeInvalid"
            invalidMessage="This field is required"
            required
            id="agree-checkbox"
            ><label
              for="agree-checkbox"
              class="cursor-pointer text-flohh-text-caption"
            >
              I agree to the
              <span
                ><a
                  href="https://www.flohh.com.au/reasonable-usage-policy/"
                  target="_blank"
                  class="underline"
                  >Reasonable Use Policy</a
                ></span
              >
            </label></FormCheckbox
          >
        </form>
      </div>
    </template>
  </WizardModal>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop, Watch } from "vue-facing-decorator";
import { icons } from "@/const/icons";
import { usePlansStore } from "@/stores/plans";
import {
  ActivePlan,
  SubscribeStripePayload,
  Price,
  BillingAddress,
} from "@/components/PlanAndBilling/type";
import { formatPrice } from "@/utils/priceFormatter";
import { formatWord } from "@/utils/pluralFormatter";
import { formatDate } from "@/utils/dateFormatter";
import PlanAndBillingService from "@/services/PlanAndBillingService";
import { IWizardModalStep } from "@/components/utilities/type";
import { AxiosResponse } from "axios";
import { useAccountInfoStore } from "@/stores/accountInfo";
import { AccountBasicInformation } from "@/components/Account/type";
import AccountService from "@/services/AccountService";
import { ICountry } from "@/const/type";

import WizardModal from "@/components/utilities/AppModals/WizardModal.vue";
import FormInput from "@/components/utilities/AppFormFields/FormInput.vue";
import FormDropdown from "@/components/utilities/AppFormFields/FormDropdown.vue";
import FormCheckbox from "@/components/utilities/AppFormFields/FormCheckBox.vue";
import RadioButton from "primevue/radiobutton";
import SelectCountry from "@/components/utilities/SelectCountry.vue";

@Component({
  components: {
    WizardModal,
    FormInput,
    FormDropdown,
    FormCheckbox,
    RadioButton,
    SelectCountry,
  },
})
export default class UpgradePlanModal extends Vue {
  private planAndBillingService: PlanAndBillingService =
    new PlanAndBillingService();
  private accountService: AccountService = new AccountService();
  plansStore = usePlansStore();
  accountInfoStore = useAccountInfoStore();
  icons = icons;

  @Model({
    type: Boolean,
    default: false,
  })
  open!: boolean;

  @Prop({
    type: Object,
    default: false,
  })
  activePlan!: ActivePlan;

  @Prop({
    type: Number,
    default: NaN,
  })
  seatCount!: number;

  @Prop({
    type: Array,
    required: true,
  })
  countries!: ICountry[];

  steps: IWizardModalStep[] = [
    {
      title: "Create your team",
      primaryBtnLabel: "Proceed to plan details",
    },
    {
      title: "Upgrade plan",
      primaryBtnLabel: "Proceed to payment",
    },
  ];

  currentPage = 0;
  processing = false;

  firstName = "";
  firstNameInvalid = false;

  lastName = "";
  lastNameInvalid = false;

  email = "";
  emailInvalid = false;

  schoolName = "";
  schoolNameInvalid = false;

  country = "";
  countryInvalid = false;

  paymentMethod = "card";

  agree = false;
  agreeInvalid = false;

  billingSchedule: "monthly" | "yearly" = "monthly";
  billingSchedules = [
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Yearly",
      value: "yearly",
    },
  ];

  totalSeat = 0;
  occupiedSeat = 0;
  currentPlan!: Price;
  newPlan!: Price;
  seatCountModel = 0;
  accountInfoPayload!: AccountBasicInformation;

  seatCountModelInvalid = false;

  @Watch("open")
  openWatcher(value: boolean) {
    if (value) {
      this.setPlans();
      this.setAccountInfo();
    } else {
      this.currentPage = 0;
      this.seatCountModel = 1;
    }
  }

  @Watch("seatCountModel")
  seatCountModelWatcher() {
    this.handleGetNewPlan();
  }

  @Watch("billingSchedule")
  billingScheduleWatcher() {
    this.handleGetNewPlan();
  }

  setPlans() {
    if (this.plansStore.pricingTable) {
      this.handleGetNewPlan();

      const currentPlan = {
        slug: this.activePlan.subscription.plan.slug,
        name: this.activePlan.subscription.plan.name,
        price: 0,
        maxSeat: 0,
      };
      if (currentPlan) {
        this.currentPlan = currentPlan;
      }
    }

    this.seatCountModel = this.seatCount;
  }

  setAccountInfo() {
    const { accountInfo } = this.accountInfoStore;
    if (accountInfo) {
      this.firstName = accountInfo.profile.firstName;
      this.lastName = accountInfo.profile.lastName;
      this.email = accountInfo.workEmail;
      this.schoolName = accountInfo.schoolName;

      this.setCountry(accountInfo);
    }
  }

  handleGetNewPlan() {
    if (this.plansStore.pricingTable) {
      const newPlan = this.plansStore.pricingTable[this.billingSchedule].find(
        (item: Price) => this.seatCountModel <= item.maxSeat
      );
      if (newPlan) {
        this.newPlan = newPlan;
      }
    }
  }

  async handleProceedToPayment() {
    try {
      const isValid = this.validateSecondPageForm();

      this.processing = true;

      if (!isValid) {
        return;
      }

      const updateAccountSuccess: boolean =
        await this.handleUpdateAccountInformation();

      if (!updateAccountSuccess) {
        return;
      }

      const payload = this.handleSetPayload();

      const response: AxiosResponse =
        await this.planAndBillingService.subscribeStripe(payload);

      if (response.data.ok) {
        const data = response.data.data;
        window.open(data.paymentDetails.paymentUrl, "_blank");
        this.handleModalClose();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.processing = false;
    }
  }

  async handleUpdateAccountInformation() {
    try {
      const response = await this.accountService.updateAccountInfo(
        this.accountInfoPayload
      );

      return response.data.ok;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  handleSetPayload(): SubscribeStripePayload {
    const successCallbackURL = `${window.location.origin}${window.location.pathname}?type=payment-success&plan=${this.newPlan.name}`;
    const failedCallbackURL = `${window.location.origin}${window.location.pathname}?type=payment-failed&plan=${this.newPlan.name}`;

    let billingAddress!: BillingAddress;
    if (
      this.activePlan.owner.address &&
      this.activePlan.subscription.plan.slug !== "free"
    ) {
      billingAddress = this.activePlan.owner.address;
      billingAddress.postCode = billingAddress.postCode?.toString();
    } else {
      billingAddress = {
        country: this.country,
      };
    }

    return {
      billingAddress: billingAddress,
      currency: "AUD",
      successCallbackUrl: successCallbackURL,
      failCallbackUrl: failedCallbackURL,
      subscriptionPlanSlug: `teacher-pro-${this.billingSchedule}`,
      clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      quantity: Number(this.seatCountModel),
      schoolName: this.schoolName,
    };
  }

  validateSecondPageForm() {
    this.agreeInvalid = !this.agree;
    this.seatCountModelInvalid = !this.seatCountModel;

    return !this.agreeInvalid && !this.seatCountModelInvalid;
  }

  validateFirstPageForm() {
    this.firstNameInvalid = this.firstName === "";
    this.lastNameInvalid = this.lastName === "";
    this.emailInvalid = this.email === "";
    this.schoolNameInvalid = this.schoolName === "";
    this.countryInvalid = this.country === "";

    return (
      !this.firstNameInvalid &&
      !this.lastNameInvalid &&
      !this.emailInvalid &&
      !this.schoolNameInvalid &&
      !this.countryInvalid
    );
  }

  handleAccountInfoPayload() {
    const { accountInfo } = this.accountInfoStore;

    if (accountInfo) {
      this.accountInfoPayload = {
        ...accountInfo,
        profile: {
          ...accountInfo.profile,
          firstName: this.firstName,
          lastName: this.lastName,
        },
        schoolName: this.schoolName,
      };
    }
  }

  setCountry(accountInfo: AccountBasicInformation) {
    let country = "";

    if (this.activePlan.owner.address) {
      country = this.activePlan.owner.address.country;
    } else if (accountInfo.schoolAddress) {
      country = accountInfo.schoolAddress.country;
    }

    const countryInfo = this.countries.find(
      (item: ICountry) => item.name == country
    );
    if (countryInfo) {
      country = countryInfo.iso2;
    }

    this.country = country;
  }

  handlePrimaryBtnClick() {
    if (this.currentPage === 0) {
      const isValid = this.validateFirstPageForm();
      if (!isValid) {
        return;
      }
      this.handleAccountInfoPayload();
      this.currentPage = 1;
    } else {
      this.handleProceedToPayment();
    }
  }

  handleGoBack(goToPageNumber: number) {
    this.currentPage = goToPageNumber;
  }

  handleManageMembers() {
    this.$router.push({ name: "Members" });
  }

  formatPrice(price: number) {
    return formatPrice(price);
  }

  formatWord(num: number, word: string) {
    return formatWord(num, word);
  }

  formatDate(dateString: string, format: string) {
    return formatDate(dateString, format);
  }

  handleModalClose() {
    this.$emit("onClose", false);
  }
}
</script>

<style scoped lang="scss"></style>
