<template>
  <DataTable
    :value="tableData"
    showGridlines
    tableStyle="min-width: 50rem; font-size: 14px; border-radius: 8px; overflow: hidden;"
    :selectionMode="null"
    :dataKey="tableKey"
    paginator
    :rows="5"
    :rowsPerPageOptions="[5, 10, 20, 50]"
    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
    currentPageReportTemplate="{first} to {last} of {totalRecords}"
  >
    <slot></slot>
    <template #empty
      ><p class="text-flohh-text-caption text-center">
        No Available Data
      </p></template
    >
  </DataTable>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import DataTable from "primevue/datatable";

@Component({ components: { DataTable } })
export default class Table extends Vue {
  @Prop({
    type: Array,
    default: [],
    required: true,
  })
  tableData!: [];

  @Prop({
    type: String,
    default: "table",
  })
  tableKey!: string;
}
</script>

<style lang="scss">
.p-datatable .p-datatable-tbody > tr:focus {
  outline: inherit !important;
  outline-offset: 0rem !important;
}

.p-paginator .p-component {
  font-size: 12px !important;
}
</style>
