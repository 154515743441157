<template>
  <div
    class="flex flex-col h-full border-solid rounded-lg"
    :class="
      highlightedPlanSlug === plan.slug
        ? 'border-flohh-secondary-orange border-2'
        : 'border-flohh-neutral-85 border'
    "
  >
    <div
      class="border-b border-flohh-neutral-85 border-solid px-6 py-4 min-h-[26%]"
    >
      <p class="mb-1 text-flohh-text-subtitle font-flohh-font-medium">
        {{ plan.name }}
      </p>
      <h6 class="mb-1 text-flohh-h6 font-flohh-font-bold">
        {{ plan.slug === "organisation" ? "Let's Talk" : formatPrice() }}
      </h6>
      <p
        v-if="plan.slug === 'teacher-pro' || plan.slug === 'teams'"
        class="text-flohh-text-caption mb-2"
      >
        {{ planType }}, per seat
      </p>
      <br v-else />
      <p class="text-flohh-text-caption font-flohh-font-bold">
        {{ plan.description }}
      </p>
    </div>
    <div class="flex flex-col justify-between flex-1 p-6">
      <div>
        <div
          class="p-6 mb-6 border border-solid rounded-lg border-flohh-neutral-70"
        >
          <p class="text-[13px] text-flohh-black-20 mb-4">Includes:</p>
          <ul class="list-disc pl-[1.5em] text-[13px] text-flohh-neutral-35">
            <li v-for="(inclution, index) in plan.includes" :key="index">
              {{ inclution }}
            </li>
          </ul>
        </div>
        <div class="mb-3">
          <div>
            <p class="mb-6 text-flohh-text-body font-flohh-font-medium">
              Features you'll love
            </p>
            <div
              v-for="(feature, index) in plan.features"
              :key="index"
              class="flex gap-2 mb-6"
            >
              <span v-html="icons.checkIconGreen"></span>
              <p class="text-flohh-text-body font-flohh-font-medium">
                {{ feature }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <SecondaryButton
        v-if="plan.slug === activePlanSlug"
        label="Current"
        color="flohh-neutral-70"
        class="w-full mt-auto border-flohh-neutral-70"
        :disabled="true"
      />
      <PrimaryButton
        v-else
        :label="upgradeButtonText"
        color="flohh-secondary-green"
        class="w-full"
        @click="handleClick"
      />
    </div>
  </div>
  <UpgradePlanModal
    v-model="upgradePlanModalOpen"
    @onClose="handleModalClose"
    :activePlan="activePlan"
    :planType="planType"
    :seatCount="seatCountModel"
    :countries="countries"
  />
  <UpdateSeatModal
    v-model="updateSeatModalOpen"
    :activePlan="activePlan"
    :seatCount="seatCountModel"
    @onClose="updateSeatModalOpen = false"
  />
  <CancelSubscriptionModal
    v-model="cancelSubscriptionModalOpen"
    :activePlan="activePlan"
    @onClose="cancelSubscriptionModalOpen = false"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import { icons } from "@/const/icons";
import {
  Plan,
  ActivePlan,
  Price,
  PlanPricing,
} from "@/components/PlanAndBilling/type";
import { formatPrice } from "@/utils/priceFormatter";
import { usePlansStore } from "@/stores/plans";
import { ICountry } from "@/const/type";

import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SecondaryButton from "@/components/utilities/AppButtons/SecondaryButton.vue";
import UpgradePlanModal from "@/components/PlanAndBilling/UpgradePlan/UpgradePlanModal.vue";
import UpdateSeatModal from "@/components/Manage/SeatCount/UpdateSeatModal.vue";
import CancelSubscriptionModal from "@/components/PlanAndBilling/CancelSubscription/CancelSubscriptionModal.vue";

@Component({
  components: {
    PrimaryButton,
    SecondaryButton,
    UpgradePlanModal,
    UpdateSeatModal,
    CancelSubscriptionModal,
  },
})
export default class PlanCard extends Vue {
  plansStore = usePlansStore();
  icons = icons;

  @Prop({
    type: Object,
    required: true,
  })
  plan!: Plan;

  @Prop({
    type: Object,
    required: true,
  })
  activePlan!: ActivePlan;

  @Prop({
    type: String,
    default: "monthly",
  })
  planType!: "monthly" | "yearly";

  @Prop({
    type: Number,
    default: 0,
  })
  seatCount!: number;

  @Prop({
    type: Array,
    required: true,
  })
  countries!: ICountry[];

  upgradePlanModalOpen = false;
  cancelSubscriptionModalOpen = false;
  upgradeSeatModalOpen = true;
  updateSeatModalOpen = false;

  activePlanSlug = "";
  upgradeButtonText = "";
  isUpgrade = true;

  seatCountModel = 0;
  highlightedPlanSlug = "";

  @Watch("activePlan")
  activePlanWatcher() {
    this.setButtonText();
  }

  @Watch("seatCount")
  seatCountWatcher(value: number) {
    this.handleGetHighlightedPlan(value);
  }

  mounted() {
    this.setButtonText();
  }

  handleGetHighlightedPlan(value: number) {
    if (value) {
      if (this.plansStore.pricingTable) {
        const newPlan = this.plansStore.pricingTable[this.planType].find(
          (item: Price) => this.seatCount <= item.maxSeat
        );
        if (newPlan) {
          this.highlightedPlanSlug = newPlan.slug;
        }
      }
    } else {
      this.highlightedPlanSlug = "";
    }
  }

  handleClick() {
    if (this.plan.slug === "organisation") {
      window.open("https://www.flohh.com.au/pricing/contact-sales/", "_blank");
    } else {
      if (this.activePlan.subscription.plan.slug === "free") {
        if (this.plan.slug === "teacher-pro") {
          this.seatCountModel = this.seatCount ? this.seatCount : 1;
        } else if (this.plan.slug === "teams") {
          this.seatCountModel = this.seatCount ? this.seatCount : 10;
        }
        this.upgradePlanModalOpen = true;
      } else {
        if (this.plan.slug === "teacher-pro") {
          this.seatCountModel = this.seatCount ? this.seatCount : 1;
          this.updateSeatModalOpen = true;
        } else if (this.plan.slug === "teams") {
          this.seatCountModel = this.seatCount ? this.seatCount : 10;
          this.updateSeatModalOpen = true;
        } else {
          this.cancelSubscriptionModalOpen = true;
        }
      }
    }
  }

  setButtonText() {
    this.activePlanSlug = this.activePlan.subscription.plan.slug;
    let text = "";
    if (this.plan.slug !== "free" && this.activePlanSlug === "free") {
      text = `Upgrade to ${this.plan.name}`;
    } else if (this.activePlanSlug === "teacher-pro") {
      if (this.plan.slug === "free") {
        text = `Downgrade to ${this.plan.name}`;
      } else {
        text = `Upgrade to ${this.plan.name}`;
      }
    } else if (this.activePlanSlug === "teams") {
      if (this.plan.slug === "organisation") {
        text = `Upgrade to ${this.plan.name}`;
      } else {
        text = `Downgrade to ${this.plan.name}`;
      }
    } else if (
      this.plan.slug !== "organisation" &&
      this.activePlanSlug === "organisation"
    ) {
      text = `Downgrade to ${this.plan.name}`;
    }

    if (text.includes("Organisation")) {
      this.upgradeButtonText = "Contact Sales";
    } else {
      this.upgradeButtonText = text;
    }

    this.isUpgrade = text.includes("Upgrade");
  }

  formatPrice() {
    return formatPrice((this.plan[this.planType] as PlanPricing).price);
  }

  handleModalClose() {
    this.upgradePlanModalOpen = false;
  }
}
</script>

<style scoped lang="scss"></style>
