<template>
  <HeadedModal
    v-model="open"
    @onClose="handleModalClose"
    :title="title"
    width="35vw"
  >
    <template #body>
      <p class="text-flohh-text-body">
        <span v-html="message" />
      </p>
    </template>
    <template #footer>
      <div class="flex justify-between items-center">
        <button
          @click="handleCancel"
          class="text-flohh-text-body font-flohh-font-medium"
        >
          {{ cancelButtonLabel }}
        </button>
        <PrimaryButton
          :label="confirmButtonLabel"
          @click="handleConfirm"
          class="!ml-auto !mr-0 !text-flohh-text-body"
          color="flohh-secondary-green"
        />
      </div>
    </template>
  </HeadedModal>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop } from "vue-facing-decorator";
import { icons } from "@/const/icons";

import HeadedModal from "@/components/utilities/AppModals/HeadedModal.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";

@Component({
  components: {
    HeadedModal,
    PrimaryButton,
  },
})
export default class DialogModal extends Vue {
  icons = icons;

  @Model({
    type: Boolean,
    default: false,
  })
  open!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  title!: string;

  @Prop({
    type: String,
    default: "",
  })
  message!: string;

  @Prop({
    type: String,
    default: "Confirm",
  })
  confirmButtonLabel!: string;

  @Prop({
    type: String,
    default: "Cancel",
  })
  cancelButtonLabel!: boolean;

  handleConfirm() {
    this.$emit("onConfirm");
  }

  handleCancel() {
    this.$emit("onCancel");
  }

  handleModalClose() {
    this.$emit("onClose", false);
  }
}
</script>

<style scoped lang="scss"></style>
