<template>
  <div class="flex flex-col">
    <h6 class="text-flohh-text-title font-flohh-font-bold mb-2">
      Upgrade Plan
    </h6>
    <p
      class="text-flohh-text-caption font-flohh-font-medium text-flohh-neutral-35"
    >
      Explore our plans to elevate your teaching experience and boost student
      engagement. <br />Upgrade now to unlock advanced features and maximise
      your educational impact.
    </p>
  </div>
  <CalculateToolsComponent
    @onPlanSelected="handlePlanSelected"
    @onSeatChange="handleSeatChange"
  />
  <div class="grid grid-cols-4 gap-4 items-stretch min-h-[700px]">
    <div v-for="(plan, index) in plans" :key="index">
      <PlanCard
        :plan="plan"
        :activePlan="activePlan"
        :planType="planType"
        :seatCount="seatCount"
        :countries="countries"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import { Plan, ActivePlan } from "@/components/PlanAndBilling/type";
import { countriesStore } from "@/stores/countries";
import { ICountry } from "@/const/type";

import CalculateToolsComponent from "@/components/PlanAndBilling/UpgradePlan/CalculateToolsComponent.vue";
import PlanCard from "@/components/PlanAndBilling/UpgradePlan/PlanCard.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

@Component({
  components: { CalculateToolsComponent, PlanCard, ProgressLoader },
})
export default class UpgradePlanComponent extends Vue {
  useCountriesStore = countriesStore();

  @Prop({
    required: true,
  })
  plans!: Plan[];

  @Prop({
    required: true,
  })
  activePlan!: ActivePlan | undefined;

  planType = "monthly";
  seatCount = 0;

  countries: ICountry[] = [];

  mounted() {
    this.getCountries();
  }

  handlePlanSelected(value: "monthly" | "yearly") {
    this.planType = value;
  }

  handleSeatChange(quantity: number) {
    this.seatCount = quantity;
  }

  async getCountries() {
    try {
      const countries = await this.useCountriesStore.fetchCountries();

      if (countries) {
        this.countries = countries;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
</script>

<style scoped lang="scss"></style>
