import { createApp } from "vue";
import { createPinia } from "pinia";

import "./assets/primevue-theme/primary_pink_theme.css";
import "./app.css";
import "./config/axios";

import router from "./router";
import ConfirmationService from "primevue/confirmationservice";
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import App from "./App.vue";

const pinia = createPinia();
const app = createApp(App);

app.directive("tooltip", Tooltip);

app.use(router);
app.use(ConfirmationService);
app.use(PrimeVue);
app.use(pinia);

app.mount("#app");
