<template>
  <div class="flex flex-col items-center gap-9">
    <div class="max-w-[300px]">
      <h6 class="font-flohh-font-bold text-flohh-h6 text-[#404040] text-center">
        Verify with OTP Code
      </h6>
      <p class="text-flohh-text-body text-[#404040] text-center">
        Enter the 6-digit verification code that was sent to your email.
      </p>
    </div>
    <form
      @submit.prevent="handleVerifyOTP"
      novalidate
      class="flex flex-col gap-4"
    >
      <div class="flex gap-2">
        <input
          v-for="(digit, index) in otp"
          :key="index"
          type="text"
          maxlength="1"
          v-model="otp[index]"
          @input="onInput(index)"
          @keydown="onBackspace(index, $event)"
          @paste="onPaste"
          ref="otpRefs"
          class="text-center border border-solid rounded-lg border-flohh-neutral-85 w-[40px] h-[40px] text-flohh-text-title outline-none"
        />
      </div>
      <div class="flex flex-col items-center gap-2">
        <PrimaryButton
          color="flohh-secondary-green"
          :loading="verifying"
          :disabled="verifying"
          type="submit"
        >
          <span v-if="!verifying" v-html="icons.checkIcon" /> Verify Account
        </PrimaryButton>
        <p class="text-flohh-text-small text-[#404040]">
          Didn’t receive code?
          <button
            href="#"
            class="text-flohh-secondary-red"
            type="button"
            @click="handleResendOTP"
            :disabled="isTimerActive"
          >
            {{ isTimerActive ? `Resend OTP in ${timer}s` : "Resend OTP" }}
          </button>
        </p>
      </div>
    </form>
  </div>
  <FailedModal
    v-model="failedModalOpen"
    :title="failedModalTitle"
    :subtitle="failedModalSubtitle"
    @onClose="failedModalOpen = false"
  />
</template>

<script lang="ts">
import { Vue, Component, Ref, Prop } from "vue-facing-decorator";
import { icons } from "@/const/icons";
import AuthenticationService from "@/services/AuthenticationService";
import { AxiosResponse, AxiosError } from "axios";
import { ErrorResponseData } from "@/components/Authentication/type";
import { useAuthStore } from "@/stores/authStore";
import { useAccountInfoStore } from "@/stores/accountInfo";

import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import FailedModal from "@/components/utilities/AppModals/FailedModal.vue";

@Component({
  components: { PrimaryButton, FailedModal },
})
export default class OneTimePasswordComponent extends Vue {
  private authenticationService: AuthenticationService =
    new AuthenticationService();
  icons = icons;
  authStore = useAuthStore();
  accountInfoStore = useAccountInfoStore();

  @Prop({
    type: String,
    default: "",
  })
  token!: string;

  verifying = false;

  failedModalOpen = false;
  failedModalTitle = "";
  failedModalSubtitle = "";

  timer = 30;
  isTimerActive = false;
  countdownInterval: number | null = null;

  otp = Array(6).fill("");

  @Ref("otpRefs") otpRefs!: HTMLInputElement[];

  onInput(index: number) {
    if (this.otp[index].length === 1 && index < this.otp.length - 1) {
      this.otpRefs[index + 1].focus();
    }
  }

  onBackspace(index: number, event: KeyboardEvent) {
    if (event.key === "Backspace" && this.otp[index] === "" && index > 0) {
      this.otpRefs[index - 1].focus();
      this.otp[index - 1] = "";
    }
  }

  onPaste(event: ClipboardEvent) {
    const pasteData = event.clipboardData?.getData("Text").slice(0, 6) || "";
    for (let i = 0; i < pasteData.length; i++) {
      this.otp[i] = pasteData[i];
    }

    this.otpRefs[Math.min(pasteData.length - 1, this.otp.length - 1)].focus();
    event.preventDefault();
  }

  validateForm(otpCode: string) {
    if (otpCode.length < 6) return false;
    if (!otpCode) return false;

    return true;
  }

  async handleVerifyOTP() {
    try {
      const otpCode = this.otp.join("");

      const isValid = this.validateForm(otpCode);

      if (!isValid) {
        this.failedModalTitle = "Unable to Verify";
        this.failedModalSubtitle = "Invalid OTP Code";
        this.failedModalOpen = true;
        return;
      }

      this.verifying = true;

      const response: AxiosResponse =
        await this.authenticationService.verifyOTP(otpCode, this.token);

      if (response.data.ok) {
        this.authStore.storeAccessToken(response.data.data.accessToken);
        if (process.env.VUE_APP_TESTING_MODE === true) {
          localStorage.setItem("accessToken", response.data.data.accessToken);
        }

        await this.accountInfoStore.fetchAccountInfo();

        this.$router.push({
          name: "MyAccount",
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.verifying = false;
      this.otp = Array(6).fill("");
    }
  }

  async handleResendOTP() {
    try {
      const response: AxiosResponse =
        await this.authenticationService.sendOTPAccess(this.token);

      if (response.data.ok) {
        this.startTimer();
      } else {
        throw new Error();
      }
    } catch (error) {
      const errorData = error as AxiosError;
      const response = errorData.response?.data as ErrorResponseData;

      if (response) {
        this.failedModalTitle = "Failed to Resend OTP";
        this.failedModalSubtitle = response.message;
        this.failedModalOpen = true;
        console.error(response.message);
      }
    }
  }

  startTimer() {
    this.timer = 30;
    this.isTimerActive = true;

    this.countdownInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    }
    this.isTimerActive = false;
  }

  beforeUnmount() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }
}
</script>

<style scoped lang="scss"></style>
