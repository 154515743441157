import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h5", _mergeProps(_ctx.$attrs, {
    class: [
      {
        'font-flohh-font-bold': _ctx.weight === '700',
        'font-flohh-font-semi-bold': _ctx.weight === '600',
        'font-flohh-font-medium': _ctx.weight === '500',
        'font-flohh-font-regular': _ctx.weight === '400',
        'font-flohh-font-light': _ctx.weight === '300',
      },
      'text-flohh-h5',
      _ctx.color,
    ]
  }), [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ])
  ], 16))
}