import {
  BillingAddress,
  SubscribeStripePayload,
} from "@/components/PlanAndBilling/type";
import axios from "axios";

const BASE_URL = "/accounts";
const SUBSCRIPTION_URL = `${BASE_URL}/subscription`;
const OWNER_URL = `${BASE_URL}/owner`;

export default class PlanAndBillingService {
  fetchActivePlan() {
    return axios.get(`${SUBSCRIPTION_URL}/active`);
  }

  fetchAllSubscription() {
    return axios.get(`${SUBSCRIPTION_URL}/all`);
  }

  fetchPendingSubscription() {
    return axios.get(`${SUBSCRIPTION_URL}/pending`);
  }

  fetchPlans() {
    return axios.get(`${SUBSCRIPTION_URL}/plans`);
  }

  subscribeStripe(payload: SubscribeStripePayload) {
    return axios.post(`${SUBSCRIPTION_URL}/subscribe/stripe`, payload);
  }

  cancelSubscription(subscriptionUuid: string, reasons: string[]) {
    return axios.put(`${SUBSCRIPTION_URL}/${subscriptionUuid}/cancel`, {
      reasons,
    });
  }

  subscribeFree() {
    return axios.post(`${SUBSCRIPTION_URL}/subscribe/free`, {
      billingAddress: {
        address1: "Blk 42 Lot 99",
        country: "ph",
        state: "Rizal",
        postCode: "1870",
        suburb: "Antipolo City",
      },
      clientTimezone: "Asia/Manila",
    });
  }

  updateBillingInfo(payload: BillingAddress) {
    return axios.put(`${OWNER_URL}/billing-info`, { address: payload });
  }

  upgradeSubscription(totalSeats: number, subscriptionUuid: string) {
    return axios.put(`${SUBSCRIPTION_URL}/upgrade`, {
      totalSeats,
      subscriptionUuid,
    });
  }

  downgradeSubscription(totalSeats: number, subscriptionUuid: string) {
    return axios.put(`${SUBSCRIPTION_URL}/downgrade`, {
      totalSeats,
      subscriptionUuid,
    });
  }

  fetchCardsInfo() {
    return axios.get(`${OWNER_URL}/payment-methods/cards`);
  }

  createCardPaymentMethod(cardId: string, setAsDefault: boolean) {
    return axios.post(`${OWNER_URL}/payment-methods/cards/${cardId}/attach`, {
      setAsDefault,
    });
  }

  updateCardPaymentMethod(cardId: string, setAsDefault: boolean) {
    return axios.put(`${OWNER_URL}/payment-methods/cards/${cardId}`, {
      setAsDefault,
    });
  }

  removeCardPaymentMethod(cardId: string) {
    return axios.delete(`${OWNER_URL}/payment-methods/cards/${cardId}/detach`);
  }

  fetchBillingHistory() {
    return axios.get(`${OWNER_URL}/invoices`);
  }
}
