import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0871fee5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-1 flex justify-between" }
const _hoisted_2 = { class: "text-flohh-text-caption" }
const _hoisted_3 = {
  key: 0,
  class: "text-flohh-text-caption"
}
const _hoisted_4 = {
  key: 1,
  class: "text-flohh-text-caption"
}
const _hoisted_5 = { class: "bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.labelLeft), 1),
      (_ctx.labelOnly)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.labelRight), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.numerator) + "/" + _toDisplayString(_ctx.denominator) + " " + _toDisplayString(_ctx.labelRight), 1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "bar-progress bg-flohh-primary-pink",
        style: _normalizeStyle(`width: ${_ctx.divWidth}%;`)
      }, null, 4)
    ])
  ]))
}