import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
} from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { getOwnershipType } from "@/utils/ownershipTypeGetter";

import AccountView from "@/views/AccountView.vue";
import PlanAndBillingView from "@/views/PlanAndBillingView.vue";
import MembersView from "@/views/MembersView.vue";
import ManageView from "@/views/ManageView.vue";
import LoginView from "@/views/LoginView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPasswordView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "MyAccount",
    component: AccountView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/plan-and-billing",
    name: "PlanAndBilling",
    component: PlanAndBillingView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/members",
    name: "Members",
    component: MembersView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manage",
    name: "Manage",
    component: ManageView,
    meta: {
      requiresAuth: true,
    },
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
    const { accessToken } = useAuthStore();

    const code = to.query.code as string;
    const token = to.query.token as string;

    if (to.meta.requiresAuth && code && token) {
      next();
    }

    if (to.meta.requiresAuth) {
      if (accessToken) {
        next();
      } else {
        localStorage.clear();
        next({ name: "Login" });
      }
    } else {
      const ownershipType = getOwnershipType();
      if (ownershipType) {
        next({ name: "MyAccount" });
      } else {
        next();
      }
    }
  }
);

export default router;
