<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    class="headed-modal"
    :class="[fullScreen ? '!max-w-[calc(100%_-_90px)] !w-full !h-full' : '']"
    :header="title"
    :style="{ width: width, minWidth: '450px', zIndex: 1000 }"
    :closable="false"
    :draggable="true"
    :pt="{
      header: {
        class: '!p-0 rounded-t-2xl overflow-hidden',
      },
      content: { class: '!p-6' },
    }"
  >
    <template #header>
      <slot name="header">
        <div
          class="w-full bg-flohh-primary-pink flex justify-between items-center bg-[url('@/assets/modal-header-bg.png')] bg-no-repeat !bg-cover p-6"
        >
          <div>
            <h6 class="text-flohh-h6 font-flohh-font-bold">{{ title }}</h6>
            <p
              v-if="subtitle"
              class="text-flohh-text-body font-flohh-font-medium"
            >
              {{ subtitle }}
            </p>
          </div>
          <button
            class="cursor-pointer hover:scale-[1.1]"
            @click="handleClickClose"
          >
            <span v-html="icons.closeIconBlack" />
          </button>
        </div>
      </slot>
    </template>
    <slot name="body" />
    <template v-if="$slots.footer" #footer>
      <hr class="mb-6" />
      <slot name="footer"
    /></template>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Model, Component, Prop } from "vue-facing-decorator";
import Dialog from "primevue/dialog";
import { icons } from "@/const/icons";

@Component({ components: { Dialog } })
export default class HeadedModal extends Vue {
  icons = icons;

  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    type: String,
    default: "Modal",
  })
  title!: string;

  @Prop({
    type: String,
    default: "",
  })
  subtitle!: string;

  @Prop({
    type: String,
    default: "50vw",
  })
  width!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  fullScreen!: boolean;

  handleClickClose() {
    this.$emit("onClose", false);
  }
}
</script>

<style lang="scss"></style>
