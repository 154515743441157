<template>
  <WizardModal
    v-model="visible"
    :steps="steps"
    :currentPage="currentPage"
    :processing="isCanceling"
    @onClose="handleModalClose"
    @onGoBack="handleGoBack"
    @onPrimaryButtonClicked="handlePrimaryBtnClick"
    @onSecondaryButtonClicked="handleSecondaryBtnClick"
  >
    <template #body>
      <div v-if="currentPage === 0" class="flex flex-col gap-6">
        <div class="flex flex-col gap-10">
          <div>
            <p class="mb-4 text-flohh-text-body">
              By proceeding you will lose the following features
            </p>
            <ul>
              <li
                v-if="newPlan.slug === 'teams'"
                class="mb-4 text-flohh-text-body flex gap-2"
              >
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Unlimited Submissions:</strong>
                  Members who are no longer part of the plan will no longer have
                  access to unlimited submissions.
                </p>
              </li>
              <li
                v-if="newPlan.slug === 'teams'"
                class="mb-4 text-flohh-text-body flex gap-2"
              >
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Integration privileges: </strong> You will no longer
                  have access to your current CMS and SIS integrations. Only
                  Google Classroom, Google and Microsoft will remain.
                </p>
              </li>
              <li class="mb-4 text-flohh-text-body flex gap-2">
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Seat changes: </strong> Your new seat limit will be
                  reduced to {{ newSeatCount }}
                </p>
              </li>
              <li
                v-if="newPlan.slug === 'teacher-pro'"
                class="mb-4 text-flohh-text-body flex gap-2"
              >
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Team access:</strong> This plan only supports one
                  seat. Your team members will be moved to their to their own
                  free accounts with limited submissions
                </p>
              </li>
              <li class="mb-4 text-flohh-text-body flex gap-2">
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Price increase: </strong> The per-seat pricing for
                  your remaining team will increase
                  {{
                    findAndFormatPrice(
                      activePlan,
                      activePlan.subscription.billingSchedule
                    )
                  }}
                  to
                  {{ formatPrice(newPlan.price) }}
                  per seat per month
                </p>
              </li>
            </ul>
          </div>
          <hr />
          <div class="flex items-center">
            <img src="@/assets/computer-img.png" alt="computer" />
            <div v-if="newPlan.slug === 'teams'">
              <p
                class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35"
              >
                Keep your organisation plan to support your unique grading
                workflow.
              </p>
              <p class="text-flohh-text-body text-flohh-neutral-35">
                From integrating with your existing systems to aligning grading
                practices across teams, Flohh ensures flexibility for every
                teacher at {{ accountInfo.schoolName }}. Build a workflow that
                fits your needs without silos or extra steps.
              </p>
            </div>
            <div v-if="newPlan.slug === 'teacher-pro'">
              <p
                class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35"
              >
                Keep your team plan for consistent grading and clear insights.
              </p>
              <p class="text-flohh-text-body text-flohh-neutral-35">
                Ensure every teacher stays aligned with shared grading standards
                and learning goals, while accessing real-time cohort data to
                spot student needs faster—saving time on admin so you can focus
                on supporting your students.
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </WizardModal>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from "vue-facing-decorator";
import { ActivePlan, Price } from "@/components/PlanAndBilling/type";
import { IWizardModalStep } from "@/components/utilities/type";
import { icons } from "@/const/icons";
import { findAndFormatPrice, formatPrice } from "@/utils/priceFormatter";
import { useAccountInfoStore } from "@/stores/accountInfo";
import { AccountBasicInformation } from "@/components/Account/type";

import WizardModal from "@/components/utilities/AppModals/WizardModal.vue";

@Component({
  components: { WizardModal },
})
export default class DowngradePlanModal extends Vue {
  accountInfoStore = useAccountInfoStore();
  icons = icons;

  @Model({
    type: Boolean,
    default: false,
  })
  visible!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  activePlan!: ActivePlan;

  @Prop({
    type: Object,
    required: true,
  })
  newPlan!: Price;

  @Prop({
    required: true,
  })
  newSeatCount!: string | number;

  @Prop({
    type: Boolean,
    default: false,
  })
  isCanceling!: boolean;

  accountInfo!: AccountBasicInformation;

  timeRemaining = "13 days";
  currentPage = 0;

  reason = "";
  reasonInvalid = false;

  anotherReason = "";
  anoterReasonInvalid = false;

  steps: IWizardModalStep[] = [
    {
      title: "Confirm Your Downgrade",
      primaryBtnLabel: "Continue Downgrade",
      secondaryBtnLabel: "Keep my Plan",
    },
  ];

  @Watch("accountInfoStore.accountInfo")
  accountInfoStoreWatcher(accountInfo: AccountBasicInformation) {
    if (accountInfo) {
      this.accountInfo = accountInfo;
    }
  }

  mounted() {
    if (this.accountInfoStore.accountInfo) {
      this.accountInfo = this.accountInfoStore.accountInfo;
    }
  }

  handlePrimaryBtnClick() {
    if (this.currentPage === 0) {
      this.$emit("onConfirmDowngrade");
    }
  }

  handleGoBack(goToPageNumber: number) {
    this.currentPage = goToPageNumber;
  }

  handleSecondaryBtnClick() {
    this.currentPage = 0;
    this.reason = "";
    this.$emit("onClose", false);
  }

  handleModalClose() {
    this.currentPage = 0;
    this.reason = "";
    this.$emit("onClose", false);
  }

  findAndFormatPrice(
    activePlan: ActivePlan,
    billingSchedule: "monthly" | "yearly" | "one-time"
  ) {
    return findAndFormatPrice(activePlan, billingSchedule);
  }

  formatPrice(price: number) {
    return formatPrice(price);
  }
}
</script>

<style scoped lang="scss"></style>
