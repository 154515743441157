<template>
  <div class="" v-show="activePlan.subscription.plan.slug !== 'free'">
    <p class="text-flohh-text-title font-flohh-font-bold">
      Cancel Subscription
    </p>
    <p class="mb-3 text-flohh-text-caption text-flohh-neutral-35">
      All future payments will be canceled and your plan will downgrade to the
      free version of Flohh at the end of your current subscription period.
    </p>
    <PrimaryButton
      label="Cancel my subscription"
      color="flohh-secondary-red"
      @click="handleCancelSubscription"
    />
  </div>
  <CancelSubscriptionModal
    v-model="cancelModalOpen"
    :activePlan="activePlan"
    @onClose="cancelModalOpen = false"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { ActivePlan } from "@/components/PlanAndBilling/type";

import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import CancelSubscriptionModal from "@/components/PlanAndBilling/CancelSubscription/CancelSubscriptionModal.vue";

@Component({
  components: { PrimaryButton, CancelSubscriptionModal },
})
export default class CancelSubscriptionComponent extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  activePlan!: ActivePlan;

  cancelModalOpen = false;

  handleCancelSubscription() {
    this.cancelModalOpen = true;
  }
}
</script>

<style scoped lang="scss"></style>
