<template>
  <div class="switch-container">
    <label class="switch">
      <input type="checkbox" v-model="value" />
      <span class="slider"></span>
    </label>
    <span class="label-text">{{ label }}</span>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Model } from "vue-facing-decorator";

@Component({ components: {} })
export default class FormSwitch extends Vue {
  @Model({
    type: Boolean,
    default: false,
    required: true,
  })
  value!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;
}
</script>

<style lang="scss" scoped>
.switch-container {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  margin-right: 8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e2e2e27e;
  border: 1px solid #cfd6e4;
  border-radius: 20px;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1a1a1a;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.label-text {
  color: #545e7d;
  font-size: 13.39px;
}
</style>
