<template>
  <Menu as="div" class="relative">
    <MenuButton class="flex items-center gap-2 p-3 bg-white rounded-full">
      <PersonAvatar
        v-if="accountInfo"
        :firstName="accountInfo.profile.firstName"
        :lastName="accountInfo.profile.lastName"
        :background="false"
        size="lg"
      />
      <span class="hidden lg:flex lg:items-center">
        <span v-html="icons.arrowDropDownBlack" aria-hidden="true" />
      </span>
    </MenuButton>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2.5 w-40 origin-top-right rounded-lg overflow-hidden bg-white shadow-lg"
      >
        <MenuItem>
          <a
            class="flex items-center justify-start w-full px-4 py-2 cursor-pointer hover:bg-flohh-primary-light-pink text-flohh-text-body"
            :href="TEACHER_PORTAL_URL"
          >
            Teacher Portal
          </a>
        </MenuItem>
        <MenuItem>
          <button
            class="flex items-center justify-start w-full px-4 py-2 hover:bg-flohh-primary-light-pink text-flohh-text-body"
            @click="handleConfirm"
          >
            Logout
          </button>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
  <ConfirmDialog />
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { icons } from "@/const/icons";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import { AccountBasicInformation } from "@/components/Account/type";
import { AxiosResponse } from "axios";

import PersonAvatar from "@/components/utilities/PersonAvatar.vue";
import AuthenticationService from "@/services/AuthenticationService";

@Component({
  components: {
    ConfirmDialog,
    PersonAvatar,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
})
export default class UserMenu extends Vue {
  private authenticationService: AuthenticationService =
    new AuthenticationService();
  icons = icons;

  @Prop({
    type: Object,
    required: true,
  })
  accountInfo!: AccountBasicInformation;

  TEACHER_PORTAL_URL = process.env.VUE_APP_TEACHER_PORTAL_URL;

  confirm = useConfirm();

  handleConfirm() {
    this.confirm.require({
      message: "Are you sure you want to logout?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClass:
        "flex justify-center items-center gap-x-[8px] rounded-[8px] font-flohh-font-medium px-[20px] py-[12px] bg-flohh-secondary-green hover:!bg-flohh-secondary-green-dark text-flohh-text-body h-[45px] !shadow-none !border-0",
      accept: () => {
        this.handleLogout();
      },
    });
  }

  async handleLogout() {
    try {
      const response: AxiosResponse = await this.authenticationService.logout();

      if (response.data.ok) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  }
}
</script>
