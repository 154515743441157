<template>
  <div class="flex flex-col items-start gap-6">
    <div>
      <p class="text-flohh-text-title font-flohh-font-bold">
        Modify your seat quantity
      </p>
      <p class="text-flohh-text-caption text-flohh-neutral-35">
        Update or adjust the number of seats you need for your team by typing
        the desired number below.
      </p>
    </div>
    <div class="flex gap-6">
      <FormInput
        type="number"
        v-model="seatCount"
        label="New paid seat quantity"
        :invalid="seatCountInvalid"
        placeholder="Enter seat quantity"
        class="text-flohh-text-body !w-[210px]"
        :class="[{ '!border-flohh-error-red': seatCountInvalid }]"
        :maxNumber="10000"
        required
      />
      <div class="flex flex-col gap-3">
        <p
          class="text-flohh-text-body text-flohh-neutral-35 font-flohh-font-medium"
        >
          New price per seat
        </p>
        <p
          class="text-flohh-text-title text-flohh-neutral-35 font-flohh-font-bold"
        >
          {{ updatedPrice }}
          {{ billingSchedule }}, per seat
        </p>
      </div>
      <div v-if="seatCountInvalid" class="flex items-end max-w-[200px]">
        <p
          v-if="!seatCount"
          class="text-flohh-text-caption text-flohh-error-red"
        >
          Seat quantity is required
        </p>
        <p
          v-else-if="seatCount < occupiedSeat"
          class="text-flohh-text-caption text-flohh-error-red"
        >
          To reduce your seat quantity below {{ occupiedSeat }}, please adjust
          your team on the
          <strong
            ><button @click="handleRedirectToMembersPage" class="underline">
              Manage Members
            </button></strong
          >
          page.
        </p>
        <p
          v-else-if="seatCount == totalSeat"
          class="text-flohh-text-caption text-flohh-error-red"
        >
          Seat quantity should not be equal to current seat quantity:
          <strong>{{ totalSeat }}</strong>
        </p>
      </div>
    </div>
    <div class="flex gap-6">
      <PrimaryButton
        :label="buttonText"
        class="!text-flohh-text-body py-3 px-5"
        @click="handleUpdateNumberOfSeat"
        :disabled="seatCount == totalSeat || !seatCount"
        color="flohh-secondary-green"
      />
      <SecondaryButton
        label="Cancel"
        color="flohh-neutral-20"
        class="!text-flohh-text-body py-3 px-5 border-flohh-neutral-20"
        @click="handleCancelClick"
      />
    </div>
  </div>
  <UpdateSeatModal
    v-model="updateSeatModalOpen"
    :activePlan="activePlan"
    :seatCount="seatCount"
    @onClose="updateSeatModalOpen = false"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import {
  ActivePlan,
  Plan,
  Price,
  PricingTable,
} from "@/components/PlanAndBilling/type";
import { getSeatCount } from "@/utils/seatCountFormatter";
import { usePlansStore } from "@/stores/plans";
import { formatPrice } from "@/utils/priceFormatter";

import FormInput from "@/components/utilities/AppFormFields/FormInput.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SecondaryButton from "@/components/utilities/AppButtons/SecondaryButton.vue";
import UpdateSeatModal from "@/components/Manage/SeatCount/UpdateSeatModal.vue";

@Component({
  components: {
    FormInput,
    PrimaryButton,
    SecondaryButton,
    UpdateSeatModal,
  },
})
export default class SeatCountComponent extends Vue {
  plansStore = usePlansStore();

  @Prop({
    type: Object,
    required: true,
  })
  activePlan!: ActivePlan;

  @Prop({
    type: Array,
    required: true,
  })
  plans!: Plan[];

  seatCount = NaN;
  seatCountInvalid = false;

  totalSeat = 1;
  occupiedSeat = 1;
  pricingTable!: PricingTable | null;

  updatedPrice = "";

  buttonText = "Preview plan changes";

  updateSeatModalOpen = false;
  billingSchedule = "monthly";

  @Watch("activePlan")
  activePlanWatcher(activePlan: ActivePlan) {
    const seatCount = getSeatCount(activePlan.subscription.consumers);
    this.occupiedSeat = seatCount.occupied;
    this.totalSeat = seatCount.total;
  }

  @Watch("seatCount")
  seatCountWatcher(newValue: number, oldValue: number) {
    if (isNaN(oldValue)) {
      this.seatCountInvalid = false;
    } else {
      this.handleValidateSeatCount(newValue);
    }

    this.updatePrice(newValue);
  }

  @Watch("plansStore.pricingTable")
  pricingTableWatcher(value: PricingTable) {
    this.pricingTable = value;
  }

  mounted() {
    this.setData();
  }

  setData() {
    const seatCount = getSeatCount(this.activePlan.subscription.consumers);
    this.seatCount = seatCount.total;
    this.occupiedSeat = seatCount.occupied;
    this.totalSeat = seatCount.total;

    this.pricingTable = this.plansStore.pricingTable;
  }

  handleUpdateNumberOfSeat() {
    this.updateSeatModalOpen = true;
  }

  handleCancelClick() {
    this.seatCount = NaN;
  }

  updatePrice(seatCount: number) {
    if (seatCount === 0 || !seatCount) {
      this.updatedPrice = formatPrice(0);
      return;
    }

    const billingSchedule =
      this.activePlan.subscription.billingSchedule === "one-time"
        ? "monthly"
        : this.activePlan.subscription.billingSchedule;

    this.billingSchedule = billingSchedule;
    if (this.pricingTable) {
      const selected = this.pricingTable[billingSchedule].find(
        (item: Price) => seatCount <= item.maxSeat
      );
      this.updatedPrice = formatPrice(selected ? selected.price : 0);
    }
  }

  handleRedirectToMembersPage() {
    this.$router.push({
      name: "Members",
    });
  }

  handleValidateSeatCount(seatCount: number) {
    if (
      !seatCount ||
      seatCount == this.totalSeat ||
      seatCount < this.occupiedSeat
    ) {
      this.seatCountInvalid = true;
      return;
    }

    this.seatCountInvalid = false;
  }
}
</script>

<style scoped lang="scss"></style>
