<template>
  <FormDropdown
    v-model="value"
    :label="label"
    :options="countries"
    optionLabel="name"
    optionValue="iso2"
    :filter="true"
    :itemSize="46"
    placeholder="Select a
  Country"
    :invalid="invalid"
    invalidMessage="Country is required"
    :required="required"
  />
</template>

<script lang="ts">
import { Vue, Component, Model, Prop, Watch } from "vue-facing-decorator";
import { ICountry } from "@/const/type";
import { countriesStore } from "@/stores/countries";

import FormDropdown from "@/components/utilities/AppFormFields/FormDropdown.vue";

@Component({ components: { FormDropdown } })
export default class SelectCountry extends Vue {
  useCountriesStore = countriesStore();

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Model({
    type: String,
    required: true,
  })
  value!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  required!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;

  countries: ICountry[] = [];
  fetching = true;

  mounted() {
    this.getCountries();
  }

  async getCountries() {
    try {
      const countries = await this.useCountriesStore.fetchCountries();
      if (countries) {
        this.countries = countries;
        this.selectCountryCode(this.value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  @Watch("value")
  valueWatcher(value: string) {
    // If country is name, convert it to country code
    this.selectCountryCode(value);
  }

  selectCountryCode(value: string) {
    const countryInfo = this.countries.find(
      (country: ICountry) => country.name == value
    );
    if (countryInfo) {
      this.value = countryInfo.iso2;
    }
  }
}
</script>
