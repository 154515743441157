import { defineStore } from "pinia";
import { ref } from "vue";
import {
  Plan,
  PricingTable,
  Price,
  PlanPricing,
  OrganisationPricing,
  PlanPricingTable,
} from "@/components/PlanAndBilling/type";
import { AxiosResponse } from "axios";

import PlanAndBillingService from "@/services/PlanAndBillingService";

const planAndBillingService = new PlanAndBillingService();
const lastFetch = ref<number>(Date.now());
const CACHE_DURATION = 300000; // 5 minutes

export const usePlansStore = defineStore("plans", () => {
  const plans = ref<Plan[]>([]);
  const isLoading = ref(false);
  const error = ref<string | null>(null);
  const pricingTable = ref<PricingTable | null>(null);

  const fetchPlans = async () => {
    const now = Date.now();
    if (plans.value.length > 0 && now - lastFetch.value < CACHE_DURATION) {
      composePricingTable(plans.value);
      return plans.value;
    }

    isLoading.value = true;
    error.value = null;
    try {
      const response: AxiosResponse = await planAndBillingService.fetchPlans();
      plans.value = response.data.data;
      lastFetch.value = Date.now();
    } catch (err) {
      error.value = "Failed to fetch plans";
      console.error(err);
    } finally {
      isLoading.value = false;
    }

    composePricingTable(plans.value);
    return plans.value;
  };

  const composePricingTable = (plans: Plan[]) => {
    const monthlyPriceTable: Price[] = [];
    const yearlyPriceTable: Price[] = [];

    const monthlyPrice = {
      name: "",
      maxSeat: 0,
      price: 0,
    };
    const yearlyPrice = {
      name: "",
      maxSeat: 0,
      price: 0,
    };

    plans.forEach((plan: Plan) => {
      if (plan.slug === "teacher-pro" || plan.slug === "teams") {
        monthlyPrice.name = plan.name;
        monthlyPrice.maxSeat = plan.maxQuantity;
        monthlyPrice.price = (plan["monthly"] as PlanPricing).price;

        monthlyPriceTable.push({
          slug: plan.slug,
          name: monthlyPrice.name,
          maxSeat: monthlyPrice.maxSeat,
          price: monthlyPrice.price,
        });

        yearlyPrice.name = plan.name;
        yearlyPrice.maxSeat = plan.maxQuantity;
        yearlyPrice.price = (plan["yearly"] as PlanPricing).price;

        yearlyPriceTable.push({
          slug: plan.slug,
          name: yearlyPrice.name,
          maxSeat: yearlyPrice.maxSeat,
          price: yearlyPrice.price,
        });
      }

      if (plan.slug === "organisation") {
        (plan["monthly"] as OrganisationPricing).pricingTable.forEach(
          (item: PlanPricingTable) => {
            monthlyPrice.name = plan.name;
            monthlyPrice.maxSeat = item.maxSeat;
            monthlyPrice.price = item.price;

            monthlyPriceTable.push({
              slug: plan.slug,
              name: monthlyPrice.name,
              maxSeat: monthlyPrice.maxSeat,
              price: monthlyPrice.price,
            });
          }
        );

        (plan["yearly"] as OrganisationPricing).pricingTable.forEach(
          (item: PlanPricingTable) => {
            yearlyPrice.name = plan.name;
            yearlyPrice.maxSeat = item.maxSeat;
            yearlyPrice.price = item.price;

            yearlyPriceTable.push({
              slug: plan.slug,
              name: yearlyPrice.name,
              maxSeat: yearlyPrice.maxSeat,
              price: yearlyPrice.price,
            });
          }
        );
      }
    });

    const pircingData: PricingTable = {
      monthly: monthlyPriceTable as Price[],
      yearly: yearlyPriceTable as Price[],
    };

    pricingTable.value = pircingData;

    return pricingTable.value;
  };

  return { plans, isLoading, error, fetchPlans, pricingTable };
});
