<template>
  <div v-if="token" class="max-w-[350px] flex flex-col gap-10 w-full">
    <OneTimePasswordComponent :token="token" />
  </div>
  <div v-else class="max-w-[350px] flex flex-col gap-10 w-full">
    <h6 class="font-flohh-font-bold text-flohh-h6 text-[#404040] text-center">
      Google Sign In Failed
    </h6>
    <p class="mb-1 text-center text-flohh-text-subtitle">
      There was a problem signing in using your Google account. Please try
      again.
    </p>
    <SecondaryButton
      label="Go Back"
      @click="handleGoBack"
      class="border-flohh-neutral-20"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { icons } from "@/const/icons";

import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SecondaryButton from "@/components/utilities/AppButtons/SecondaryButton.vue";
import OneTimePasswordComponent from "@/components/Authentication/OneTimePassword/OneTimePasswordComponent.vue";

@Component({
  components: {
    OneTimePasswordComponent,
    PrimaryButton,
    SecondaryButton,
  },
})
export default class LoginViaGoogle extends Vue {
  icons = icons;

  token = "";

  created() {
    const data = this.$route.query.data as string;
    if (data) {
      const parsedData = JSON.parse(data);
      this.token = parsedData.accessToken;
    } else {
      this.token = "";
    }
  }

  handleGoBack() {
    this.$router.push({ name: "Login" });
  }
}
</script>

<style scoped lang="scss"></style>
