<template>
  <div class="w-full">
    <div class="flex flex-col gap-6 mb-6">
      <Text color="text-flohh-neutral-20" weight="bd" size="title"
        >Basic Information</Text
      >
    </div>
    <form
      @submit.prevent="handleSaveInfo"
      novalidate
      class="flex flex-col gap-6 mb-6"
    >
      <FormInput
        type="email"
        v-model="email"
        label="Email"
        :readonly="true"
        invalidMessage="Email is required"
        :invalid="emailInvalid"
        required
      />
      <div class="flex gap-6">
        <FormInput
          type="text"
          v-model="firstName"
          label="First Name"
          invalidMessage="First name is required"
          :invalid="firstNameInvalid"
          required
        />
        <FormInput
          type="text"
          v-model="lastName"
          label="Last Name"
          invalidMessage="Last name is required"
          :invalid="lastNameInvalid"
          required
        />
      </div>
      <FormInput
        type="text"
        v-model="school"
        label="School Name"
        invalidMessage="School name is required"
        :invalid="schoolInvalid"
        required
      />
      <div class="flex gap-6">
        <SelectCountry
          v-model="country"
          label="Country"
          :invalid="countryInvalid"
          required
        />
        <SelectState
          v-model="state"
          :country="country"
          label="State/Province"
        />
      </div>
      <div class="flex gap-4">
        <PrimaryButton
          label="Save"
          type="submit"
          :loading="saving"
          :disabled="saving"
          color="flohh-primary-light-pink"
        />
      </div>
    </form>
  </div>
  <SuccessModal
    v-model="successModalOpen"
    title="Account Information Updated"
    subtitle="Your account information has been updated successfully"
    @onClose="successModalOpen = false"
  />
</template>
<script lang="ts">
import { Vue, Component, Watch } from "vue-facing-decorator";
import { AxiosResponse } from "axios";
import { AccountBasicInformation } from "@/components/Account/type";
import AccountService from "@/services/AccountService";
import { useAccountInfoStore } from "@/stores/accountInfo";

import Text from "@/components/utilities/AppTypographies/Text.vue";
import FormInput from "@/components/utilities/AppFormFields/FormInput.vue";
import SelectCountry from "@/components/utilities/SelectCountry.vue";
import SelectState from "@/components/utilities/SelectState.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SuccessModal from "../utilities/AppModals/SuccessModal.vue";

@Component({
  components: {
    Text,
    FormInput,
    SelectCountry,
    SelectState,
    PrimaryButton,
    SuccessModal,
  },
})
export default class BasicInformationFormComponent extends Vue {
  private accountService: AccountService = new AccountService();
  accountInfoStore = useAccountInfoStore();
  accountInfo!: AccountBasicInformation;
  successModalOpen = false;
  saving = false;
  teacherUuid = "";

  firstName = "";
  firstNameInvalid = false;

  lastName = "";
  lastNameInvalid = false;

  email = "";
  emailInvalid = false;

  school = "";
  schoolInvalid = false;

  country = "";
  countryInvalid = false;

  state = "";
  stateInvalid = false;

  @Watch("accountInfoStore.accountInfo")
  accountInfoStoreWatcher(accountInfo: AccountBasicInformation) {
    if (accountInfo) {
      this.setAccountInfo(accountInfo);
    }
  }

  async mounted() {
    if (this.accountInfoStore.accountInfo) {
      this.setAccountInfo(this.accountInfoStore.accountInfo);
    }
  }

  async setAccountInfo(accountInfo: AccountBasicInformation) {
    this.accountInfo = accountInfo;
    this.teacherUuid = accountInfo.uuid;
    this.firstName = accountInfo.profile.firstName;
    this.lastName = accountInfo.profile.lastName;
    this.email = accountInfo.workEmail;
    this.school = accountInfo.schoolName;
    this.country = accountInfo.schoolAddress?.country || "";
    this.state = accountInfo.schoolAddress?.state || "";
  }

  async handleSaveInfo() {
    const valid = this.validateForm();

    if (!valid) {
      return;
    }

    this.saving = true;

    const schoolAddress = {
      state: this.state,
      country: this.country,
    };

    const payload = {
      ...this.accountInfo,
      firstName: this.firstName,
      lastName: this.lastName,
      schoolAddress: schoolAddress,
      workEmail: this.email,
      schoolName: this.school,
    };

    try {
      const response: AxiosResponse =
        await this.accountService.updateAccountInfo(payload);

      if (response.data.ok) {
        this.successModalOpen = true;
        this.accountInfoStore.fetchAccountInfo(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.saving = false;
    }
  }

  validateForm() {
    this.firstNameInvalid = this.firstName === "";
    this.lastNameInvalid = this.lastName === "";
    this.emailInvalid = this.email === "";
    this.schoolInvalid = this.school === "";
    this.countryInvalid = this.country === "";
    this.stateInvalid = this.state === "";

    return (
      !this.firstNameInvalid &&
      !this.lastNameInvalid &&
      !this.emailInvalid &&
      !this.schoolInvalid &&
      !this.countryInvalid &&
      !this.stateInvalid
    );
  }
}
</script>
