<template>
  <div>
    <h6 class="text-flohh-text-title font-flohh-font-bold mb-[40px]">
      Billing Information
    </h6>
    <div class="flex justify-between mb-4">
      <p class="text-flohh-text-subtitle font-flohh-font-bold">
        Credit Card Details
      </p>
      <SecondaryButton
        label="Add payment method"
        @click="handleAddPaymentMethod"
        class="border-flohh-primary-pink"
      />
    </div>
    <div v-if="cards.length">
      <div v-for="(item, index) in cards" :key="index">
        <CardInfoComponent
          :cardNumber="item.card.last4"
          :isDefault="defaultCard === item.id"
          :type="item.card.brand"
          :cardID="item.id"
          @onUpdateSuccess="handleMakeCardAsDefault"
          @onRemoveSuccess="fetchCardsInfo"
        />
      </div>
    </div>
    <div v-else class="grid py-10 place-items-center">
      <ProgressLoader v-if="isFetching" />
      <p v-else class="text-flohh-text-caption">No Available Card</p>
    </div>
  </div>
  <AddCardModal
    v-model="addCardModalOpen"
    @onClose="handleModalClose"
    @onCreateSuccess="fetchCardsInfo"
  />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import PlanAndBillingService from "@/services/PlanAndBillingService";
import { AxiosResponse } from "axios";
import {
  PaymentMethod,
  PaymentMethodReference,
} from "@/components/PlanAndBilling/type";

import HeadingFive from "@/components/utilities/AppTypographies/HeadingFive.vue";
import ProgressBarUtility from "@/components/utilities/ProgressBarUtility.vue";
import SecondaryButton from "@/components/utilities/AppButtons/SecondaryButton.vue";
import CardInfoComponent from "./CardInfoComponent.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import HeadedModal from "@/components/utilities/AppModals/HeadedModal.vue";
import AddCardModal from "@/components/PlanAndBilling/CreditCardDetails/AddCardModal.vue";

@Component({
  components: {
    SecondaryButton,
    HeadingFive,
    ProgressBarUtility,
    CardInfoComponent,
    ProgressLoader,
    HeadedModal,
    AddCardModal,
  },
})
export default class CreditCardDetailsComponent extends Vue {
  private planAndBillingService = new PlanAndBillingService();

  defaultCard = "";
  cards: PaymentMethod[] = [];

  addCardModalOpen = false;
  isFetching = true;

  mounted() {
    this.fetchCardsInfo();
  }

  async fetchCardsInfo() {
    try {
      this.isFetching = true;
      const response: AxiosResponse =
        await this.planAndBillingService.fetchCardsInfo();

      if (response.data.ok) {
        const data: PaymentMethodReference = response.data.data;
        this.defaultCard = data.default ? data.default : "";
        this.cards = data.paymentMethods;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isFetching = false;
    }
  }

  handleMakeCardAsDefault(cardID: string) {
    this.defaultCard = cardID;
  }

  handleAddPaymentMethod() {
    this.addCardModalOpen = true;
  }

  handleModalClose(value: boolean) {
    this.addCardModalOpen = value;
  }
}
</script>

<style scoped lang="scss"></style>
