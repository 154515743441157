<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    class="headed-modal"
    :class="[fullScreen ? '!max-w-[calc(100%_-_50px)] !w-full !h-full' : '']"
    :header="title"
    :style="{ width: width, minWidth: '800px', zIndex: 1000 }"
    :closable="false"
    :draggable="true"
    :pt="{
      header: { class: `${title || subtitle ? '!pt-20 !px-20' : ''}` },
      content: { class: '!px-20 !pb-20 h-full' },
    }"
  >
    <template #header>
      <slot name="header">
        <div class="w-full">
          <div class="flex items-center justify-between w-full">
            <div>
              <h6 class="text-flohh-h6 font-flohh-font-bold">
                {{ title }}
              </h6>
              <p class="text-flohh-text-caption font-flohh-font-medium">
                {{ subtitle }}
              </p>
            </div>
            <button
              class="cursor-pointer hover:scale-[1.1]"
              @click="handleClickClose"
            >
              <span v-html="icons.closeIconBlack" />
            </button>
          </div>
          <div
            v-if="title || subtitle"
            class="w-full mt-6 border-b border-solid border-flohh-neutral-85"
          ></div>
        </div>
      </slot>
    </template>
    <slot name="body" />
    <template v-if="$slots.footer" #footer>
      <hr class="mb-6" />
      <slot name="footer"
    /></template>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Model, Component, Prop } from "vue-facing-decorator";
import Dialog from "primevue/dialog";
import { icons } from "@/const/icons";

@Component({ components: { Dialog } })
export default class HeadlessModal extends Vue {
  icons = icons;

  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  title!: string;

  @Prop({
    type: String,
    default: "",
  })
  subtitle!: string;

  @Prop({
    type: String,
    default: "70vw",
  })
  width!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  fullScreen!: boolean;

  handleClickClose() {
    this.$emit("onClose", false);
  }
}
</script>

<style lang="scss"></style>
