<template>
  <div class="flex gap-4 items-center mb-[60px]">
    <span
      v-if="isRemoving"
      class="animate-spin"
      v-html="icons.loadingIconBlack"
    ></span>
    <button v-else @click="handleRemoveCard">
      <span v-html="icons.closeIconSmallBlack" />
    </button>
    <div class="flex gap-4 items-center">
      <img v-if="type === 'visa'" src="@/assets/visacard.svg" />
      <img v-else src="@/assets/mastercard.svg" />
      <div>
        <p
          class="text-flohh-text-caption font-flohh-font-medium text-flohh-neutral-15"
        >
          {{ type === "visa" ? "Visa" : "Master Card" }}
        </p>
        <p
          class="text-flohh-text-caption font-flohh-font-medium text-flohh-neutral-15"
        >
          {{ getMaskedCardNumber() }}
        </p>
      </div>
    </div>
    <div class="flex items-center gap-4">
      <p v-if="isDefault" class="text-flohh-text-body">Default</p>
      <span
        v-if="isUpdating"
        class="animate-spin"
        v-html="icons.loadingIconBlack"
      ></span>
      <button
        v-else-if="!isDefault && !isUpdating"
        class="text-flohh-text-body text-flohh-primary-pink"
        @click="handleSetAsDefault"
      >
        Set as Default
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import { maskCardNumber } from "@/utils/cardFormatter";
import { AxiosResponse } from "axios";
import { icons } from "@/const/icons";

import HeadingFive from "@/components/utilities/AppTypographies/HeadingFive.vue";
import ProgressBarUtility from "@/components/utilities/ProgressBarUtility.vue";
import SecondaryButton from "@/components/utilities/AppButtons/SecondaryButton.vue";
import PlanAndBillingService from "@/services/PlanAndBillingService";

@Component({
  components: {
    SecondaryButton,
    HeadingFive,
    ProgressBarUtility,
  },
})
export default class CardInfoComponent extends Vue {
  private planAndBillingService: PlanAndBillingService =
    new PlanAndBillingService();
  icons = icons;

  @Prop({
    type: String,
    required: true,
    default: "",
  })
  cardNumber!: string;

  @Prop({
    type: String,
    required: true,
    default: "",
  })
  type!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  isDefault!: boolean;

  @Prop({
    type: String,
    required: true,
    default: "",
  })
  cardID!: string;

  isUpdating = false;
  isRemoving = false;

  async handleSetAsDefault() {
    try {
      this.isUpdating = true;

      const response: AxiosResponse =
        await this.planAndBillingService.updateCardPaymentMethod(
          this.cardID,
          true
        );

      if (response.data.ok) {
        this.$emit("onUpdateSuccess", this.cardID);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isUpdating = false;
    }
  }

  async handleRemoveCard() {
    try {
      this.isRemoving = true;
      const response: AxiosResponse =
        await this.planAndBillingService.removeCardPaymentMethod(this.cardID);

      if (response.data.ok) {
        this.$emit("onRemoveSuccess");
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isRemoving = false;
    }
  }

  getMaskedCardNumber() {
    return maskCardNumber(this.cardNumber);
  }
}
</script>

<style scoped lang="scss"></style>
