/*
    USAGE:
    
    const cardNumber = "2424 2424 2424 2424" | "2424";

    maskCardNumber(cardNumber)

    Return Type: String
    Return Value: •••• •••• •••• 242424
*/

export const maskCardNumber = (cardNumber: string) => {
  if (cardNumber.length === 4) {
    return `•••• •••• •••• ${cardNumber}`;
  } else {
    const lastFour = cardNumber.slice(-4);
    const maskedSection = cardNumber.slice(0, -4).replace(/\d/g, "•");

    return `${maskedSection} ${lastFour}`;
  }
};
