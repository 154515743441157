<template>
  <SuccessModal
    v-model="successModalOpen"
    title="Upgrade Successful"
    :subtitle="successPaymentMessage"
    @onClose="handleClosePaymentMessage"
  />
  <FailedModal
    v-model="failedModalOpen"
    title="Payment Failed"
    subtitle="We are sorry, there was an error processing your payment. Please try again."
    @onClose="handleClosePaymentMessage"
  />
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import { useActivePlanStore } from "@/stores/activePlan";
import { usePlansStore } from "@/stores/plans";

import SuccessModal from "@/components/utilities/AppModals/SuccessModal.vue";
import FailedModal from "@/components/utilities/AppModals/FailedModal.vue";

@Component({ components: { SuccessModal, FailedModal } })
export default class StripePaymentStatus extends Vue {
  activePlanStore = useActivePlanStore();
  plansStore = usePlansStore();

  successModalOpen = false;
  failedModalOpen = false;
  successPaymentMessage = "";

  @Watch("successModalOpen")
  successModalOpenWatcher(value: boolean) {
    if (!value) {
      this.activePlanStore.fetchActivePlan(true);
      this.plansStore.fetchPlans();
    }
  }

  created() {
    this.handleOpenPaymentMessage();
  }

  handleOpenPaymentMessage() {
    if (
      this.$route.query.type === "payment-success" &&
      this.$route.query.plan
    ) {
      this.successPaymentMessage = `Your plan has been upgraded to <strong>${this.$route.query.plan}</strong>`;
      this.successModalOpen = true;
    }

    if (this.$route.query.type === "payment-failed") {
      this.failedModalOpen = true;
    }
  }

  handleClosePaymentMessage() {
    this.successModalOpen = false;
    this.failedModalOpen = false;

    const currentPath = this.$route.path;
    this.$router.push({ path: currentPath });
  }
}
</script>

<style scoped lang="scss"></style>
