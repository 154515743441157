<template>
  <Menu as="div" class="relative inline-block text-left">
    <MenuButton id="dropdownButton" @click="toggleDropdown">
      <span v-html="icons[icon]" />
    </MenuButton>
    <Teleport to="#custom-overlay-menu">
      <MenuItems
        :style="{ top: `${position.top}px`, left: `${position.left}px` }"
        class="overflow-hidden absolute z-50 origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-flohh-neutral-85 ring-opacity-5 focus:outline-none"
      >
        <MenuItem
          v-for="(item, index) in items"
          :key="index"
          @click="() => handleMenuSelect(item.id)"
        >
          <div
            class="py-3 px-6 flex gap-2 cursor-pointer hover:bg-flohh-primary-light-pink"
          >
            <span v-html="icons[item.icon]" />
            <p class="text-flohh-text-body font-flohh-font-medium">
              {{ item.label }}
            </p>
          </div>
        </MenuItem>
      </MenuItems>
    </Teleport>
  </Menu>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import { icons } from "@/const/icons";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

interface Items {
  id: string;
  label: string;
  icon: string;
}

@Component({ components: { Menu, MenuButton, MenuItem, MenuItems } })
export default class DropdownButton extends Vue {
  icons = icons;

  @Prop({
    type: String,
    required: true,
  })
  icon!: string;

  @Prop({
    type: Array,
    required: true,
  })
  items!: Items[];

  @Prop({
    type: Boolean,
    default: false,
  })
  toRight!: boolean;

  position = { top: 0, left: 0 };

  toggleDropdown() {
    this.setPosition();
  }

  setPosition() {
    const button = document.getElementById("dropdownButton");
    const container = document.getElementById("custom-overlay-menu");

    if (button && container) {
      const buttonRect = button.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      this.position.top = buttonRect.bottom - containerRect.top;
      //   this.position.top = rect.bottom + window.scrollY;
      const xAxis = this.toRight
        ? buttonRect.left + window.scrollX
        : buttonRect.left + window.scrollX - button.offsetWidth * 10;
      this.position.left = xAxis;
    }
  }

  handleMenuSelect(id: string) {
    this.$emit("onSelect", id);
  }
}
</script>
