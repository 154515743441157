<template>
  <div class="w-full">
    <div class="flex flex-col gap-6 mb-6">
      <Text color="text-flohh-neutral-20" weight="bd" size="title"
        >Password</Text
      >
    </div>
    <form
      ref="passwordFormRef"
      @submit.prevent="handleUpdatePassword"
      novalidate
      class="flex flex-col gap-6 mb-6"
    >
      <FormInput
        type="password"
        v-model="currentPassword"
        label="Verify Current Password"
        placeholder="Enter current password here"
        invalidMessage="Current password is required"
        :invalid="currentPasswordInvalid"
        required
      />
      <FormInput
        type="password"
        v-model="newPassword"
        label="New Password"
        placeholder="Enter new password here"
        :invalidMessage="newPasswordInvalidMessage"
        :invalid="newPasswordInvalid"
        required
      />
      <div class="flex gap-4 text-flohh-neutral-35 font-flohh-text-subtitle">
        <ul class="list-disc pl-[1.5em]">
          <li>One lowercase character</li>
          <li>One uppercase character</li>
          <li>One number</li>
        </ul>
        <ul class="list-disc pl-[1.5em]">
          <li>One special character</li>
          <li>8 characters minimum</li>
        </ul>
      </div>
      <FormInput
        type="password"
        v-model="confirmNewPassword"
        label="Confirm New Password"
        placeholder="Enter new password here"
        :invalidMessage="confirmNewPasswordInvalidMessage"
        :invalid="confirmNewPasswordInvalid"
        required
      />
      <div class="flex gap-4">
        <PrimaryButton
          type="submit"
          label="Save"
          :disabled="isSaving"
          :loading="isSaving"
          color="flohh-primary-light-pink"
        />
        <SecondaryButton
          type="reset"
          label="Cancel"
          color="flohh-primary-pink"
          @click="handleCancel"
          class="border-flohh-primary-pink"
        />
      </div>
    </form>
  </div>

  <SuccessModal
    v-model="successModalOpen"
    title="Password Updated"
    subtitle="Your password has been updated successfully"
    @onClose="successModalOpen = false"
  />
</template>
<script lang="ts">
import { Vue, Component, Watch, Ref } from "vue-facing-decorator";
import { UpdatePassword } from "@/components/Account/type";
import { AxiosResponse } from "axios";
import AuthenticationService from "@/services/AuthenticationService";
import { validatePassword } from "@/utils/passwordValidator";

import Text from "@/components/utilities/AppTypographies/Text.vue";
import FormInput from "@/components/utilities/AppFormFields/FormInput.vue";
import SelectCountry from "@/components/utilities/SelectCountry.vue";
import SelectState from "@/components/utilities/SelectState.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SecondaryButton from "@/components/utilities/AppButtons/SecondaryButton.vue";
import SuccessModal from "@/components/utilities/AppModals/SuccessModal.vue";

@Component({
  components: {
    Text,
    FormInput,
    SelectCountry,
    SelectState,
    PrimaryButton,
    SecondaryButton,
    SuccessModal,
  },
})
export default class PasswordFormComponent extends Vue {
  private authenticationService = new AuthenticationService();

  successModalOpen = false;

  currentPassword = "";
  currentPasswordInvalid = false;

  newPassword = "";
  newPasswordInvalidMessage = "New password is required";
  newPasswordInvalid = false;

  confirmNewPassword = "";
  confirmNewPasswordInvalidMessage = "Please confirm your password";
  confirmNewPasswordInvalid = false;

  isSaving = false;

  @Ref("passwordFormRef")
  passwordFormRef!: HTMLFormElement;

  @Watch("newPassword")
  newPasswordWatcher(value: string) {
    const error = validatePassword(value);
    if (error) {
      this.newPasswordInvalid = true;
      this.newPasswordInvalidMessage = error;
    } else {
      this.checkifEqual();
    }
  }

  @Watch("confirmNewPassword")
  confirmNewPasswordWatcher(value: string) {
    const error = validatePassword(value);
    if (error) {
      this.confirmNewPasswordInvalid = true;
      this.confirmNewPasswordInvalidMessage = error;
    } else {
      this.checkifEqual();
    }
  }

  async handleUpdatePassword() {
    const valid = this.validateForm();
    if (!valid) {
      return;
    }

    this.isSaving = true;

    const payload: UpdatePassword = {
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      newPasswordConfirmation: this.confirmNewPassword,
    };

    try {
      const response: AxiosResponse =
        await this.authenticationService.onUpdatePassword(payload);

      if (response.data.ok) {
        this.successModalOpen = true;
        this.passwordFormRef.reset();
        this.handleCancel();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isSaving = false;
    }
  }

  handleCancel() {
    this.currentPasswordInvalid = false;
    this.newPasswordInvalid = false;
    this.confirmNewPasswordInvalid = false;
  }

  validateForm() {
    this.currentPasswordInvalid = this.currentPassword === "";
    this.newPasswordInvalid = this.newPasswordInvalidMessage !== "";
    this.confirmNewPasswordInvalid =
      this.confirmNewPasswordInvalidMessage !== "";

    return (
      !this.currentPasswordInvalid &&
      !this.newPasswordInvalid &&
      !this.confirmNewPasswordInvalid
    );
  }

  checkifEqual() {
    if (this.newPassword === this.confirmNewPassword) {
      this.confirmNewPasswordInvalidMessage = "";
      this.confirmNewPasswordInvalid = false;
      this.newPasswordInvalidMessage = "";
      this.newPasswordInvalid = false;
    } else {
      this.confirmNewPasswordInvalidMessage = "Passwords do not match";
      this.confirmNewPasswordInvalid = true;
      this.newPasswordInvalidMessage = "Passwords do not match";
      this.newPasswordInvalid = true;
    }
  }
}
</script>
