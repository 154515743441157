<template>
  <div>
    <p class="mb-6 text-flohh-text-subtitle font-flohh-font-bold">
      Billing Address
    </p>
    <form
      @submit.prevent="handleSaveBillingAddress"
      novalidate
      class="flex flex-col gap-6 mb-6"
    >
      <div class="flex gap-6">
        <FormInput type="text" v-model="building" label="Building" />
        <FormInput
          type="text"
          v-model="street"
          label="Street"
          invalidMessage="Street is required"
          :invalid="streetInvalid"
          required
        />
      </div>

      <div class="flex gap-6">
        <FormInput
          type="text"
          v-model="city"
          label="City / Town"
          invalidMessage="City/town is required"
          :invalid="cityInvalid"
          required
        />
        <SelectCountry
          v-model="country"
          label="Country"
          :invalid="countryInvalid"
          required
        />
      </div>

      <div class="flex gap-6">
        <SelectState
          v-model="state"
          :country="country"
          label="State / Province"
        />
        <FormInput
          type="number"
          v-model="postCode"
          label="Postcode"
          invalidMessage="Postcode is required"
          :invalid="postCodeInvalid"
          required
        />
      </div>
      <div class="flex gap-4">
        <PrimaryButton
          label="Save"
          type="submit"
          :loading="isSaving"
          :disabled="isSaving"
        />
      </div>
    </form>
  </div>
  <SuccessModal
    v-model="successModalOpen"
    title="Billing Address Updated"
    @onClose="handleModalClose"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import { AxiosResponse } from "axios";
import PlanAndBillingService from "@/services/PlanAndBillingService";
import { ActivePlan, BillingAddress } from "@/components/PlanAndBilling/type";
import { useActivePlanStore } from "@/stores/activePlan";

import FormInput from "@/components/utilities/AppFormFields/FormInput.vue";
import SelectCountry from "@/components/utilities/SelectCountry.vue";
import SelectState from "@/components/utilities/SelectState.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SuccessModal from "@/components/utilities/AppModals/SuccessModal.vue";

@Component({
  components: {
    FormInput,
    SelectCountry,
    SelectState,
    PrimaryButton,
    SuccessModal,
  },
})
export default class BillingAddressComponent extends Vue {
  private planAndBillingService: PlanAndBillingService =
    new PlanAndBillingService();
  activePlanStore = useActivePlanStore();

  @Prop({
    required: true,
  })
  activePlan!: ActivePlan;

  building = "";
  state = "";

  street = "";
  streetInvalid = false;

  city = "";
  cityInvalid = false;

  country = "";
  countryInvalid = false;

  postCode!: number | string;
  postCodeInvalid = false;

  isSaving = false;
  successModalOpen = false;

  mounted() {
    this.fetchBillingInfo();
  }

  async fetchBillingInfo() {
    const billingAddress = this.activePlan.owner.address;
    this.building = billingAddress ? billingAddress.address2 || "" : "";
    this.street = billingAddress ? billingAddress.address1 || "" : "";
    this.state = billingAddress ? billingAddress.state || "" : "";
    this.city = billingAddress ? billingAddress.suburb || "" : "";
    this.country = billingAddress ? billingAddress.country : "";
    this.postCode = billingAddress ? billingAddress.postCode || "" : "";
  }

  async handleSaveBillingAddress() {
    try {
      const isValid = this.validateForm();

      if (!isValid) {
        return;
      }

      this.isSaving = true;

      const payload: BillingAddress = {
        address1: this.building,
        address2: this.street,
        state: this.state,
        suburb: this.city,
        country: this.country,
        postCode: this.postCode.toString(),
      };

      const response: AxiosResponse =
        await this.planAndBillingService.updateBillingInfo(payload);

      if (response.data.ok) {
        await this.activePlanStore.fetchActivePlan(true);
        this.successModalOpen = true;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isSaving = false;
    }
  }

  validateForm() {
    this.streetInvalid = this.street === "";
    this.cityInvalid = this.city === "";
    this.countryInvalid = this.country === "";
    this.postCodeInvalid = this.postCode === "";

    return (
      !this.streetInvalid &&
      !this.cityInvalid &&
      !this.countryInvalid &&
      !this.postCodeInvalid
    );
  }

  handleModalClose(value: boolean) {
    this.successModalOpen = value;
  }
}
</script>

<style scoped lang="scss"></style>
