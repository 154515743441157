<template>
  <h5
    v-bind="$attrs"
    :class="[
      {
        'font-flohh-font-bold': weight === '700',
        'font-flohh-font-semi-bold': weight === '600',
        'font-flohh-font-medium': weight === '500',
        'font-flohh-font-regular': weight === '400',
        'font-flohh-font-light': weight === '300',
      },
      'text-flohh-h5',
      color,
    ]"
  >
    <slot>{{ label }}</slot>
  </h5>
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";

@Component({ components: {} })
export default class HeadingFive extends Vue {
  @Prop({
    type: String,
    default: "400",
    validator: (value) => {
      return ["300", "400", "500", "600", "700"].includes(value);
    },
  })
  weight!: string;

  @Prop({
    type: String,
    default: "Heading 5 Component",
  })
  label!: string;

  @Prop({
    type: String,
    default: "",
  })
  color!: string;
}
</script>
