import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", _mergeProps(_ctx.$attrs, {
    class: [
      {
        'font-flohh-font-bold': _ctx.weight === 'bd',
        'font-flohh-font-medium': _ctx.weight === 'md',
        'font-flohh-font-regular': _ctx.weight === 'rg',
        'text-flohh-text-title': _ctx.size === 'title',
        'text-flohh-text-body': _ctx.size === 'body',
        'text-flohh-text-subtitle': _ctx.size === 'subtitle',
        'text-flohh-text-caption': _ctx.size === 'caption',
        'text-flohh-text-small': _ctx.size === 'small',
      },
      _ctx.color,
    ]
  }), [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ])
  ], 16))
}