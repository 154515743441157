<template>
  <div v-if="activePlan && accountInfo" class="flex flex-col gap-[40px]">
    <MembersCountComponent :activePlan="activePlan" />
    <MembersListComponent
      :activePlan="activePlan"
      :accountInfo="accountInfo"
    /><LeaveTeamComponent :activePlan="activePlan" />
  </div>
  <div v-else class="grid py-10 place-items-center"><ProgressLoader /></div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import { useActivePlanStore } from "@/stores/activePlan";
import { ActivePlan } from "@/components/PlanAndBilling/type";
import { useAccountInfoStore } from "@/stores/accountInfo";
import { AccountBasicInformation } from "@/components/Account/type";

import MembersCountComponent from "@/components/Members/MembersCount/MembersCountComponent.vue";
import MembersListComponent from "@/components/Members/MembersList/MembersListComponent.vue";
import LeaveTeamComponent from "@/components/Members/LeaveTeam/LeaveTeamComponent.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

@Component({
  components: {
    MembersCountComponent,
    LeaveTeamComponent,
    MembersListComponent,
    ProgressLoader,
  },
})
export default class MembersComponent extends Vue {
  accountInfoStore = useAccountInfoStore();
  accountInfo!: AccountBasicInformation;
  activePlanStore = useActivePlanStore();
  activePlan!: ActivePlan | null;

  @Watch("activePlanStore.activePlan")
  activePlanWatcher(activePlan: ActivePlan) {
    this.activePlan = activePlan;
  }

  @Watch("accountInfoStore.accountInfo")
  accountInfoWatcher(accountInfo: AccountBasicInformation) {
    this.setAccountInfo(accountInfo);
  }

  created() {
    this.fetchPlans();
    if (this.accountInfoStore.accountInfo) {
      this.setAccountInfo(this.accountInfoStore.accountInfo);
    }
  }

  async fetchPlans() {
    this.activePlan = await this.activePlanStore.fetchActivePlan();
  }

  setAccountInfo(accountInfo: AccountBasicInformation) {
    this.accountInfo = accountInfo;
  }
}
</script>

<style scoped lang="scss"></style>
