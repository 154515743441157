<template>
  <div class="flex justify-between items-start mb-6">
    <h6 class="text-flohh-text-title font-flohh-font-bold">Billing History</h6>
    <div class="flex items-center gap-2">
      <IconizeInput
        v-model="searchText"
        icon="searchIcon"
        placeholder="Search..."
        class="text-flohh-text-body"
      />
      <FormDropdown
        v-model="sortValue"
        :options="sorts"
        optionLabel="label"
        optionValue="value"
        :itemSize="46"
        placeholder="Sort by..."
      />
    </div>
  </div>
  <div v-if="!loading">
    <Table :tableData="tableDataModel" tableKey="billing-history">
      <Column header="Reference No."
        ><template #body="cell"
          ><p class="uppercase whitespace-nowrap text-ellipsis">
            {{ getReferenceNumber(cell.data) }}
          </p>
        </template></Column
      >
      <Column header="Type"><template #body>Invoice</template> </Column>
      <Column header="Date"
        ><template #body="cell">{{
          formatDate(cell.data.createdAt, "dd MMMM yyyy")
        }}</template></Column
      >
      <Column header="Amount"
        ><template #body="cell">{{
          formatPrice(cell.data.totalAmount.value)
        }}</template></Column
      >
      <Column
        header="Invoice Status"
        :style="{
          paddingTop: '10px',
          paddingBottom: '10px',
        }"
        ><template #body="cell"
          ><StatusBadge :status="cell.data.status" /></template
      ></Column>
      <Column
        ><template #body="cell">
          <button
            class="center-content"
            @click="() => handleViewInvoice(cell.data)"
          >
            <span v-html="icons.eyeIcon"></span></button
        ></template>
      </Column>
    </Table>
  </div>
  <div v-else class="grid place-items-center py-10">
    <ProgressLoader />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import {
  Invoice,
  InvoiceAttachment,
  TransactionAttachment,
  Transaction,
} from "@/components/PlanAndBilling/type";
import Column from "primevue/column";
import { icons } from "@/const/icons";
import { formatDate } from "@/utils/dateFormatter";
import { formatPrice } from "@/utils/priceFormatter";
import { AxiosResponse } from "axios";
import PlanAndBillingService from "@/services/PlanAndBillingService";
import { sortBillingHistoryTable } from "@/utils/tableSorter";

import Table from "@/components/utilities/Table.vue";
import IconizeInput from "@/components/utilities/IconizeInput.vue";
import FormDropdown from "@/components/utilities/AppFormFields/FormDropdown.vue";
import StatusBadge from "@/components/utilities/StatusBadge.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

@Component({
  components: {
    Table,
    Column,
    IconizeInput,
    FormDropdown,
    StatusBadge,
    ProgressLoader,
  },
})
export default class BillingHistoryComponent extends Vue {
  private planAndBillingService: PlanAndBillingService =
    new PlanAndBillingService();
  icons = icons;

  sortValue = "";
  sorts = [
    {
      label: "Date: Newest to Oldest",
      value: "date_descending",
    },
    {
      label: "Date: Oldest to Newest",
      value: "date_ascending",
    },
    {
      label: "Amount: Highest to Lowest",
      value: "amount_descending",
    },
    {
      label: "Amount: Lowest to Highest",
      value: "amount_ascending",
    },
  ];

  tableData: Invoice[] = [];
  tableDataModel: Invoice[] = [];
  searchText = "";
  loading = false;

  @Watch("sortValue")
  sortValueWatcher(value: string) {
    if (value && this.tableData.length > 0) {
      this.tableDataModel = sortBillingHistoryTable(value, this.tableData);
    }
  }

  @Watch("searchText")
  searchTextWatcher(value: string) {
    this.tableDataModel = this.searchTable(value);
  }

  mounted() {
    this.setTableData();
  }

  async setTableData() {
    try {
      this.loading = true;
      const response: AxiosResponse =
        await this.planAndBillingService.fetchBillingHistory();

      if (response.data.ok) {
        this.tableData = response.data.data;
        this.tableDataModel = response.data.data;
        this.sortValue = "date_descending";
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  getReferenceNumber(invoice: Invoice) {
    try {
      let selectedTransaction: Transaction[] = [];
      if (invoice.transactions.length > 0) {
        selectedTransaction = invoice.transactions.filter(
          (transaction: Transaction) =>
            transaction.attachments[0].name === "Stripe payment receipt url"
        );
        return selectedTransaction[0].paymentReference;
      }
      return "";
    } catch (error) {
      console.error(error);
    }
  }

  handleViewInvoice(invoice: Invoice) {
    let selectedAttachment;
    if (invoice.transactions.length > 0) {
      selectedAttachment = invoice.transactions[0].attachments.find(
        (attachment: TransactionAttachment) =>
          attachment.name === "Stripe payment receipt url"
      );
    } else {
      selectedAttachment = invoice.attachments.find(
        (attachment: InvoiceAttachment) => attachment.name === "invoice url"
      );
    }
    if (selectedAttachment) {
      window.open(selectedAttachment.value, "_blank");
    }
  }

  searchTable(text: string) {
    const query = text.toLowerCase();

    return this.tableData.filter((invoice: Invoice) => {
      const matchesTotalAmount = invoice.totalAmount.value
        .toString()
        .includes(query);
      const formattedDate = this.formatDate(invoice.createdAt, "dd MMMM yyyy");
      const matchesCreatedAt = formattedDate.toLowerCase().includes(query);

      const matchesTransaction = invoice.transactions.some((transaction) =>
        transaction.paymentReference.toLowerCase().includes(query)
      );

      return matchesTotalAmount || matchesCreatedAt || matchesTransaction;
    });
  }

  formatDate(dateString: string, format: string) {
    return formatDate(dateString, format);
  }

  formatPrice(price: number) {
    return formatPrice(price);
  }
}
</script>

<style scoped lang="scss">
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  width: 100%;
}
</style>
