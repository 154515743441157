<template>
  <div v-if="!hidePasswordForm" class="flex gap-[43px]">
    <BasicInformationFormComponent /><PasswordFormComponent />
  </div>
  <div v-else class="max-w-[600px]"><BasicInformationFormComponent /></div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { useAccountInfoStore } from "@/stores/accountInfo";

import BasicInformationFormComponent from "@/components/Account/BasicInformationFormComponent.vue";
import PasswordFormComponent from "@/components/Account/PasswordFormComponent.vue";

@Component({
  components: {
    BasicInformationFormComponent,
    PasswordFormComponent,
  },
})
export default class AccountComponent extends Vue {
  loginMethods: string[] = [];
  accountInfoStore = useAccountInfoStore();
  hidePasswordForm = false;

  mounted() {
    const loginMethods = this.accountInfoStore.accountInfo
      ? this.accountInfoStore.accountInfo.loginMethods
      : ["email"];

    if (loginMethods.includes("google") || loginMethods.includes("microsoft")) {
      this.hidePasswordForm = true;
    }
  }
}
</script>

<style scoped lang="scss"></style>
