<template>
  <LoginViaEmail v-if="$route.query.type && $route.query.type === 'email'" />
  <LoginViaGoogle
    v-else-if="$route.query.type && $route.query.type === 'google'"
  />
  <LoginViaMicrosoft
    v-else-if="$route.query.type && $route.query.type === 'microsoft'"
  />
  <div v-else class="max-w-[350px] flex flex-col gap-10 w-full">
    <h6 class="font-flohh-font-bold text-flohh-h6 text-[#404040] text-center">
      Verify Your Account
    </h6>
    <div class="flex flex-col w-full gap-2">
      <button class="buttons" @click="() => handleLoginViaSocial('google')">
        <span v-html="icons.googleIcon" />
        <p class="text-flohh-text-body font-flohh-font-medium">
          Continue with Google
        </p>
      </button>
      <button class="buttons" @click="() => handleLoginViaSocial('microsoft')">
        <span v-html="icons.microsoftIcon" />
        <p class="text-flohh-text-body font-flohh-font-medium">
          Continue with Microsoft
        </p>
      </button>
      <div class="my-4">
        <p class="text-center text-flohh-text-body font-flohh-font-medium">
          Or
        </p>
      </div>
      <button class="buttons" @click="handleLoginViaEmail">
        <span v-html="icons.mailIcon" />
        <p class="text-flohh-text-body font-flohh-font-medium">
          Continue with Email
        </p>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { icons } from "@/const/icons";

import LoginViaEmail from "@/components/Authentication/Login/LoginViaEmail.vue";
import LoginViaGoogle from "@/components/Authentication/Login/LoginViaGoogle.vue";
import LoginViaMicrosoft from "@/components/Authentication/Login/LoginViaMicrosoft.vue";

@Component({
  components: { LoginViaEmail, LoginViaGoogle, LoginViaMicrosoft },
})
export default class LoginComponent extends Vue {
  icons = icons;

  BASE_URL = `${process.env.VUE_APP_API_URL}/authentication/social`;

  handleLoginViaSocial(type: string) {
    const redirectURL = `${window.location.origin}${window.location.pathname}?type=${type}`;

    window.location.href = `${this.BASE_URL}/${type}?authenticatingAs=teacher&successRedirectUrl=${redirectURL}&failedRedirectUrl=${redirectURL}`;
  }

  handleLoginViaEmail() {
    this.$router.push({
      name: "Login",
      query: {
        type: "email",
      },
    });
  }
}
</script>

<style scoped lang="scss">
.buttons {
  border: 1px solid #d9d9d9;
  padding: 9px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;

  &:hover {
    filter: brightness(0.8);
  }
}
</style>
