<template>
  <PageViewContainer
    ><div
      class="p-[28px] bg-white rounded-lg border border-solid border-flohh-neutral-85"
    >
      <PageNavHead selected="Members" />
      <MembersComponent /></div
  ></PageViewContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import PageViewContainer from "@/components/Layout/Partials/PageViewContainer.vue";
import MembersComponent from "@/components/Members/MembersComponent.vue";
import PageNavHead from "@/components/utilities/PageNavHead.vue";

@Component({
  components: { PageNavHead, MembersComponent, PageViewContainer },
})
export default class ProfileView extends Vue {}
</script>

<style scoped lang="scss"></style>
