<template>
  <div
    v-if="subscription"
    class="flex items-center gap-2 px-6 py-4 mb-10 rounded-lg bg-flohh-secondary-blue"
  >
    <span v-html="icons.infoCircleIcon" />
    <p class="text-flohh-text-caption">
      You still have pending payment that needs to be completed.
      <a
        :href="subscription.paymentUrl"
        class="underline cursor-pointer"
        target="_blank"
        >Click Here To Checkout</a
      >.
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import { usePendingSubscriptionStore } from "@/stores/pendingSubscription";
import { icons } from "@/const/icons";
import {
  PendingSubscription,
  Subscription,
} from "@/components/PlanAndBilling/type";

import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import CancelSubscriptionModal from "@/components/PlanAndBilling/CancelSubscription/CancelSubscriptionModal.vue";

@Component({
  components: { PrimaryButton, CancelSubscriptionModal },
})
export default class AbandonedCartComponent extends Vue {
  pendingSubscriptionStore = usePendingSubscriptionStore();
  icons = icons;

  cancelModalOpen = false;
  pendingSubscription!: PendingSubscription;
  subscription!: Subscription;

  handleCancelSubscription() {
    this.cancelModalOpen = true;
  }

  @Watch("pendingSubscriptionStore.pendingSubscription")
  pendingSubscriptionWatcher(pendingSubscription: PendingSubscription) {
    this.pendingSubscription = pendingSubscription;
    this.setSubscription(pendingSubscription);
  }

  async mounted() {
    const pendingSubscription =
      await this.pendingSubscriptionStore.fetchPendingSubscription();
    if (pendingSubscription) {
      this.pendingSubscription = pendingSubscription;
      this.setSubscription(pendingSubscription);
    }
  }

  setSubscription(pendingSubscription: PendingSubscription) {
    if (pendingSubscription.subscriptions.length > 0) {
      this.subscription = pendingSubscription.subscriptions[0];
    }
  }
}
</script>

<style scoped lang="scss"></style>
