<template>
  <div>
    <SidePanelNavigation @onNavToggle="handleNavToggle" />
    <div class="main-content" id="main-content">
      <TopNavigation
        v-model:sidebarOpen="sidebarOpen"
        v-model:hideNavigation="hideNavigation"
      />

      <main class="">
        <div id="custom-overlay-menu" class="relative"></div>
        <slot />
      </main>
    </div>
  </div>
  <StripePaymentStatus />
</template>

<script lang="ts">
import { Vue, Component } from "vue-facing-decorator";
import { icons } from "@/const/icons";
import axios, { AxiosResponse } from "axios";
import AuthenticationService from "@/services/AuthenticationService";
import { useAuthStore } from "@/stores/authStore";
import { useAccountInfoStore } from "@/stores/accountInfo";

import SidePanelNavigation from "@/components/Layout/Partials/SidePanelNavigation.vue";
import TopNavigation from "@/components/Layout/Partials/TopNavigation.vue";
import StripePaymentStatus from "@/components/utilities/StripePaymentStatus.vue";

@Component({
  components: {
    SidePanelNavigation,
    TopNavigation,
    StripePaymentStatus,
  },
})
export default class AppLayout extends Vue {
  private authenticationService: AuthenticationService =
    new AuthenticationService();
  authStore = useAuthStore();
  accountInfoStore = useAccountInfoStore();

  hideNavigation = false;
  sidebarOpen = false;
  icons = icons;
  reloggingIn = false;

  handleNavToggle(value: boolean) {
    const main = document.getElementById("main-content");
    if (main) {
      if (value) {
        main.classList.add("extended-nav");
      } else {
        main.classList.remove("extended-nav");
      }
    }
  }

  created() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          const code = this.$route.query.code as string;
          const token = this.$route.query.token as string;
          if (code && token) {
            this.handleRelogin(code, token);
            return;
          }
          localStorage.clear();
          if (this.$route.meta.requiresAuth) {
            this.$router.push({ name: "Login" });
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async handleRelogin(code: string, token: string) {
    try {
      if (!this.authStore.accessToken && !this.reloggingIn) {
        this.reloggingIn = true;
        const response: AxiosResponse =
          await this.authenticationService.verifyOTP(
            code,
            token,
            "teacher_account_portal_access_refresh"
          );

        if (response.data.ok) {
          this.authStore.storeAccessToken(response.data.data.accessToken);

          await this.accountInfoStore.fetchAccountInfo();

          const currentPath = this.$route.path;
          this.$router.push({ path: currentPath });
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      this.$router.push({ name: "Login" });
      console.error(error);
    } finally {
      this.reloggingIn = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  padding-left: 64px;
  transition: all 0.5s ease;
  height: 100vh;
  overflow: auto;
}
.extended-nav {
  padding-left: 244px !important;
}
</style>
