import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "sticky top-0 z-[50] flex h-[4.3rem] shrink-0 items-center gap-x-4 border-b border-gray-200 bg-flohh-neutral-100 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "flex items-center self-stretch justify-between w-full" }
const _hoisted_3 = { class: "font-flohh-font-bold" }
const _hoisted_4 = { class: "flex gap-1" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex items-center gap-4" }
const _hoisted_8 = {
  key: 0,
  class: "font-flohh-font-medium text-flohh-text-subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserMenu = _resolveComponent("UserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.pageTitle), 1),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (breadcrumb, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "flex gap-1 font-flohh-font-medium text-flohh-text-small"
            }, [
              (index !== 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, "/"))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                onClick: ($event: any) => (_ctx.handleClickBreadcrumb(breadcrumb.name))
              }, _toDisplayString(breadcrumb.label), 9, _hoisted_6)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.accountInfo)
          ? (_openBlock(), _createElementBlock("p", _hoisted_8, " Hey " + _toDisplayString(_ctx.accountInfo.profile.firstName) + " " + _toDisplayString(_ctx.accountInfo.profile.lastName) + "! ", 1))
          : _createCommentVNode("", true),
        _createVNode(_component_UserMenu, { accountInfo: _ctx.accountInfo }, null, 8, ["accountInfo"])
      ])
    ])
  ]))
}