import { defineStore } from "pinia";
import { ref } from "vue";
import { ActivePlan } from "@/components/PlanAndBilling/type";
import { AxiosResponse } from "axios";

import PlanAndBillingService from "@/services/PlanAndBillingService";

const planAndBillingService: PlanAndBillingService =
  new PlanAndBillingService();
const lastFetch = ref<number>(Date.now());
const CACHE_DURATION = 300000; // 5 minutes

export const useActivePlanStore = defineStore("activePlan", () => {
  const activePlan = ref<ActivePlan | null>(null);

  const isLoading = ref(false);
  const error = ref<string | null>(null);

  const fetchActivePlan = async (skipCache = false) => {
    const now = Date.now();
    if (
      activePlan.value &&
      now - lastFetch.value < CACHE_DURATION &&
      !skipCache
    ) {
      setOwnershipType(activePlan.value);
      return activePlan.value;
    }

    isLoading.value = true;
    error.value = null;
    try {
      const response: AxiosResponse =
        await planAndBillingService.fetchActivePlan();
      setOwnershipType(response.data.data);
      activePlan.value = response.data.data;
      lastFetch.value = Date.now();
    } catch (err) {
      error.value = "Failed to fetch active plan";
      console.error(err);
    } finally {
      isLoading.value = false;
    }

    return activePlan.value;
  };

  return {
    activePlan,
    isLoading,
    error,
    fetchActivePlan,
  };
});

const setOwnershipType = (activePlan: ActivePlan) => {
  localStorage.setItem("ownershipType", activePlan.ownershipType);
};
