<template>
  <div class="flex flex-col gap-[8px] w-full">
    <Text v-if="label" color="text-flohh-neutral-35" size="body" weight="md">{{
      label
    }}</Text>
    <Dropdown
      v-model="value"
      :options="options"
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      :virtualScrollerOptions="{ itemSize: itemSize }"
      :placeholder="placeholder"
      :filter="filter"
      filterPlaceholder="Search"
      :style="{
        outline: 'none',
        boxShadow: 'none',
      }"
      :class="[
        {
          'p-invalid': errorMessage && required,
        },
        'w-full border-flohh-neutral-70 rounded-lg border-solid border',
      ]"
      :pt="{
        item: { class: 'text-flohh-text-body text-flohh-neutral-20' },
        input: {
          class: [
            'px-[10px] py-[8px] text-flohh-text-body text-flohh-neutral-20',
          ],
        },
      }"
      :required="required"
      v-bind="$attrs"
      @change="handleUpdate"
    />
    <p
      v-if="errorMessage"
      class="ml-1 text-flohh-error-red text-flohh-text-caption"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Model, Watch } from "vue-facing-decorator";
import Text from "@/components/utilities/AppTypographies/Text.vue";
import Dropdown from "primevue/dropdown";

@Component({ components: { Text, Dropdown } })
export default class FormDropdown extends Vue {
  @Model({
    type: String,
    default: "",
    required: true,
  })
  value!: string;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;

  @Prop({
    type: Array,
    default: [],
  })
  options!: [];

  @Prop({
    type: String,
    default: "label",
  })
  optionLabel!: string;

  @Prop({
    type: String,
    default: "value",
  })
  optionValue!: string;

  @Prop({
    type: Number,
    default: 46,
  })
  itemSize!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  filter!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: "",
  })
  invalidMessage!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  required!: boolean;

  errorMessage = "";

  @Watch("invalid")
  invalidWatcher(value: boolean) {
    this.errorMessage = value ? this.invalidMessage : "";
  }

  @Watch("value")
  valueWatcher(newValue: string, oldValue: string) {
    if (this.required) {
      if (!newValue && oldValue) {
        this.errorMessage = this.invalidMessage;
      } else if (newValue && !oldValue) {
        this.errorMessage = "";
      }
    }
  }

  handleUpdate(event: { originalEvent: Event; value: string | number }) {
    this.$emit("onUpdate", event.value);
  }
}
</script>
