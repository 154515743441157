<template>
  <button
    :type="type"
    :disabled="disabled"
    :class="[
      getColor(),
      `flex justify-center items-center gap-x-2 rounded-lg text-flohh-text-caption py-2 px-4 hover:brightness-95 bg-transparent border border-solid font-flohh-font-medium`,
      {
        'cursor-not-allowed': disabled,
        'cursor-progress': loading,
      },
    ]"
    v-bind="$attrs"
  >
    <span
      v-if="loading"
      class="animate-spin"
      v-html="icons.loadingIconBlack"
    ></span>
    <slot>{{ label }}</slot>
  </button>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import { icons } from "@/const/icons";

@Component({ components: {} })
export default class SecondaryButton extends Vue {
  icons = icons;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: String,
    default: "flohh-primary-pink",
  })
  color!: string;

  @Prop({
    type: String,
    default: "button",
  })
  type!: "button" | "submit" | "reset";

  getColor() {
    return `!border-${this.color} text-${this.color}`;
  }
}
</script>

<style scoped></style>
