<template>
  <WizardModal
    v-model="visible"
    :steps="steps"
    :currentPage="currentPage"
    :processing="isCanceling"
    @onClose="handleModalClose"
    @onGoBack="handleGoBack"
    @onPrimaryButtonClicked="handlePrimaryBtnClick"
    @onSecondaryButtonClicked="handleSecondaryBtnClick"
  >
    <template #body>
      <div v-show="currentPage === 0" class="flex flex-col gap-6">
        <div
          class="flex items-center gap-2 px-6 py-4 rounded-lg bg-flohh-secondary-blue"
        >
          <span v-html="icons.infoCircleIcon" />
          <p class="text-flohh-text-caption">
            You still have <strong>{{ daysRemaining }} left</strong> on your
            plan.
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <div class="flex items-center gap-2">
            <span v-html="icons.starIcon" />
            <p class="text-flohh-text-body">
              40% faster grading without all the excess admin tasks.
            </p>
          </div>
          <div class="flex items-center gap-2">
            <span v-html="icons.starIcon" />
            <p class="text-flohh-text-body">
              Grade both formative and summative work and consolidate all your
              grading workflows into one.
            </p>
          </div>
          <div class="flex items-center gap-2">
            <span v-html="icons.starIcon" />
            <p class="text-flohh-text-body">
              Detailed grade breakdowns for micro-learning insights with our
              <strong>auto Guttman charts</strong> and see students learning
              growth.
            </p>
          </div>
          <div class="flex items-center gap-2">
            <span v-html="icons.starIcon" />
            <p class="text-flohh-text-body">
              Bulk upload, store and manage all student work in one place with
              the <strong>Gradebook for teachers</strong> and
              <strong>Bulk Upload.</strong>
            </p>
          </div>
          <div class="flex items-center gap-2">
            <span v-html="icons.starIcon" />
            <p class="text-flohh-text-body">
              Focus on learning not grades with
              <strong>Learning Goal Loops.</strong>
            </p>
          </div>
        </div>
      </div>
      <div v-show="currentPage === 1" class="flex flex-col gap-6">
        <div
          class="flex items-center gap-2 px-6 py-4 rounded-lg bg-flohh-secondary-blue"
        >
          <span v-html="icons.infoCircleIcon" />
          <p class="text-flohh-text-caption">
            You still have <strong>{{ daysRemaining }} left</strong> on your
            plan.
          </p>
        </div>
        <div class="flex flex-col gap-4 pl-4">
          <FormCheckbox
            v-for="(item, index) in reasons"
            required
            :id="item.id"
            :key="index"
            @change="(e: InputEvent) => handleCheckboxChange(e, item.value)"
            ><label
              :for="item.id"
              class="cursor-pointer text-flohh-text-caption"
            >
              {{ item.label }}
            </label></FormCheckbox
          >
          <p
            v-if="reasonInvalid"
            class="ml-1 text-flohh-error-red text-flohh-text-caption"
          >
            Feedback is required
          </p>
        </div>
        <div v-if="reasonsPayload.includes('Another reason')" class="pl-4">
          <FormTextArea
            v-model="anotherReason"
            placeholder="Type Something"
            invalidMessage="Feedback is required"
            required
            :invalid="anoterReasonInvalid"
          />
        </div>
      </div>
      <div v-show="currentPage === 2">
        <div
          v-if="activePlan.subscription.plan.slug === 'organisation'"
          class="flex flex-col gap-10"
        >
          <div>
            <p class="mb-4 text-flohh-text-body">
              By proceeding you will lose the following features
            </p>
            <ul>
              <li class="flex gap-2 mb-4 text-flohh-text-body">
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Unlimited Submissions:</strong> You will lose access
                  to premium You will no longer have access to unlimited
                  submissions.
                </p>
              </li>
              <li class="flex gap-2 mb-4 text-flohh-text-body">
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Integration privileges:</strong> You will no longer
                  have access to your current CMS and SIS integrations. Only
                  Google Classroom, Google and Microsoft will remain.
                </p>
              </li>
              <li class="flex gap-2 mb-4 text-flohh-text-body">
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Team access:</strong> All members of your team will be
                  downgraded to the free plan and will immediately lose access
                  to paid features.
                </p>
              </li>
            </ul>
          </div>
          <hr />
          <div class="flex items-center">
            <img src="@/assets/computer-img.png" alt="computer" />
            <div>
              <p
                class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35"
              >
                Keep your organisation plan to support your unique grading
                workflow.
              </p>
              <p class="text-flohh-text-body text-flohh-neutral-35">
                From integrating with your existing systems to aligning grading
                practices across teams, Flohh ensures flexibility for every
                teacher at {{ accountInfo.schoolName }}. Build a workflow that
                fits your needs without silos or extra steps.
              </p>
            </div>
          </div>
        </div>
        <div
          v-else-if="activePlan.subscription.plan.slug === 'teams'"
          class="flex flex-col gap-10"
        >
          <div>
            <p class="mb-4 text-flohh-text-body">
              By proceeding you will lose the following features
            </p>
            <ul>
              <li class="flex gap-2 mb-4 text-flohh-text-body">
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Unlimited Submissions:</strong>
                  You will no longer have access to unlimited submissions.
                </p>
              </li>
              <li class="flex gap-2 mb-4 text-flohh-text-body">
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Team access:</strong> All members of your team will be
                  downgraded to the free plan and will immediately lose access
                  to paid features.
                </p>
              </li>
            </ul>
          </div>
          <hr />
          <div class="flex items-center">
            <img src="@/assets/computer-img.png" alt="computer" />
            <div>
              <p
                class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35"
              >
                Keep your team plan for consistent grading and clear insights.
              </p>
              <p class="text-flohh-text-body text-flohh-neutral-35">
                Ensure every teacher stays aligned with shared grading standards
                and learning goals, while accessing real-time cohort data to
                spot student needs faster—saving time on admin so you can focus
                on supporting your students.
              </p>
            </div>
          </div>
        </div>
        <div
          v-else-if="activePlan.subscription.plan.slug === 'teacher-pro'"
          class="flex flex-col gap-10"
        >
          <div>
            <p class="mb-4 text-flohh-text-body">
              By proceeding you will lose the following features
            </p>
            <ul>
              <li class="flex gap-2 mb-4 text-flohh-text-body">
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Unlimited Submissions:</strong>
                  You will no longer have access to unlimited submissions.
                </p>
              </li>
              <li class="flex gap-2 mb-4 text-flohh-text-body">
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Grading tools:</strong> You will lose access to full
                  formative and summative grading capabilities in one place
                </p>
              </li>
              <li class="flex gap-2 mb-4 text-flohh-text-body">
                <span v-html="icons.starIcon" />
                <p>
                  <strong>Your access:</strong> You will be downgraded to the
                  free plan and will immediately lose access to paid features.
                </p>
              </li>
            </ul>
          </div>
          <hr />
          <div class="flex items-center">
            <img src="@/assets/computer-img.png" alt="computer" />
            <div>
              <p
                class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35"
              >
                Keep Teacher Pro for faster, all-in-one grading.
              </p>
              <p class="text-flohh-text-body text-flohh-neutral-35">
                With Teacher Pro, you can grade both formative and summative
                assessments—handwritten or digital—in one place. Avoid
                repetitive tasks and admin overload, and enjoy 40% faster
                grading with unlimited submissions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </WizardModal>
  <SuccessModal
    v-model="successModalOpen"
    title="Your plan has been cancelled"
    subtitle="We have downgraded your plan to free and removed the paid feature access from your account. <br/><br/>All future payments and billing have been cancelled."
    @onClose="successModalOpen = false"
  />
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from "vue-facing-decorator";
import PlanAndBillingService from "@/services/PlanAndBillingService";
import { ActivePlan } from "@/components/PlanAndBilling/type";
import { AxiosResponse } from "axios";
import { IWizardModalStep } from "@/components/utilities/type";
import { icons } from "@/const/icons";
import { reasons } from "@/const/reasons";
import { useActivePlanStore } from "@/stores/activePlan";
import { useAccountInfoStore } from "@/stores/accountInfo";
import { calculateDays, getDateBefore } from "@/utils/dateCalculator";
import { AccountBasicInformation } from "@/components/Account/type";

import WizardModal from "@/components/utilities/AppModals/WizardModal.vue";
import SuccessModal from "@/components/utilities/AppModals/SuccessModal.vue";
import FormTextArea from "@/components/utilities/AppFormFields/FormTextArea.vue";
import FormCheckbox from "@/components/utilities/AppFormFields/FormCheckBox.vue";

@Component({
  components: {
    WizardModal,
    SuccessModal,
    FormTextArea,
    FormCheckbox,
  },
})
export default class CancelSubscriptionModal extends Vue {
  private planAndBillingService: PlanAndBillingService =
    new PlanAndBillingService();
  activePlanStore = useActivePlanStore();
  accountInfoStore = useAccountInfoStore();

  icons = icons;
  reasons = reasons;

  @Model({
    type: Boolean,
    default: false,
  })
  visible!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  activePlan!: ActivePlan;

  isCanceling = false;
  successModalOpen = false;

  daysRemaining = "";
  currentPage = 0;

  reasonInvalid = false;

  anotherReason = "";
  anoterReasonInvalid = false;

  reasonsPayload: string[] = [];
  accountInfo!: AccountBasicInformation;

  steps: IWizardModalStep[] = [
    {
      title: "You’ll miss out on...",
      primaryBtnLabel: "Continue Cancellation",
      secondaryBtnLabel: "Keep my Plan",
    },
    {
      title: "Why do you want to cancel?",
      subtitle: "We’d love your feedback on how we can improve",
      primaryBtnLabel: "Continue Cancellation",
      secondaryBtnLabel: "Keep my Plan",
    },
    {
      title: "Are you sure you want to cancel your subscription?",
      primaryBtnLabel: "Confirm Cancellation",
      secondaryBtnLabel: "Keep my Plan",
    },
  ];

  @Watch("visible")
  visibleWatcher(value: boolean) {
    if (value) {
      this.setSubscriptionTimeRemaining();
      if (this.accountInfoStore.accountInfo) {
        this.accountInfo = this.accountInfoStore.accountInfo;
      }
    }
  }

  setSubscriptionTimeRemaining() {
    const billingSchedule = this.activePlan.subscription.billingSchedule;
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    let nextBillingDate = "";

    if (this.activePlan.nextBillingDate) {
      nextBillingDate = this.activePlan.nextBillingDate;
    } else {
      if (billingSchedule === "monthly") {
        nextDate.setMonth(currentDate.getMonth() + 1);
      } else if (billingSchedule === "yearly") {
        nextDate.setFullYear(currentDate.getFullYear() + 1);
      }
      nextBillingDate = getDateBefore(nextDate.toISOString(), 0);
    }

    this.daysRemaining = calculateDays("current", nextBillingDate);
  }

  async handleCancelSubscription() {
    try {
      this.isCanceling = true;

      const reasonsPayload: string[] = this.handleReasonsPayload();

      const response: AxiosResponse =
        await this.planAndBillingService.cancelSubscription(
          this.activePlan.subscription.uuid,
          reasonsPayload
        );

      if (response.data.ok) {
        await this.activePlanStore.fetchActivePlan(true);
        this.handleModalClose();
        this.successModalOpen = true;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isCanceling = false;
    }
  }

  handleReasonsPayload() {
    const reasonsPayload = this.reasonsPayload;
    let reasons = reasonsPayload;

    if (reasonsPayload.includes("Another reason")) {
      reasons = reasonsPayload.filter(
        (item: string) => item !== "Another reason"
      );
      reasons.push(`Another reason: ${this.anotherReason}`);
      return reasons;
    }
    return reasonsPayload;
  }

  validateForm() {
    let valid = false;
    if (this.reasonsPayload.includes("Another reason") && !this.anotherReason) {
      this.anoterReasonInvalid = true;
      valid = false;
    } else {
      valid = true;
      if (this.reasonsPayload.length <= 0) {
        this.reasonInvalid = true;
        valid = false;
      }
    }

    return valid;
  }

  handlePrimaryBtnClick() {
    if (this.currentPage === 0) {
      this.currentPage = 1;
    } else if (this.currentPage === 1) {
      const isValid = this.validateForm();

      if (!isValid) {
        return;
      }

      this.currentPage = 2;
    } else {
      this.handleCancelSubscription();
    }
  }

  handleCheckboxChange(event: InputEvent, reason: string) {
    const element = event.target as HTMLInputElement;
    const checked = element.checked;
    const reasonsPayload: string[] = this.reasonsPayload;

    if (checked && !reasonsPayload.includes(reason)) {
      reasonsPayload.push(reason);
      this.reasonsPayload = reasonsPayload;
    } else if (!checked && reasonsPayload.includes(reason)) {
      this.reasonsPayload = reasonsPayload.filter(
        (item: string) => reason !== item
      );
    }

    this.reasonInvalid = this.reasonsPayload.length <= 0;
  }

  handleGoBack(goToPageNumber: number) {
    this.currentPage = goToPageNumber;
  }

  handleSecondaryBtnClick() {
    this.currentPage = 0;
    this.reasonsPayload = [];
    this.$emit("onClose", false);
  }

  handleModalClose() {
    this.currentPage = 0;
    this.reasonsPayload = [];
    this.$emit("onClose", false);
  }
}
</script>

<style scoped lang="scss"></style>
