<template>
  <HeadlessModal
    v-model="open"
    @onClose="handleModalClose"
    :title="
      isUpgrade ? 'Upgrade Your Subscription' : 'Downgrade Your Subscription'
    "
  >
    <template #body>
      <div class="flex flex-col gap-6">
        <p class="text-flohh-text-title font-flohh-font-bold">
          Team Information
        </p>
        <div>
          <FormInput
            type="number"
            v-model="seatCountModel"
            label="Seat Quantity"
            :invalid="seatCountModelInvalid"
            placeholder="Enter seat quantity"
            class="text-flohh-text-body"
            :class="[{ '!border-flohh-secondary-red': seatCountModelInvalid }]"
            :maxNumber="10000"
            required
          />
          <div v-if="seatCountModelInvalid">
            <p
              v-if="!seatCountModel"
              class="text-flohh-text-caption text-flohh-error-red"
            >
              Seat quantity is required
            </p>
            <p
              v-else-if="seatCountModel < occupiedSeat"
              class="text-flohh-text-caption text-flohh-error-red"
            >
              To reduce your seat quantity below {{ occupiedSeat }}, please
              adjust your team on the
              <strong
                ><button @click="handleRedirectToMembersPage" class="underline">
                  Manage Members
                </button></strong
              >
              page.
            </p>
            <p
              v-else-if="seatCountModel == totalSeat"
              class="text-flohh-text-caption text-flohh-error-red"
            >
              Seat quantity should not be equal to current seat quantity:
              <strong>{{ totalSeat }}</strong>
            </p>
          </div>
        </div>
        <div class="flex flex-col justify-center w-full gap-6 lg:flex-row">
          <div
            v-if="currentPlan && activePlan"
            class="flex-1 p-6 border border-solid rounded-lg border-flohh-neutral-70 bg-flohh-neutral-95"
          >
            <p class="mb-1 text-flohh-text-title font-flohh-font-bold">
              Current Plan: {{ currentPlan.name }}
            </p>
            <div class="flex justify-between">
              <div>
                <h5 class="text-flohh-h5 font-flohh-font-bold">
                  {{ formatPrice(currentPlan.price) }}
                </h5>
                <p class="text-flohh-text-caption">
                  {{ billingSchedule }}, per seat
                </p>
              </div>
              <div>
                <h5 class="text-right text-flohh-h5 font-flohh-font-bold">
                  {{ activePlan.subscription.consumers.length }}
                  {{
                    formatWord(activePlan.subscription.consumers.length, "seat")
                  }}
                </h5>
              </div>
            </div>
          </div>
          <div
            v-if="newPlan"
            class="flex-1 p-6 border border-solid rounded-lg border-flohh-neutral-70"
          >
            <p class="mb-1 text-flohh-text-title font-flohh-font-bold">
              New Plan: {{ newPlan.name }}
            </p>
            <div class="flex justify-between">
              <div>
                <h5 class="text-flohh-h5 font-flohh-font-bold">
                  {{ formatPrice(newPlan.price) }}
                </h5>
                <p class="text-flohh-text-caption">
                  {{ billingSchedule }}, per seat
                </p>
              </div>
              <div>
                <h5 class="text-right text-flohh-h5 font-flohh-font-bold">
                  {{ seatCountModel }} {{ formatWord(seatCountModel, "seat") }}
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-4">
          <p
            v-if="isUpgrade"
            class="text-flohh-text-caption text-flohh-neutral-35"
          >
            Your updated plan starts now. You’ll be charged a prorated amount
            for the additional seats for the rest of this billing period.
            Starting {{ getNextBillingDate() }}, your regular
            {{ billingSchedule }} charge of
            {{ formatPrice(newPlan.price * seatCountModel) }} will apply.
          </p>
          <p v-else class="text-flohh-text-caption text-flohh-neutral-35">
            Your new plan starts now. Starting
            {{ getNextBillingDate() }} your plan will automatically renew at the
            new {{ billingSchedule }} rate of
            {{ formatPrice(newPlan.price * seatCountModel) }}. Please note that
            no refund will be issued for the downgrade during this period.
          </p>
          <p class="text-flohh-text-caption text-flohh-neutral-35">
            <strong
              >You agree that your Flohh subscription will auto-renew and that
              you are happy for us to continue charging the updated
              {{ billingSchedule }} fee until you cancel. You may cancel or
              pause your subscription at any time to avoid future charges
            </strong>
          </p>
        </div>
        <PrimaryButton
          :label="isUpgrade ? 'Confirm Upgrade' : 'Confirm Downgrade'"
          color="flohh-secondary-green"
          class="py-3 text-flohh-text-subtitle"
          @click="handleClickConfirm"
          :disabled="seatCountModelInvalid || confirming"
          :loading="confirming"
        />
      </div>
    </template>
  </HeadlessModal>

  <DowngradePlanModal
    v-model="downgradePlanModalOpen"
    :activePlan="activePlan"
    :newPlan="newPlan"
    :newSeatCount="seatCountModel"
    :isCanceling="confirming"
    @onClose="downgradePlanModalOpen = false"
    @onConfirmDowngrade="handleConfirmPurchase"
  />
  <SuccessModal
    v-if="newPlan"
    v-model="successModalOpen"
    :title="isUpgrade ? 'Upgrade Successful' : 'Downgrade Successful'"
    :subtitle="`Your plan has been
  ${isUpgrade ? 'upgraded' : 'downgraded'} to ${newPlan.name} with
  ${seatCountModel} ${formatWord(seatCountModel, 'seat')}`"
    @onClose="successModalOpen = false"
  />
  <FailedModal
    v-model="failedModalOpen"
    title="Payment Failed"
    subtitle="We are sorry, there was an error processing your payment. Please try again."
    @onClose="failedModalOpen = false"
  />
</template>

<script lang="ts">
import { Component, Vue, Model, Prop, Watch } from "vue-facing-decorator";
import { icons } from "@/const/icons";
import { AxiosResponse } from "axios";
import PlanAndBillingService from "@/services/PlanAndBillingService";
import { ActivePlan, Price } from "@/components/PlanAndBilling/type";
import { usePlansStore } from "@/stores/plans";
import { formatPrice } from "@/utils/priceFormatter";
import { formatWord } from "@/utils/pluralFormatter";
import { formatDate } from "@/utils/dateFormatter";
import { useActivePlanStore } from "@/stores/activePlan";
import { getSeatCount } from "@/utils/seatCountFormatter";
import { getDateBefore } from "@/utils/dateCalculator";

import HeadlessModal from "@/components/utilities/AppModals/HeadlessModal.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SuccessModal from "@/components/utilities/AppModals/SuccessModal.vue";
import FailedModal from "@/components/utilities/AppModals/FailedModal.vue";
import FormInput from "@/components/utilities/AppFormFields/FormInput.vue";
import DowngradePlanModal from "@/components/Manage/DowngradePlan/DowngradePlanModal.vue";

@Component({
  components: {
    HeadlessModal,
    PrimaryButton,
    SuccessModal,
    FailedModal,
    FormInput,
    DowngradePlanModal,
  },
})
export default class UpdateSeatModal extends Vue {
  private planAndBillingService: PlanAndBillingService =
    new PlanAndBillingService();
  icons = icons;
  plansStore = usePlansStore();
  activePlanStore = useActivePlanStore();

  @Model({
    type: Boolean,
    default: false,
  })
  open!: boolean;

  @Prop({
    type: Object,
    default: false,
  })
  activePlan!: ActivePlan;

  @Prop({
    type: Number,
    default: NaN,
  })
  seatCount!: number;

  seatCountModel = 0;
  billingSchedule: "monthly" | "yearly" = "monthly";

  successModalOpen = false;
  failedModalOpen = false;
  downgradePlanModalOpen = false;

  totalSeat = 1;
  occupiedSeat = 1;
  currentPlan!: Price;
  newPlan!: Price;
  isUpgrade!: boolean;

  confirming = false;

  seatCountModelInvalid = false;
  currentPage = 0;

  @Watch("open")
  openWatcher(value: boolean) {
    if (value) {
      if (this.plansStore.pricingTable) {
        this.handleGetCurrentPlan();
        this.handleGetNewPlan();
      }

      this.seatCountModel = this.seatCount;
    } else {
      this.currentPage = 0;
    }
  }

  @Watch("seatCountModel")
  seatCountWatcher(newValue: number, oldValue: number) {
    if (isNaN(oldValue)) {
      this.seatCountModelInvalid = false;
    } else {
      this.handleValidateSeatCount(newValue);
    }

    this.handleGetNewPlan();
    this.isUpgrade = newValue > this.activePlan.subscription.consumers.length;
  }

  handleClickConfirm() {
    if (this.isUpgrade || this.currentPlan.slug === this.newPlan.slug) {
      this.handleConfirmPurchase();
    } else {
      this.downgradePlanModalOpen = true;
    }
  }

  async handleConfirmPurchase() {
    try {
      this.confirming = true;

      const seatCount =
        typeof this.seatCountModel === "string"
          ? parseInt(this.seatCountModel)
          : this.seatCountModel;

      let response: AxiosResponse;

      if (this.isUpgrade) {
        response = await this.planAndBillingService.upgradeSubscription(
          seatCount,
          this.activePlan.subscription.uuid
        );
      } else {
        response = await this.planAndBillingService.downgradeSubscription(
          seatCount,
          this.activePlan.subscription.uuid
        );
      }

      if (response.data.ok) {
        await this.activePlanStore.fetchActivePlan(true);
        this.successModalOpen = true;
        this.downgradePlanModalOpen = false;
      } else {
        throw new Error();
      }
    } catch (error) {
      this.failedModalOpen = true;
      console.error(error);
    } finally {
      this.confirming = false;
      this.handleModalClose();
    }
  }

  handleRedirectToMembersPage() {
    this.$router.push({
      name: "Members",
    });
  }

  handleValidateSeatCount(seatCount: number) {
    if (
      !seatCount ||
      seatCount < this.occupiedSeat ||
      seatCount == this.totalSeat
    ) {
      this.seatCountModelInvalid = true;
      return;
    }

    this.seatCountModelInvalid = false;
  }

  handleGetNewPlan() {
    if (this.activePlan.subscription.billingSchedule !== "one-time")
      this.billingSchedule = this.activePlan.subscription.billingSchedule;

    if (this.plansStore.pricingTable) {
      const newPlan = this.plansStore.pricingTable[this.billingSchedule].find(
        (item: Price) => this.seatCountModel <= item.maxSeat
      );
      if (newPlan) {
        this.newPlan = newPlan;
      }
    }
  }

  handleGetCurrentPlan() {
    const subscription = this.activePlan.subscription;
    this.getCurrentSeatCount();
    const currentBillingSchedule = subscription.billingSchedule;
    const currentPlan = {
      slug: subscription.plan.slug,
      name: subscription.plan.name,
      price: 0,
      maxSeat: 0,
    };

    const pricingTable = this.plansStore.pricingTable;

    if (currentBillingSchedule !== "one-time" && pricingTable) {
      const currentPlanPricing = pricingTable[currentBillingSchedule].find(
        (item: Price) => item.slug === subscription.plan.slug
      );

      if (currentPlanPricing) {
        currentPlan.price = currentPlanPricing.price;
        currentPlan.maxSeat = currentPlanPricing.maxSeat;
        this.currentPlan = currentPlan;
        return;
      }
    }
    this.currentPlan = currentPlan;
  }

  getCurrentSeatCount() {
    const seatCount = getSeatCount(this.activePlan.subscription.consumers);
    this.totalSeat = seatCount.total;
    this.occupiedSeat = seatCount.occupied;
  }

  getNextBillingDate() {
    const billingSchedule = this.activePlan.subscription.billingSchedule;
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    let nextBillingDate = "";

    if (this.activePlan.nextBillingDate) {
      nextBillingDate = this.activePlan.nextBillingDate;
    } else {
      if (billingSchedule === "monthly") {
        nextDate.setMonth(currentDate.getMonth() + 1);
      } else if (billingSchedule === "yearly") {
        nextDate.setFullYear(currentDate.getFullYear() + 1);
      }
      nextBillingDate = getDateBefore(nextDate.toISOString(), 0);
    }

    return formatDate(nextBillingDate, "MMMM d, yyyy");
  }

  formatPrice(price: number) {
    return formatPrice(price);
  }

  formatWord(num: number, word: string) {
    return formatWord(num, word);
  }

  formatDate(dateString: string, format: string) {
    return formatDate(dateString, format);
  }

  handleModalClose() {
    this.$emit("onClose", false);
  }
}
</script>

<style scoped lang="scss"></style>
