<template>
  <div>
    <div class="mb-1 flex justify-between">
      <p class="text-flohh-text-caption">{{ labelLeft }}</p>
      <p v-if="labelOnly" class="text-flohh-text-caption">
        {{ labelRight }}
      </p>
      <p v-else class="text-flohh-text-caption">
        {{ numerator }}/{{ denominator }} {{ labelRight }}
      </p>
    </div>
    <div class="bar">
      <div
        class="bar-progress bg-flohh-primary-pink"
        :style="`width: ${divWidth}%;`"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import AppTypographyText from "@/components/utilities/AppTypographies/AppTypographyText.vue";

@Component({ components: { AppTypographyText } })
export default class ProgressBarUtility extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  labelLeft!: string;

  @Prop({
    type: Number,
    default: 0,
  })
  numerator!: number;

  @Prop({
    type: Number,
    default: 0,
  })
  denominator!: number;

  @Prop({
    type: String,
    default: "",
  })
  labelRight!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  labelOnly!: boolean;

  divWidth = 0;

  @Watch("denominator")
  denominatorWatcher() {
    this.handleComputeProgress();
  }

  mounted() {
    this.handleComputeProgress();
  }

  handleComputeProgress() {
    const quotient = this.numerator / this.denominator;
    this.divWidth = quotient ? quotient * 100 : 0;
  }
}
</script>

<style scoped lang="scss">
.bar {
  height: 10px;
  width: 100%;
  background-color: #e9e9e9;
  border-radius: 50px;

  &-progress {
    height: 10px;
    border-radius: 50px;
  }
}
</style>
