import { IAccountsNav } from "@/const/type";
import { getOwnershipType } from "@/utils/ownershipTypeGetter";

export const getNavigationList = (): IAccountsNav[] => {
  return [
    {
      name: "MyAccount",
      label: "My Account",
      path: "/",
      hidden: false,
    },
    {
      name: "PlanAndBilling",
      label: "Plan & Billing",
      path: "/plan-and-billing",
      hidden: getOwnershipType() === "consumer",
    },
    {
      name: "Members",
      label: "Members",
      path: "/members",
      hidden: false,
    },
  ];
};
