<template>
  <div
    v-if="success"
    class="max-w-[350px] flex flex-col gap-10 w-full items-center"
  >
    <p
      class="font-flohh-font-bold text-flohh-text-title text-[#404040] text-center"
    >
      Check Your Email
    </p>
    <p class="text-flohh-text-subtitle text-[#404040] text-center">
      Please check you email inbox and click on the provided link to reset your
      password. If you didn't receive an email,
      <button
        type="button"
        class="underline text-flohh-primary-pink"
        @click="handleRequestResetPassword"
        :disabled="sending"
      >
        click here to resend
      </button>
    </p>
    <SecondaryButton
      color="flohh-neutral-20"
      @click="handleGoBack"
      class="border-flohh-neutral-20"
    >
      <span v-html="icons.backArrowIcon" />Go Back To Login</SecondaryButton
    >
  </div>
  <div v-else class="max-w-[350px] flex flex-col gap-10 w-full">
    <h6 class="font-flohh-font-bold text-flohh-h6 text-[#404040] text-center">
      Forgot Password
    </h6>
    <form
      novalidate
      @submit.prevent="handleRequestResetPassword"
      class="flex flex-col w-full gap-6"
    >
      <FormInput
        v-model="email"
        type="email"
        label="Email"
        placeholder="Enter Email"
        invalidMessage="Email address is required"
        :invalid="emailInvalid"
        required
        class="!py-[10px] !px-6"
      />
      <div class="flex justify-end gap-2 px-6">
        <SecondaryButton
          color="flohh-neutral-20"
          @click="handleGoBack"
          class="border-flohh-neutral-20"
        >
          <span v-html="icons.backArrowIcon" />Go Back</SecondaryButton
        >
        <PrimaryButton
          color="flohh-secondary-green"
          type="submit"
          :loading="sending"
          :disabled="sending"
        >
          <span v-if="!sending" v-html="icons.checkIcon" />Submit</PrimaryButton
        >
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { icons } from "@/const/icons";
import AuthenticationService from "@/services/AuthenticationService";
import { AxiosResponse } from "axios";

import FormInput from "@/components/utilities/AppFormFields/FormInput.vue";
import SecondaryButton from "@/components/utilities/AppButtons/SecondaryButton.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";

@Component({
  components: {
    FormInput,
    SecondaryButton,
    PrimaryButton,
  },
})
export default class ForgotPasswordComponent extends Vue {
  private authenticationService: AuthenticationService =
    new AuthenticationService();
  icons = icons;

  email = "";
  emailInvalid = false;

  sending = false;
  success = false;

  handleGoBack() {
    this.$router.push({ name: "Login", query: { type: "email" } });
  }

  validateForm() {
    this.emailInvalid = this.email === "";

    return !this.emailInvalid;
  }

  async handleRequestResetPassword() {
    try {
      const isValid = this.validateForm();

      if (!isValid) {
        return;
      }
      this.sending = true;

      const response: AxiosResponse =
        await this.authenticationService.requestResetPassword(this.email);

      if (response && response.data.ok) {
        this.success = true;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.sending = false;
    }
  }
}
</script>

<style scoped lang="scss"></style>
