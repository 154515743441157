<template>
  <AuthenticationComponent title="Enter your new password to reset">
    <ResetPasswordComponent />
  </AuthenticationComponent>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

import AuthenticationComponent from "@/components/Authentication/AuthenticationComponent.vue";
import ResetPasswordComponent from "@/components/Authentication/PasswordReset/ResetPasswordComponent.vue";

@Component({
  components: { AuthenticationComponent, ResetPasswordComponent },
})
export default class ResetPasswordView extends Vue {
  //
}
</script>

<style scoped lang="scss"></style>
