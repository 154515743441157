import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col gap-[40px]"
}
const _hoisted_2 = {
  key: 1,
  class: "grid py-10 place-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MembersCountComponent = _resolveComponent("MembersCountComponent")!
  const _component_MembersListComponent = _resolveComponent("MembersListComponent")!
  const _component_LeaveTeamComponent = _resolveComponent("LeaveTeamComponent")!
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!

  return (_ctx.activePlan && _ctx.accountInfo)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MembersCountComponent, { activePlan: _ctx.activePlan }, null, 8, ["activePlan"]),
        _createVNode(_component_MembersListComponent, {
          activePlan: _ctx.activePlan,
          accountInfo: _ctx.accountInfo
        }, null, 8, ["activePlan", "accountInfo"]),
        _createVNode(_component_LeaveTeamComponent, { activePlan: _ctx.activePlan }, null, 8, ["activePlan"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_ProgressLoader)
      ]))
}