<template>
  <div v-if="requiresAuth">
    <AppLayout>
      <router-view />
    </AppLayout>
  </div>
  <div v-else>
    <router-view />
  </div>
  <FailedModal
    v-model="failedmodalOpen"
    title="Unable to Proceed"
    :subtitle="errorMessage"
    @onClose="failedmodalOpen = false"
  />
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import AppLayout from "./components/Layout/AppLayout.vue";
import { RouteRecordRaw } from "vue-router";
import { routes } from "@/router";
import axios, { AxiosError } from "axios";
import { ErrorResponseData } from "@/components/Authentication/type";
import { useAuthStore } from "@/stores/authStore";

import FailedModal from "@/components/utilities/AppModals/FailedModal.vue";

@Component({
  components: { AppLayout, FailedModal },
})
export default class App extends Vue {
  authStore = useAuthStore();
  requiresAuth = false;
  routes: RouteRecordRaw[] = routes;

  failedmodalOpen = false;
  errorMessage = "";

  @Watch("$route")
  routeWatcher(value: RouteRecordRaw) {
    if (value.meta) {
      this.requiresAuth = value.meta.requiresAuth as boolean;
    }
  }

  @Watch("requiresAuth")
  requiresAuthWatcher(value: RouteRecordRaw) {
    if (value.meta) {
      this.requiresAuth = value.meta.requiresAuth as boolean;
    }
  }

  created() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status !== 401) {
          console.error(error);

          const errorData = error as AxiosError;
          const response = errorData.response?.data as ErrorResponseData;

          if (response && typeof response !== "string") {
            const errorDetails = response.details;
            const responseMessage = response.message;

            this.errorMessage = responseMessage;
            if (
              errorDetails &&
              errorDetails.info &&
              typeof errorDetails.info === "string"
            ) {
              this.errorMessage = `${responseMessage}<br/>${errorDetails.info}`;
            }
          } else {
            this.errorMessage = error.message;
          }
          this.failedmodalOpen = true;
        } else {
          localStorage.clear();
        }

        return Promise.reject(error);
      }
    );

    axios.interceptors.request.use(
      async (config) => {
        let accessToken = "";
        if (process.env.VUE_APP_TESTING_MODE === true) {
          const localStorageAccessToken = localStorage.getItem("accessToken");
          accessToken = localStorageAccessToken || "";
        } else {
          accessToken = this.authStore.accessToken;
        }
        if (accessToken)
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}
</script>

<style lang="scss"></style>
