import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex gap-[43px]"
}
const _hoisted_2 = {
  key: 1,
  class: "max-w-[600px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicInformationFormComponent = _resolveComponent("BasicInformationFormComponent")!
  const _component_PasswordFormComponent = _resolveComponent("PasswordFormComponent")!

  return (!_ctx.hidePasswordForm)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_BasicInformationFormComponent),
        _createVNode(_component_PasswordFormComponent)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_BasicInformationFormComponent)
      ]))
}