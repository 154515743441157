<template>
  <FormDropdown
    v-model="value"
    :label="label"
    :options="filteredStates"
    optionLabel="name"
    optionValue="name"
    :filter="true"
    :itemSize="46"
    placeholder="Select a State"
    :invalid="invalid"
    invalidMessage="State/province is required"
    :required="required"
  />
</template>

<script lang="ts">
import { ICountry, IState } from "@/const/type";
import { Vue, Component, Model, Prop, Watch } from "vue-facing-decorator";
import { countriesStore } from "@/stores/countries";

import Dropdown from "primevue/dropdown";
import FormDropdown from "./AppFormFields/FormDropdown.vue";

@Component({ components: { Dropdown, FormDropdown } })
export default class SelectState extends Vue {
  useCountriesStore = countriesStore();

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  country!: string;

  @Model({
    type: String,
    required: true,
  })
  value!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  required!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;

  countries: ICountry[] = [];
  filteredStates: IState[] | undefined = [];

  formatCountryData(selectedCountry?: string | undefined | null) {
    const countryValue = selectedCountry ? selectedCountry : this.country;
    if (countryValue) {
      this.filteredStates = this.countries.find(
        (country) => country.iso2 === countryValue
      )?.states;

      return;
    }

    this.filteredStates = [];
  }

  @Watch("country")
  countryWatcher(value: string) {
    this.formatCountryData(value);
  }

  @Watch("useCountriesStore.countries")
  countryStoreWatcher() {
    this.getCountries();
  }

  mounted() {
    this.getCountries();
  }

  getCountries() {
    const { countries } = this.useCountriesStore;
    this.countries = countries;
    this.formatCountryData();
  }
}
</script>
