<template>
  <div class="max-w-[350px] flex flex-col gap-10 w-full">
    <h6 class="font-flohh-font-bold text-flohh-h6 text-[#404040] text-center">
      Reset Password
    </h6>
    <form
      @submit.prevent="handleResetPassword"
      novalidate
      class="flex flex-col gap-6"
    >
      <div>
        <FormPasswordInput
          v-model="newPassword"
          label="New Password"
          placeholder="Enter new password here"
          :invalidMessage="newPasswordInvalidMessage"
          :invalid="newPasswordInvalid"
          required
          class="!py-[10px] !px-6"
        />
        <FormPasswordInput
          v-model="confirmNewPassword"
          label="Confirm New Password"
          placeholder="Enter new password here"
          :invalidMessage="confirmNewPasswordInvalidMessage"
          :invalid="confirmNewPasswordInvalid"
          required
          class="!py-[10px] !px-6"
        />
      </div>
      <div class="flex justify-end gap-2 px-6">
        <SecondaryButton
          color="flohh-neutral-20"
          @click="handleGoBack"
          class="border-flohh-neutral-20"
        >
          <span v-html="icons.backArrowIcon" />Go Back To Login</SecondaryButton
        >
        <PrimaryButton
          color="flohh-secondary-green"
          type="submit"
          :loading="submitting"
          :disabled="submitting"
        >
          <span
            v-if="!submitting"
            v-html="icons.checkIcon"
          />Submit</PrimaryButton
        >
      </div>
    </form>
  </div>
  <SuccessModal
    v-model="successModalOpen"
    title="Password Reset Success"
    subtitle="Your password has been reset successfully"
    @onClose="successModalOpen = false"
  />
</template>
<script lang="ts">
import { Vue, Component, Watch } from "vue-facing-decorator";
import { AxiosResponse } from "axios";
import AuthenticationService from "@/services/AuthenticationService";
import { validatePassword } from "@/utils/passwordValidator";
import { icons } from "@/const/icons";

import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SecondaryButton from "@/components/utilities/AppButtons/SecondaryButton.vue";
import FormPasswordInput from "@/components/utilities/AppFormFields/FormPasswordInput.vue";
import SuccessModal from "@/components/utilities/AppModals/SuccessModal.vue";

@Component({
  components: {
    PrimaryButton,
    SecondaryButton,
    FormPasswordInput,
    SuccessModal,
  },
})
export default class ResetPasswordComponent extends Vue {
  private authenticationService = new AuthenticationService();
  icons = icons;

  newPassword = "";
  newPasswordInvalidMessage = "New password is required";
  newPasswordInvalid = false;

  confirmNewPassword = "";
  confirmNewPasswordInvalidMessage = "Please confirm your new password";
  confirmNewPasswordInvalid = false;

  submitting = false;
  token = "";
  successModalOpen = false;

  @Watch("newPassword")
  newPasswordWatcher(value: string) {
    const error = validatePassword(value);
    if (error) {
      this.newPasswordInvalid = true;
      this.newPasswordInvalidMessage = error;
    } else {
      this.checkifEqual();
    }
  }

  @Watch("confirmNewPassword")
  confirmNewPasswordWatcher(value: string) {
    const error = validatePassword(value);
    if (error) {
      this.confirmNewPasswordInvalid = true;
      this.confirmNewPasswordInvalidMessage = error;
    } else {
      this.checkifEqual();
    }
  }

  @Watch("successModalOpen")
  successModalOpenWatcher(value: boolean) {
    if (!value) this.handleGoBack();
  }

  mounted() {
    this.token = this.$route.query.token as string;
  }

  async handleResetPassword() {
    const valid = this.validateForm();
    if (!valid) {
      return;
    }

    this.submitting = true;

    try {
      const response: AxiosResponse =
        await this.authenticationService.resetPassword(
          this.token,
          this.confirmNewPassword
        );

      if (response.data.ok) {
        this.successModalOpen = true;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.submitting = false;
    }
  }

  validateForm() {
    this.newPasswordInvalid = this.newPasswordInvalidMessage !== "";
    this.confirmNewPasswordInvalid =
      this.confirmNewPasswordInvalidMessage !== "";

    return !this.newPasswordInvalid && !this.confirmNewPasswordInvalid;
  }

  checkifEqual() {
    if (this.newPassword === this.confirmNewPassword) {
      this.confirmNewPasswordInvalidMessage = "";
      this.confirmNewPasswordInvalid = false;
      this.newPasswordInvalidMessage = "";
      this.newPasswordInvalid = false;
    } else {
      this.confirmNewPasswordInvalidMessage = "Passwords do not match";
      this.confirmNewPasswordInvalid = true;
      this.newPasswordInvalidMessage = "Passwords do not match";
      this.newPasswordInvalid = true;
    }
  }

  handleGoBack() {
    this.$router.push({ name: "Login", query: { type: "email" } });
  }
}
</script>
