<template>
  <div
    class="border-[3px] border-solid border-flohh-neutral-85 rounded-lg px-6"
  >
    <div class="flex items-center justify-between py-4">
      <div>
        <p class="text-flohgh-text-title font-flohh-font-bold">Plan</p>
        <p class="text-flohh-text-body text-flohh-neutral-35">
          {{ activePlan.subscription.plan.name }}
        </p>
      </div>
      <div class="flex gap-[3.75rem] justify-end items-center">
        <p
          v-if="activePlan.subscription.plan.slug === 'free'"
          class="text-flohh-text-body text-flohh-neutral-35"
        >
          {{ getCurrentPlanPrice() }}
        </p>
        <p v-else class="text-flohh-text-body text-flohh-neutral-35">
          {{ getCurrentPlanPrice() }}
          {{ activePlan.subscription.billingSchedule }}. Renews
          {{ getNextBillingDate() }}
        </p>
        <SecondaryButton
          label="Manage Plan"
          color="flohh-primary-pink"
          class="min-w-[145px] border-flohh-primary-pink"
          @click="handleManagePlan"
        />
      </div>
    </div>
    <div
      v-if="activePlan.subscription.plan.slug !== 'free'"
      class="w-full border-b border-solid border-flohh-neutral-70"
    ></div>
    <div
      v-if="activePlan.subscription.plan.slug !== 'free'"
      class="flex items-center justify-between py-4"
    >
      <div>
        <p class="text-flohgh-text-title font-flohh-font-bold">Billing cycle</p>
        <p class="capitalize text-flohh-text-body text-flohh-neutral-35">
          {{ activePlan.subscription.billingSchedule }}
        </p>
      </div>
      <div class="flex gap-[3.75rem] justify-end items-center">
        <p class="text-flohh-text-body text-flohh-neutral-35">Credit card</p>
        <SecondaryButton
          label="Edit Cycle"
          color="flohh-primary-pink"
          class="min-w-[145px] border-flohh-primary-pink"
          @click="handleManagePlan"
        />
      </div>
    </div>
    <div
      v-if="activePlan.subscription.plan.slug !== 'free'"
      class="w-full border-b border-solid border-flohh-neutral-70"
    ></div>
    <div
      v-if="activePlan.subscription.plan.slug !== 'free'"
      class="flex items-center justify-between py-4"
    >
      <p class="text-flohgh-text-title font-flohh-font-bold">Paid Seats</p>
      <div class="flex gap-[3.75rem] justify-end items-center">
        <p class="text-flohh-text-body text-flohh-neutral-35">
          {{ totalSeat }} {{ formatWord(totalSeat, "seat") }}
        </p>
        <SecondaryButton
          label="Manage Seats"
          color="flohh-primary-pink"
          class="min-w-[145px] border-flohh-primary-pink"
          @click="handleManagePlan"
        />
      </div>
    </div>
    <div class="w-full border-b border-solid border-flohh-neutral-70"></div>
    <div class="flex items-center justify-between py-4">
      <p class="text-flohgh-text-title font-flohh-font-bold">Members</p>
      <div class="flex gap-[3.75rem] justify-end items-center">
        <p class="text-flohh-text-body text-flohh-neutral-35">
          {{ occupiedSeat }} {{ formatWord(occupiedSeat, "member") }}
        </p>
        <SecondaryButton
          label="Manage Members"
          color="flohh-primary-pink"
          class="min-w-[145px] border-flohh-primary-pink"
          @click="handleManageMembers"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import { ActivePlan, Consumer, Plan } from "@/components/PlanAndBilling/type";
import { getSeatCount } from "@/utils/seatCountFormatter";
import { findAndFormatPrice } from "@/utils/priceFormatter";
import { formatWord } from "@/utils/pluralFormatter";
import { formatDate } from "@/utils/dateFormatter";
import { formatPrice } from "@/utils/priceFormatter";

import SecondaryButton from "@/components/utilities/AppButtons/SecondaryButton.vue";

@Component({
  components: {
    SecondaryButton,
  },
})
export default class PlanInfoComponent extends Vue {
  @Prop({
    required: true,
  })
  activePlan!: ActivePlan;

  @Prop({
    required: true,
  })
  plans!: Plan[];

  occupiedSeat = 1;
  totalSeat = 1;

  @Watch("activePlan")
  activePlanWatcher(activePlan: ActivePlan) {
    this.handleGetSeatCount(activePlan.subscription.consumers);
  }

  mounted() {
    this.handleGetSeatCount(this.activePlan.subscription.consumers);
  }

  handleGetSeatCount(consumers: Consumer[]) {
    const seatCount = getSeatCount(consumers);
    this.occupiedSeat = seatCount.occupied;
    this.totalSeat = seatCount.total;
  }

  getCurrentPlanPrice() {
    if (this.activePlan) {
      return findAndFormatPrice(
        this.activePlan,
        this.activePlan.subscription.billingSchedule,
        true
      );
    }
  }

  getNextBillingDate() {
    const billingSchedule = this.activePlan.subscription.billingSchedule;
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    let nextBillingDate = "";

    if (this.activePlan.nextBillingDate) {
      nextBillingDate = this.activePlan.nextBillingDate;
    } else {
      if (billingSchedule === "monthly") {
        nextDate.setMonth(currentDate.getMonth() + 1);
      } else if (billingSchedule === "yearly") {
        nextDate.setFullYear(currentDate.getFullYear() + 1);
      }
      nextBillingDate = nextDate.toISOString();
    }

    return formatDate(nextBillingDate, "MMM dd, yyyy");
  }

  formatWord(num: number, word: string) {
    return formatWord(num, word);
  }

  formatDate(dateString: string, format: string) {
    return formatDate(dateString, format);
  }

  formatPrice(price: number) {
    return formatPrice(price);
  }

  handleManageMembers() {
    this.$router.push({ name: "Members" });
  }

  handleManagePlan() {
    this.$router.push({ name: "Manage" });
  }
}
</script>

<style scoped lang="scss"></style>
