import axios from "axios";

const BASE_URL = "/accounts/subscription";

export default class MemberService {
  inviteTeachers(payload: string[]) {
    return axios.post(`${BASE_URL}/consumer/invite`, { teachers: payload });
  }

  removeMember(email: string) {
    return axios.put(`${BASE_URL}/consumer/remove/`, { email });
  }

  leaveAsConsumer() {
    return axios.post(`${BASE_URL}/consumer/leave`);
  }

  resendInvite(email: string) {
    return axios.post(`${BASE_URL}/consumer/resend-invite`, { email });
  }
}
