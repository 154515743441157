import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuccessModal = _resolveComponent("SuccessModal")!
  const _component_FailedModal = _resolveComponent("FailedModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SuccessModal, {
      modelValue: _ctx.successModalOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.successModalOpen) = $event)),
      title: "Upgrade Successful",
      subtitle: _ctx.successPaymentMessage,
      onOnClose: _ctx.handleClosePaymentMessage
    }, null, 8, ["modelValue", "subtitle", "onOnClose"]),
    _createVNode(_component_FailedModal, {
      modelValue: _ctx.failedModalOpen,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.failedModalOpen) = $event)),
      title: "Payment Failed",
      subtitle: "We are sorry, there was an error processing your payment. Please try again.",
      onOnClose: _ctx.handleClosePaymentMessage
    }, null, 8, ["modelValue", "onOnClose"])
  ], 64))
}