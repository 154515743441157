<template>
  <PageViewContainer
    ><div
      class="p-[28px] bg-white rounded-lg border border-solid border-flohh-neutral-85"
    >
      <PageNavHead selected="PlanAndBilling" />
      <PlanAndBillingComponent /></div
  ></PageViewContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import PageViewContainer from "@/components/Layout/Partials/PageViewContainer.vue";
import PlanAndBillingComponent from "@/components/PlanAndBilling/PlanAndBillingComponent.vue";
import PageNavHead from "@/components/utilities/PageNavHead.vue";

@Component({
  components: { PageViewContainer, PlanAndBillingComponent, PageNavHead },
})
export default class PlanAndBilling extends Vue {}
</script>

<style scoped lang="scss"></style>
