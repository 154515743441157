import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center gap-2 px-6 py-4 mb-10 rounded-lg bg-flohh-secondary-blue"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "text-flohh-text-caption" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.subscription)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", {
          innerHTML: _ctx.icons.infoCircleIcon
        }, null, 8, _hoisted_2),
        _createElementVNode("p", _hoisted_3, [
          _createTextVNode(" You still have pending payment that needs to be completed. "),
          _createElementVNode("a", {
            href: _ctx.subscription.paymentUrl,
            class: "underline cursor-pointer",
            target: "_blank"
          }, "Click Here To Checkout", 8, _hoisted_4),
          _createTextVNode(". ")
        ])
      ]))
    : _createCommentVNode("", true)
}