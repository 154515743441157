import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppLayout = _resolveComponent("AppLayout")!
  const _component_FailedModal = _resolveComponent("FailedModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.requiresAuth)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AppLayout, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_router_view)
        ])),
    _createVNode(_component_FailedModal, {
      modelValue: _ctx.failedmodalOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.failedmodalOpen) = $event)),
      title: "Unable to Proceed",
      subtitle: _ctx.errorMessage,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.failedmodalOpen = false))
    }, null, 8, ["modelValue", "subtitle"])
  ], 64))
}