import axios from "axios";
import { AccountBasicInformation } from "@/components/Account/type";

const BASE_URL = "/teachers";

export default class AccountService {
  fetchAccountInfo() {
    return axios.get(`${BASE_URL}/me`);
  }

  updateAccountInfo(payload: AccountBasicInformation) {
    return axios.put(`${BASE_URL}`, payload);
  }

  fetchCountries() {
    return axios.get("/lookup/countries-and-states");
  }
}
