/*
    USAGE:
    
    const num = 4;
    const word = seat; // singular form

    formatWord(num)

    Return Type: String
    Return Value: "seats"
*/
export const formatWord = (num: number, word: string) => {
  if (num > 1) {
    return `${word}s`;
  } else {
    return word;
  }
};
