<template>
  <AuthenticationComponent
    title="Enter the email address of the account you want to reset the password"
  >
    <ForgotPasswordComponent />
  </AuthenticationComponent>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

import AuthenticationComponent from "@/components/Authentication/AuthenticationComponent.vue";
import ForgotPasswordComponent from "@/components/Authentication/PasswordReset/ForgotPasswordComponent.vue";

@Component({
  components: { AuthenticationComponent, ForgotPasswordComponent },
})
export default class ForgotPasswordView extends Vue {
  //
}
</script>

<style scoped lang="scss"></style>
