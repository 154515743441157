import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeStyle as _normalizeStyle, Teleport as _Teleport } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "py-3 px-6 flex gap-2 cursor-pointer hover:bg-flohh-primary-light-pink" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "text-flohh-text-body font-flohh-font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createBlock(_component_Menu, {
    as: "div",
    class: "relative inline-block text-left"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MenuButton, {
        id: "dropdownButton",
        onClick: _ctx.toggleDropdown
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            innerHTML: _ctx.icons[_ctx.icon]
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      (_openBlock(), _createBlock(_Teleport, { to: "#custom-overlay-menu" }, [
        _createVNode(_component_MenuItems, {
          style: _normalizeStyle({ top: `${_ctx.position.top}px`, left: `${_ctx.position.left}px` }),
          class: "overflow-hidden absolute z-50 origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-flohh-neutral-85 ring-opacity-5 focus:outline-none"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
              return (_openBlock(), _createBlock(_component_MenuItem, {
                key: index,
                onClick: () => _ctx.handleMenuSelect(item.id)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.icons[item.icon]
                    }, null, 8, _hoisted_3),
                    _createElementVNode("p", _hoisted_4, _toDisplayString(item.label), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["style"])
      ]))
    ]),
    _: 1
  }))
}