<template>
  <div class="flex py-6 rounded-lg bg-flohh-secondary-pale-pink">
    <div class="flex-1 px-6 border-r border-solid border-flohh-neutral-70">
      <div>
        <h5 class="text-flohh-h5 font-flohh-font-bold">{{ totalSeat }}</h5>
        <p class="text-flohh-text-caption">
          Paid {{ formatWord(totalSeat, "Seat") }}
        </p>
      </div>
      <div></div>
    </div>
    <div class="flex-1 px-6">
      <div>
        <h5 class="text-flohh-h5 font-flohh-font-bold">{{ occupiedSeat }}</h5>
        <p class="text-flohh-text-caption">
          {{ formatWord(occupiedSeat, "Current Member") }}
        </p>
      </div>
      <div></div>
    </div>
    <div
      v-if="
        activePlan.subscription.plan.slug !== 'free' &&
        activePlan.nextBillingDate
      "
      class="flex-1 px-6 border-l border-solid border-flohh-neutral-70"
    >
      <div>
        <h5 class="text-flohh-h5 font-flohh-font-bold">
          {{ formatDate(activePlan.nextBillingDate, "d MMM yyyy") }}
        </h5>
        <p class="text-flohh-text-caption">Next Billing Period</p>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import { getSeatCount } from "@/utils/seatCountFormatter";
import { ActivePlan } from "@/components/PlanAndBilling/type";
import { formatWord } from "@/utils/pluralFormatter";
import { formatDate } from "@/utils/dateFormatter";

@Component({
  components: {},
})
export default class MembersCountComponent extends Vue {
  @Prop({
    required: true,
  })
  activePlan!: ActivePlan;

  occupiedSeat = 0;
  totalSeat = 0;

  @Watch("activePlan")
  activePlanWatcher() {
    this.setSeatCount();
  }

  mounted() {
    this.setSeatCount();
  }

  setSeatCount() {
    if (this.activePlan) {
      const seatCount = getSeatCount(this.activePlan.subscription.consumers);
      this.occupiedSeat = seatCount.occupied;
      this.totalSeat =
        this.activePlan.subscription.plan.slug === "free" ? 0 : seatCount.total;
    }
  }

  formatWord(num: number, word: string) {
    return formatWord(num, word);
  }

  formatDate(dateString: string, format: string) {
    return formatDate(dateString, format);
  }
}
</script>

<style scoped lang="scss"></style>
