<template>
  <div class="flex flex-col w-full gap-2">
    <Text v-if="label" color="text-flohh-neutral-35" size="body" weight="md">{{
      label
    }}</Text>
    <div class="relative w-full">
      <InputText
        v-model="value"
        :type="inputType"
        :placeholder="placeholder"
        :style="{
          outline: 'none',
          boxShadow: 'none',
        }"
        class="border-flohh-neutral-70 text-flohh-text-body rounded-lg border-solid border outline-none py-2 text-flohh-neutral-20 w-full pl-[10px] pr-[55px]"
        :class="[
          {
            'p-invalid': errorMessage && required,
          },
        ]"
        v-bind="$attrs"
      />
      <button
        v-if="inputType === 'password'"
        class="absolute right-[24px] top-[50%] translate-y-[-50%] w-4 cursor-pointer bg-white px-4"
        @click="handleToggleVisibility"
        type="button"
      >
        <span v-html="icons.eyeIcon"></span>
      </button>
      <button
        v-else
        class="absolute right-[24px] top-[50%] translate-y-[-50%] w-4 cursor-pointer bg-white px-4"
        @click="handleToggleVisibility"
        type="button"
      >
        <span v-html="icons.passwordEyeVisibleIcon"></span>
      </button>
    </div>
    <p
      v-if="errorMessage"
      class="ml-1 text-flohh-error-red text-flohh-text-caption"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Model, Watch } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import { icons } from "@/const/icons";

import Text from "@/components/utilities/AppTypographies/Text.vue";

@Component({ components: { Text, InputText } })
export default class FormPasswordInput extends Vue {
  icons = icons;
  @Model({
    type: String,
    default: "",
    required: true,
  })
  value!: string;

  @Prop({
    type: String,
    default: "",
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: "Password",
  })
  label!: string;

  @Prop({
    type: String,
    default: "",
  })
  invalidMessage!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  required!: boolean;

  errorMessage = "";

  inputType = "password";

  handleToggleVisibility() {
    this.inputType = this.inputType === "password" ? "text" : "password";
  }

  @Watch("invalid")
  invalidWatcher(value: boolean) {
    this.errorMessage = value ? this.invalidMessage : "";
  }

  @Watch("invalidMessage")
  invalidMessageWatcher(value: string) {
    this.errorMessage = value;
  }

  @Watch("value")
  valueWatcher(newValue: string, oldValue: string) {
    if (this.required) {
      if (!newValue && oldValue) {
        this.errorMessage = this.invalidMessage;
      }
    }
  }
}
</script>
