import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/visacard.svg'
import _imports_1 from '@/assets/mastercard.svg'


const _hoisted_1 = { class: "flex gap-4 items-center mb-[60px]" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex gap-4 items-center" }
const _hoisted_5 = {
  key: 0,
  src: _imports_0
}
const _hoisted_6 = {
  key: 1,
  src: _imports_1
}
const _hoisted_7 = { class: "text-flohh-text-caption font-flohh-font-medium text-flohh-neutral-15" }
const _hoisted_8 = { class: "text-flohh-text-caption font-flohh-font-medium text-flohh-neutral-15" }
const _hoisted_9 = { class: "flex items-center gap-4" }
const _hoisted_10 = {
  key: 0,
  class: "text-flohh-text-body"
}
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isRemoving)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "animate-spin",
          innerHTML: _ctx.icons.loadingIconBlack
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleRemoveCard && _ctx.handleRemoveCard(...args)))
        }, [
          _createElementVNode("span", {
            innerHTML: _ctx.icons.closeIconSmallBlack
          }, null, 8, _hoisted_3)
        ])),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.type === 'visa')
        ? (_openBlock(), _createElementBlock("img", _hoisted_5))
        : (_openBlock(), _createElementBlock("img", _hoisted_6)),
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.type === "visa" ? "Visa" : "Master Card"), 1),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.getMaskedCardNumber()), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.isDefault)
        ? (_openBlock(), _createElementBlock("p", _hoisted_10, "Default"))
        : _createCommentVNode("", true),
      (_ctx.isUpdating)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: "animate-spin",
            innerHTML: _ctx.icons.loadingIconBlack
          }, null, 8, _hoisted_11))
        : (!_ctx.isDefault && !_ctx.isUpdating)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "text-flohh-text-body text-flohh-primary-pink",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleSetAsDefault && _ctx.handleSetAsDefault(...args)))
            }, " Set as Default "))
          : _createCommentVNode("", true)
    ])
  ]))
}