import { Options } from "@/components/utilities/AppFormFields/FormRadioButton.vue";

export const reasons: Options[] = [
  {
    id: "1",
    label: "The free tier suits my needs",
    value: "The free tier suits my needs",
  },
  {
    id: "2",
    label: "I’m moving over to another grading tool",
    value: "I’m moving over to another grading tool",
  },
  {
    id: "3",
    label: "This is temporary, I plan to resume my subscription in the future",
    value: "This is temporary, I plan to resume my subscription in the futures",
  },
  {
    id: "4",
    label: "Didn’t have features or integrations I needed",
    value: "Didn’t have features or integrations I needed",
  },
  {
    id: "5",
    label: "The product is unreliable or has poor performance",
    value: "The product is unreliable or has poor performance",
  },
  {
    id: "6",
    label: "I didn’t really understand how to use Flohh",
    value: "I didn’t really understand how to use Flohh",
  },
  {
    id: "7",
    label: "I didn’t see the benefit of using Flohh",
    value: "I didn’t see the benefit of using Flohh",
  },
  {
    id: "8",
    label: "Another reason",
    value: "Another reason",
  },
];
