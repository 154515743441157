/*
    USAGE:
    
    const password = "$randomText!";

    validatePassword(password)

    Return Type: String
    Return Value: Error Message | Empty String (if valid)
*/

export const validatePassword = (password: string) => {
  const hasLowercase = /[a-z]/;
  const hasUppercase = /[A-Z]/;
  const hasNumber = /[0-9]/;
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
  const minLength = 8;

  const isLongEnough = password.length >= minLength;
  const containsLowercase = hasLowercase.test(password);
  const containsUppercase = hasUppercase.test(password);
  const containsNumber = hasNumber.test(password);
  const containsSpecialChar = hasSpecialChar.test(password);

  if (!isLongEnough) {
    return "Should be atleast 8 characters long";
  } else if (!containsLowercase) {
    return "Should contain a lowercase character";
  } else if (!containsUppercase) {
    return "Should contain an uppercase character";
  } else if (!containsNumber) {
    return "Should contain a number";
  } else if (!containsSpecialChar) {
    return "Should contain a special character";
  } else {
    return "";
  }
};
