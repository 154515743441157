import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-[28px] bg-white rounded-lg border border-solid border-flohh-neutral-85" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageNavHead = _resolveComponent("PageNavHead")!
  const _component_AccountComponent = _resolveComponent("AccountComponent")!
  const _component_PageViewContainer = _resolveComponent("PageViewContainer")!

  return (_openBlock(), _createBlock(_component_PageViewContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PageNavHead, { selected: "MyAccount" }),
        _createVNode(_component_AccountComponent)
      ])
    ]),
    _: 1
  }))
}