import { vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13e29e4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col gap-[8px] w-full" }
const _hoisted_2 = { class: "flex items-center gap-2" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "ml-1 text-flohh-error-red text-flohh-text-caption" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", _mergeProps({
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
      }, _ctx.$attrs, {
        class: "cursor-pointer checkbox",
        id: _ctx.id
      }), null, 16, _hoisted_3), [
        [_vModelCheckbox, _ctx.value]
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}