<template>
  <div v-if="activePlan && plans.length" class="flex flex-col gap-10">
    <PlanInfoComponent :activePlan="activePlan" :plans="plans" />
    <CreditCardDetailsComponent
      v-if="activePlan.subscription.plan.slug !== 'free'"
    />
    <BillingAddressComponent
      v-if="activePlan.subscription.plan.slug !== 'free'"
      :activePlan="activePlan"
    />
    <BillingHistoryComponent
      v-if="activePlan.subscription.plan.slug !== 'free'"
    />
    <hr />
    <UpgradePlanComponent :plans="plans" :activePlan="activePlan" />
    <hr />
    <CancelSubscriptionComponent :activePlan="activePlan" />
  </div>
  <div v-else class="grid py-10 place-items-center"><ProgressLoader /></div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import { usePlansStore } from "@/stores/plans";
import { useActivePlanStore } from "@/stores/activePlan";
import { ActivePlan, Plan } from "@/components/PlanAndBilling/type";

import PlanInfoComponent from "@/components/PlanAndBilling/PlanInfo/PlanInfoComponent.vue";
import CreditCardDetailsComponent from "@/components/PlanAndBilling/CreditCardDetails/CreditCardDetailsComponent.vue";
import BillingAddressComponent from "@/components/PlanAndBilling/BillingAddress/BillingAddressComponent.vue";
import BillingHistoryComponent from "@/components/PlanAndBilling/BillingHistory/BillingHistoryComponent.vue";
import UpgradePlanComponent from "@/components/PlanAndBilling//UpgradePlan/UpgradePlanComponent.vue";
import CancelSubscriptionComponent from "@/components/PlanAndBilling/CancelSubscription/CancelSubscriptionComponent.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

@Component({
  components: {
    PlanInfoComponent,
    CreditCardDetailsComponent,
    BillingAddressComponent,
    BillingHistoryComponent,
    UpgradePlanComponent,
    CancelSubscriptionComponent,
    ProgressLoader,
  },
})
export default class PlanAndBillingComponent extends Vue {
  plansStore = usePlansStore();
  activePlanStore = useActivePlanStore();
  plans: Plan[] = [];
  activePlan!: ActivePlan | null;

  @Watch("plansStore.plans")
  plansWatcher(plans: Plan[]) {
    this.plans = plans;
  }

  @Watch("activePlanStore.activePlan")
  activePlanWatcher(activePlan: ActivePlan) {
    if (activePlan?.ownershipType === "consumer") {
      this.$router.push({ name: "Members" });
    } else {
      this.activePlan = activePlan;
    }
  }

  created() {
    this.fetchPlans();
  }

  async fetchPlans() {
    this.plans = await this.plansStore.fetchPlans();
    const activePlan = await this.activePlanStore.fetchActivePlan();
    if (activePlan?.ownershipType === "consumer") {
      this.$router.push({ name: "Members" });
    } else {
      this.activePlan = activePlan;
    }
  }
}
</script>

<style scoped lang="scss"></style>
