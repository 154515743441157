<template>
  <div class="">
    <div class="flex items-center gap-6 mb-6">
      <IconizeInput
        v-model="searchText"
        icon="searchIcon"
        placeholder="Enter name"
        class="flex-[2] text-flohh-text-body"
      />
      <FormDropdown
        v-model="typeFilterValue"
        :options="typeFilter"
        optionLabel="label"
        optionValue="value"
        :itemSize="46"
        class="flex-1"
        @onUpdate="handleTypeFilter"
      />
      <FormDropdown
        v-model="statusFilterValue"
        :options="statusFilter"
        optionLabel="label"
        optionValue="value"
        :itemSize="46"
        class="flex-1"
        @onUpdate="handleStatusFilter"
      />
      <div class="flex items-center">
        <TertiaryButton
          label="Clear"
          class="underline"
          @click="handleClearFilters"
        />
        <PrimaryButton
          v-if="
            activePlan.subscription.plan.slug === 'teams' ||
            activePlan.subscription.plan.slug === 'organisation'
          "
          label="Invite Teacher"
          class="min-w-[150px]"
          @click="handleInviteTeacher"
        />
      </div>
    </div>
    <Table :tableData="tableData" tableKey="members-list">
      <Column
        field="name"
        header="Member"
        sortable
        :style="{ minWidth: '350px', maxWidth: '350px', width: '350px' }"
        ><template #body="cell">
          <div
            class="h-auto ellipsis"
            v-tooltip.top="cell.data.name || cell.data.email"
          >
            {{ cell.data.name || cell.data.email }}
            <span
              v-if="cell.data.email === accountInfo.email"
              class="px-2 py-[1px] ml-2 border border-solid rounded-lg border-flohh-secondary-dark-blue text-flohh-text-caption"
              >You</span
            >
          </div></template
        ></Column
      >
      <Column
        header="Role"
        :style="{ minWidth: '200px', maxWidth: '200px', width: '200px' }"
        ><template #body="cell">{{
          cell.data.email === accountInfo.email
            ? "Subscription Owner"
            : "Teacher"
        }}</template>
      </Column>
      <Column
        field="dateAdded"
        header="Date Added"
        sortable
        :style="{ minWidth: '150px', maxWidth: '150px', width: '150px' }"
        ><template #body="cell">{{
          formatDate(cell.data.dateAdded, "dd MMM yyyy")
        }}</template></Column
      >
      <Column
        header="Status"
        :style="{
          paddingTop: '10px',
          paddingBottom: '10px',
          minWidth: '150px',
          maxWidth: '150px',
          width: '150px',
        }"
        ><template #body="cell"
          ><StatusBadge :status="cell.data.status" /></template
      ></Column>
      <Column :style="{ minWidth: '50px', maxWidth: '50px', width: '50px' }"
        ><template #body="cell">
          <div class="center-content">
            <DropdownButton
              v-if="
                owner.name !== cell.data.name &&
                activePlan.ownershipType === 'owner'
              "
              icon="moreOptions"
              :items="
                cell.data.status === 'reserved'
                  ? dropdownItemsInvited
                  : dropdownItemsActive
              "
              :loading="actionProcessing"
              @onSelect="(id: string) => handleMenuSelect(id, cell.data.email, cell.data.name)"
            />
            <DropdownButton
              v-else-if="
                owner.name !== cell.data.name &&
                activePlan.ownershipType === 'consumer' &&
                cell.data.status === 'reserved'
              "
              icon="moreOptions"
              :items="dropdownItemsForMembers"
              :loading="actionProcessing"
              @onSelect="(id: string) => handleMenuSelect(id, cell.data.email, cell.data.name)"
            /></div
        ></template>
      </Column>
    </Table>
  </div>
  <InviteTeacherModal
    v-model="inviteTeacherModalOpen"
    :availableSeats="availableSeats"
    @onClose="handleModalClose"
  />
  <SuccessModal
    v-model="removeSuccessModalOpen"
    title="Teacher Removed"
    :subtitle="`You have successfully removed ${removeUser} from you team.`"
    @onClose="removeSuccessModalOpen = false"
  />
  <SuccessModal
    v-model="cancelSuccessModalOpen"
    title="Invite Cancelled"
    :subtitle="`You have successfully cancelled the invite to ${removeUser}.`"
    @onClose="cancelSuccessModalOpen = false"
  />
  <SuccessModal
    v-model="reInviteSuccessModalOpen"
    title="Invite Sent"
    :subtitle="`Your invitation has been sent to ${reInviteEmail}.`"
    @onClose="reInviteSuccessModalOpen = false"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import Column from "primevue/column";
import { icons } from "@/const/icons";
import {
  ActivePlan,
  Consumer,
  OwnerInfo,
} from "@/components/PlanAndBilling/type";
import { formatDate } from "@/utils/dateFormatter";
import { getSeatCount } from "@/utils/seatCountFormatter";
import MemberService from "@/services/MemberService";
import { AxiosResponse } from "axios";
import { AccountBasicInformation } from "@/components/Account/type";
import { useActivePlanStore } from "@/stores/activePlan";

import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import DropdownButton from "@/components/utilities/AppButtons/DropdownButton.vue";
import Table from "@/components/utilities/Table.vue";
import IconizeInput from "@/components/utilities/IconizeInput.vue";
import FormDropdown from "@/components/utilities/AppFormFields/FormDropdown.vue";
import StatusBadge from "@/components/utilities/StatusBadge.vue";
import TertiaryButton from "@/components/utilities/AppButtons/TertiaryButton.vue";
import InviteTeacherModal from "@/components/Members/InviteTeacherModal.vue";
import SuccessModal from "@/components/utilities/AppModals/SuccessModal.vue";

@Component({
  components: {
    PrimaryButton,
    StatusBadge,
    FormDropdown,
    IconizeInput,
    Table,
    Column,
    TertiaryButton,
    InviteTeacherModal,
    DropdownButton,
    SuccessModal,
  },
})
export default class MembersListComponent extends Vue {
  private memberService: MemberService = new MemberService();
  icons = icons;
  activePlanStore = useActivePlanStore();

  @Prop({
    required: true,
  })
  activePlan!: ActivePlan;

  @Prop({
    required: true,
  })
  accountInfo!: AccountBasicInformation;

  dropdownItemsInvited = [
    {
      id: "resend",
      icon: "personAdd",
      label: "Resend Invite",
    },
    {
      id: "cancel",
      icon: "personAddDisabled",
      label: "Cancel Invite",
    },
  ];

  dropdownItemsActive = [
    {
      id: "remove",
      icon: "closeIconSmallBlack",
      label: "Remove From Team",
    },
  ];

  dropdownItemsForMembers = [
    {
      id: "resend",
      icon: "personAdd",
      label: "Resend Invite",
    },
  ];

  tableData: Consumer[] = [];
  owner!: OwnerInfo;
  actionProcessing = false;

  inviteTeacherModalOpen = false;
  searchText = "";
  typeFilterValue = "all";
  statusFilterValue = "all";
  availableSeats = 0;
  removeUser = "";
  reInviteEmail = "";

  removeSuccessModalOpen = false;
  cancelSuccessModalOpen = false;
  reInviteSuccessModalOpen = false;

  typeFilter = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Owner",
      value: "owner",
    },
    {
      label: "Teacher",
      value: "teacher",
    },
  ];

  statusFilter = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Invited",
      value: "reserved",
    },
  ];

  @Watch("activePlan")
  activePlanWatcher(activePlan: ActivePlan) {
    this.setOwner(activePlan);
    this.setTableData(activePlan);
    this.setSeatCount(activePlan);
  }

  mounted() {
    this.setOwner(this.activePlan);
    this.setTableData(this.activePlan);
    this.setSeatCount(this.activePlan);
  }

  async handleRemoveMember(email: string, action: string, name = "") {
    try {
      const response: AxiosResponse = await this.memberService.removeMember(
        email
      );

      if (response.data.ok) {
        await this.activePlanStore.fetchActivePlan(true);
        if (action === "remove") {
          this.removeUser = name;
          this.removeSuccessModalOpen = true;
        } else {
          this.removeUser = email;
          this.cancelSuccessModalOpen = true;
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleMenuSelect(id: string, email: string, name: string) {
    if (id === "remove") {
      this.handleRemoveMember(email, "remove", name);
    } else if (id === "resend") {
      this.handleResendInvite(email);
    } else if (id === "cancel") {
      this.handleRemoveMember(email, "cancel");
    }
  }

  async handleResendInvite(email: string) {
    try {
      const response: AxiosResponse = await this.memberService.resendInvite(
        email
      );

      if (response.data.ok) {
        this.handleModalClose(false);
        this.reInviteEmail = email;
        this.reInviteSuccessModalOpen = true;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  }

  setTableData(activePlan: ActivePlan) {
    if (activePlan) {
      const consumers = activePlan.subscription.consumers.filter(
        (consumer: Consumer) =>
          consumer.status === "active" || consumer.status === "reserved"
      );
      this.tableData = consumers;
    }
  }

  setOwner(activePlan: ActivePlan) {
    this.owner = activePlan.owner;
  }

  handleInviteTeacher() {
    this.inviteTeacherModalOpen = true;
  }

  @Watch("searchText")
  searchTextWatcher(text: string) {
    const consumers = this.activePlan.subscription.consumers;
    if (text) {
      this.statusFilterValue = "all";
      this.typeFilterValue = "all";
      this.tableData = consumers.filter((consumer: Consumer) =>
        consumer.name?.toLowerCase().includes(text.toLowerCase())
      );
    } else {
      this.setTableData(this.activePlan);
    }
  }

  handleTypeFilter(value: string) {
    this.statusFilterValue = "all";
    const consumers = this.activePlan.subscription.consumers;
    if (value === "all") {
      this.setTableData(this.activePlan);
    } else {
      this.tableData = consumers.filter((consumer: Consumer) => {
        if (value === "owner") {
          return consumer.name === this.owner.name;
        } else {
          return (
            consumer.name !== this.owner.name &&
            (consumer.status === "active" || consumer.status === "reserved")
          );
        }
      });
    }
  }

  handleStatusFilter(value: string) {
    this.typeFilterValue = "all";
    const consumers = this.activePlan.subscription.consumers;
    if (value === "all") {
      this.setTableData(this.activePlan);
    } else {
      this.tableData = consumers.filter(
        (consumer: Consumer) => consumer.status === value
      );
    }
  }

  handleClearFilters() {
    this.searchText = "";
    this.typeFilterValue = "all";
    this.statusFilterValue = "all";
    this.setTableData(this.activePlan);
  }

  formatDate(dateString: string, format: string) {
    return formatDate(dateString, format);
  }

  setSeatCount(activePlan: ActivePlan) {
    const seatCount = getSeatCount(activePlan.subscription.consumers);
    this.availableSeats = seatCount.total - seatCount.occupied;
  }

  handleModalClose(value: boolean) {
    this.inviteTeacherModalOpen = value;
  }
}
</script>

<style scoped lang="scss">
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
