import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1479c3cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "flex items-center gap-6 mb-6" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "h-auto ellipsis" }
const _hoisted_5 = {
  key: 0,
  class: "px-2 py-[1px] ml-2 border border-solid rounded-lg border-flohh-secondary-dark-blue text-flohh-text-caption"
}
const _hoisted_6 = { class: "center-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconizeInput = _resolveComponent("IconizeInput")!
  const _component_FormDropdown = _resolveComponent("FormDropdown")!
  const _component_TertiaryButton = _resolveComponent("TertiaryButton")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_Column = _resolveComponent("Column")!
  const _component_StatusBadge = _resolveComponent("StatusBadge")!
  const _component_DropdownButton = _resolveComponent("DropdownButton")!
  const _component_Table = _resolveComponent("Table")!
  const _component_InviteTeacherModal = _resolveComponent("InviteTeacherModal")!
  const _component_SuccessModal = _resolveComponent("SuccessModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_IconizeInput, {
          modelValue: _ctx.searchText,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
          icon: "searchIcon",
          placeholder: "Enter name",
          class: "flex-[2] text-flohh-text-body"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_FormDropdown, {
          modelValue: _ctx.typeFilterValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.typeFilterValue) = $event)),
          options: _ctx.typeFilter,
          optionLabel: "label",
          optionValue: "value",
          itemSize: 46,
          class: "flex-1",
          onOnUpdate: _ctx.handleTypeFilter
        }, null, 8, ["modelValue", "options", "onOnUpdate"]),
        _createVNode(_component_FormDropdown, {
          modelValue: _ctx.statusFilterValue,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.statusFilterValue) = $event)),
          options: _ctx.statusFilter,
          optionLabel: "label",
          optionValue: "value",
          itemSize: 46,
          class: "flex-1",
          onOnUpdate: _ctx.handleStatusFilter
        }, null, 8, ["modelValue", "options", "onOnUpdate"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_TertiaryButton, {
            label: "Clear",
            class: "underline",
            onClick: _ctx.handleClearFilters
          }, null, 8, ["onClick"]),
          (
            _ctx.activePlan.subscription.plan.slug === 'teams' ||
            _ctx.activePlan.subscription.plan.slug === 'organisation'
          )
            ? (_openBlock(), _createBlock(_component_PrimaryButton, {
                key: 0,
                label: "Invite Teacher",
                class: "min-w-[150px]",
                onClick: _ctx.handleInviteTeacher
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_Table, {
        tableData: _ctx.tableData,
        tableKey: "members-list"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "name",
            header: "Member",
            sortable: "",
            style: { minWidth: '350px', maxWidth: '350px', width: '350px' }
          }, {
            body: _withCtx((cell) => [
              _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(cell.data.name || cell.data.email) + " ", 1),
                (cell.data.email === _ctx.accountInfo.email)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "You"))
                  : _createCommentVNode("", true)
              ])), [
                [
                  _directive_tooltip,
                  cell.data.name || cell.data.email,
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Role",
            style: { minWidth: '200px', maxWidth: '200px', width: '200px' }
          }, {
            body: _withCtx((cell) => [
              _createTextVNode(_toDisplayString(cell.data.email === _ctx.accountInfo.email
            ? "Subscription Owner"
            : "Teacher"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "dateAdded",
            header: "Date Added",
            sortable: "",
            style: { minWidth: '150px', maxWidth: '150px', width: '150px' }
          }, {
            body: _withCtx((cell) => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(cell.data.dateAdded, "dd MMM yyyy")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Status",
            style: {
          paddingTop: '10px',
          paddingBottom: '10px',
          minWidth: '150px',
          maxWidth: '150px',
          width: '150px',
        }
          }, {
            body: _withCtx((cell) => [
              _createVNode(_component_StatusBadge, {
                status: cell.data.status
              }, null, 8, ["status"])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, { style: { minWidth: '50px', maxWidth: '50px', width: '50px' } }, {
            body: _withCtx((cell) => [
              _createElementVNode("div", _hoisted_6, [
                (
                _ctx.owner.name !== cell.data.name &&
                _ctx.activePlan.ownershipType === 'owner'
              )
                  ? (_openBlock(), _createBlock(_component_DropdownButton, {
                      key: 0,
                      icon: "moreOptions",
                      items: 
                cell.data.status === 'reserved'
                  ? _ctx.dropdownItemsInvited
                  : _ctx.dropdownItemsActive
              ,
                      loading: _ctx.actionProcessing,
                      onOnSelect: (id) => _ctx.handleMenuSelect(id, cell.data.email, cell.data.name)
                    }, null, 8, ["items", "loading", "onOnSelect"]))
                  : (
                _ctx.owner.name !== cell.data.name &&
                _ctx.activePlan.ownershipType === 'consumer' &&
                cell.data.status === 'reserved'
              )
                    ? (_openBlock(), _createBlock(_component_DropdownButton, {
                        key: 1,
                        icon: "moreOptions",
                        items: _ctx.dropdownItemsForMembers,
                        loading: _ctx.actionProcessing,
                        onOnSelect: (id) => _ctx.handleMenuSelect(id, cell.data.email, cell.data.name)
                      }, null, 8, ["items", "loading", "onOnSelect"]))
                    : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["tableData"])
    ]),
    _createVNode(_component_InviteTeacherModal, {
      modelValue: _ctx.inviteTeacherModalOpen,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inviteTeacherModalOpen) = $event)),
      availableSeats: _ctx.availableSeats,
      onOnClose: _ctx.handleModalClose
    }, null, 8, ["modelValue", "availableSeats", "onOnClose"]),
    _createVNode(_component_SuccessModal, {
      modelValue: _ctx.removeSuccessModalOpen,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.removeSuccessModalOpen) = $event)),
      title: "Teacher Removed",
      subtitle: `You have successfully removed ${_ctx.removeUser} from you team.`,
      onOnClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removeSuccessModalOpen = false))
    }, null, 8, ["modelValue", "subtitle"]),
    _createVNode(_component_SuccessModal, {
      modelValue: _ctx.cancelSuccessModalOpen,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cancelSuccessModalOpen) = $event)),
      title: "Invite Cancelled",
      subtitle: `You have successfully cancelled the invite to ${_ctx.removeUser}.`,
      onOnClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.cancelSuccessModalOpen = false))
    }, null, 8, ["modelValue", "subtitle"]),
    _createVNode(_component_SuccessModal, {
      modelValue: _ctx.reInviteSuccessModalOpen,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.reInviteSuccessModalOpen) = $event)),
      title: "Invite Sent",
      subtitle: `Your invitation has been sent to ${_ctx.reInviteEmail}.`,
      onOnClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.reInviteSuccessModalOpen = false))
    }, null, 8, ["modelValue", "subtitle"])
  ], 64))
}