import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-center gap-4" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "text-flohh-text-title font-flohh-font-bold text-center" }
const _hoisted_4 = { class: "text-flohh-text-body text-center" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "text-flohh-text-body text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_HeadedModal = _resolveComponent("HeadedModal")!

  return (_openBlock(), _createBlock(_component_HeadedModal, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
    onOnClose: _ctx.handleModalClose,
    title: "Success",
    width: "35vw"
  }, _createSlots({
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", {
          innerHTML: _ctx.icons.modalSuccessIcon
        }, null, 8, _hoisted_2),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_4, [
          _createElementVNode("span", { innerHTML: _ctx.subtitle }, null, 8, _hoisted_5)
        ]),
        (_ctx.showUndoText)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
              _createTextVNode(" If you change your mind, you can undo this from the "),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleRedirect && _ctx.handleRedirect(...args))),
                class: "underline text-flohh-primary-pink"
              }, " Plan & Billing "),
              _createTextVNode(" page ")
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 2
  }, [
    (_ctx.buttonText)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(_component_PrimaryButton, {
              label: _ctx.buttonText,
              onClick: _ctx.handleClick,
              class: "!ml-auto !mr-0 !text-flohh-text-body",
              color: "flohh-secondary-green"
            }, null, 8, ["label", "onClick"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["modelValue", "onOnClose"]))
}