import axios from "axios";

try {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  axios.defaults.withCredentials = false;
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["Content-Type"] = "application/json";
} catch (error) {
  console.error(error);
}
