import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex flex-col gap-6 mb-6" }
const _hoisted_3 = { class: "flex gap-6" }
const _hoisted_4 = { class: "flex gap-6" }
const _hoisted_5 = { class: "flex gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_SelectCountry = _resolveComponent("SelectCountry")!
  const _component_SelectState = _resolveComponent("SelectState")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_SuccessModal = _resolveComponent("SuccessModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Text, {
          color: "text-flohh-neutral-20",
          weight: "bd",
          size: "title"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Basic Information")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSaveInfo && _ctx.handleSaveInfo(...args)), ["prevent"])),
        novalidate: "",
        class: "flex flex-col gap-6 mb-6"
      }, [
        _createVNode(_component_FormInput, {
          type: "email",
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
          label: "Email",
          readonly: true,
          invalidMessage: "Email is required",
          invalid: _ctx.emailInvalid,
          required: ""
        }, null, 8, ["modelValue", "invalid"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_FormInput, {
            type: "text",
            modelValue: _ctx.firstName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.firstName) = $event)),
            label: "First Name",
            invalidMessage: "First name is required",
            invalid: _ctx.firstNameInvalid,
            required: ""
          }, null, 8, ["modelValue", "invalid"]),
          _createVNode(_component_FormInput, {
            type: "text",
            modelValue: _ctx.lastName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.lastName) = $event)),
            label: "Last Name",
            invalidMessage: "Last name is required",
            invalid: _ctx.lastNameInvalid,
            required: ""
          }, null, 8, ["modelValue", "invalid"])
        ]),
        _createVNode(_component_FormInput, {
          type: "text",
          modelValue: _ctx.school,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.school) = $event)),
          label: "School Name",
          invalidMessage: "School name is required",
          invalid: _ctx.schoolInvalid,
          required: ""
        }, null, 8, ["modelValue", "invalid"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SelectCountry, {
            modelValue: _ctx.country,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.country) = $event)),
            label: "Country",
            invalid: _ctx.countryInvalid,
            required: ""
          }, null, 8, ["modelValue", "invalid"]),
          _createVNode(_component_SelectState, {
            modelValue: _ctx.state,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state) = $event)),
            country: _ctx.country,
            label: "State/Province"
          }, null, 8, ["modelValue", "country"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_PrimaryButton, {
            label: "Save",
            type: "submit",
            loading: _ctx.saving,
            disabled: _ctx.saving,
            color: "flohh-primary-light-pink"
          }, null, 8, ["loading", "disabled"])
        ])
      ], 32)
    ]),
    _createVNode(_component_SuccessModal, {
      modelValue: _ctx.successModalOpen,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.successModalOpen) = $event)),
      title: "Account Information Updated",
      subtitle: "Your account information has been updated successfully",
      onOnClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.successModalOpen = false))
    }, null, 8, ["modelValue"])
  ], 64))
}