import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormDropdown = _resolveComponent("FormDropdown")!

  return (_openBlock(), _createBlock(_component_FormDropdown, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    label: _ctx.label,
    options: _ctx.filteredStates,
    optionLabel: "name",
    optionValue: "name",
    filter: true,
    itemSize: 46,
    placeholder: "Select a State",
    invalid: _ctx.invalid,
    invalidMessage: "State/province is required",
    required: _ctx.required
  }, null, 8, ["modelValue", "label", "options", "invalid", "required"]))
}