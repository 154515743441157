<template>
  <HeadedModal
    v-model="open"
    @onClose="handleModalClose"
    title="Add New Payment"
    subtitle="Enter your payment details to update billing"
    width="50vw"
  >
    <template #body>
      <p class="mb-6 text-flohh-text-title font-flohh-font-bold">
        Credit card details
      </p>
      <form @submit.prevent="handleSubmit" class="flex flex-col gap-6">
        <div ref="cardElementRef"></div>
        <FormSwitch v-model="isDefault" label="Set as my default card" />
        <hr />
        <PrimaryButton
          type="submit"
          label="Submit"
          class="ml-auto text-flohh-text-body"
          color="flohh-secondary-green"
          :disabled="isSubmitting"
          :loading="isSubmitting"
        />
      </form>
    </template>
  </HeadedModal>
  <SuccessModal
    v-model="successModalOpen"
    title="Card Payment Method Added"
    subtitle="Your new payment method has been added successfully."
    @onClose="handleModalClose"
  />
  <FailedModal
    v-model="failedModalOpen"
    title="Payment Failed"
    subtitle="We are sorry, there was an error processing your payment. Please try again."
    @onClose="handleModalClose"
  />
</template>

<script lang="ts">
import { Component, Vue, Model, Ref, Watch } from "vue-facing-decorator";
import { icons } from "@/const/icons";
import { AxiosResponse } from "axios";
import PlanAndBillingService from "@/services/PlanAndBillingService";
import {
  Stripe,
  loadStripe,
  StripeElements,
  StripeCardElement,
} from "@stripe/stripe-js";

import HeadedModal from "@/components/utilities/AppModals/HeadedModal.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SuccessModal from "@/components/utilities/AppModals/SuccessModal.vue";
import FormInput from "@/components/utilities/AppFormFields/FormInput.vue";
import FormSwitch from "@/components/utilities/AppFormFields/FormSwitch.vue";
import FailedModal from "@/components/utilities/AppModals/FailedModal.vue";

@Component({
  components: {
    HeadedModal,
    PrimaryButton,
    SuccessModal,
    FormInput,
    FailedModal,
    FormSwitch,
  },
})
export default class AddCardModal extends Vue {
  private planAndBillingService: PlanAndBillingService =
    new PlanAndBillingService();
  icons = icons;

  @Model({
    type: Boolean,
    required: true,
    default: false,
  })
  open!: boolean;

  isSubmitting = false;
  isDefault = false;
  successModalOpen = false;
  failedModalOpen = false;

  publishableKey = process.env.VUE_APP_STRIPE_KEY;

  @Ref("cardElementRef")
  cardElementRef!: HTMLElement | null;

  stripe: Stripe | null = null;
  elements: StripeElements | null = null;
  cardElement!: StripeCardElement | null;

  stripePromise = loadStripe(this.publishableKey);

  @Watch("open")
  openWatcher(value: boolean) {
    if (value) {
      this.initializeStripElements();
    }
  }

  async initializeStripElements() {
    this.stripe = await this.stripePromise;
    if (this.stripe) {
      this.elements = this.stripe.elements();

      if (this.elements) {
        this.handleCreateElement(this.elements);
        if (this.cardElement && this.cardElementRef) {
          this.cardElement.mount(this.cardElementRef);
        }
      }
    }
  }

  async handleSaveCard(cardId: string) {
    try {
      this.isSubmitting = false;

      const response: AxiosResponse =
        await this.planAndBillingService.createCardPaymentMethod(
          cardId,
          this.isDefault
        );

      if (response.data.ok) {
        this.$emit("onClose", false);
        this.successModalOpen = true;
        this.$emit("onCreateSuccess");
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      this.failedModalOpen = true;
    } finally {
      this.isSubmitting = false;
    }
  }

  async handleSubmit() {
    try {
      this.isSubmitting = true;

      if (this.stripe && this.cardElement) {
        const { paymentMethod, error } = await this.stripe.createPaymentMethod({
          type: "card",
          card: this.cardElement,
        });
        if (!error) {
          this.handleSaveCard(paymentMethod.id);
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      console.error(error);
      this.failedModalOpen = true;
    } finally {
      this.isSubmitting = false;
    }
  }

  handleCreateElement(elements: StripeElements) {
    this.cardElement = elements.create("card", {
      hidePostalCode: true,
      style: {
        base: {
          color: "#333333",
          fontSize: "16px",
          fontFamily: '"Poppins", "sans-serif"',
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "#AAB7C4",
          },
        },
        invalid: {
          color: "#FF0000",
          iconColor: "#FF0000",
        },
      },
    });
  }

  handleModalClose() {
    this.successModalOpen = false;
    this.failedModalOpen = false;
    this.$emit("onClose", false);
  }
}
</script>

<style scoped lang="scss"></style>
