<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    class="headed-modal"
    :class="[fullScreen ? '!max-w-[calc(100%_-_50px)] !w-full !h-full' : '']"
    :style="{ width: width, minWidth: '800px', zIndex: 1000 }"
    :closable="false"
    :draggable="true"
    :pt="{
      header: { class: '!pt-10 !px-10 !flex-col' },
      content: { class: '!px-10 h-full' },
      footer: { class: '!px-10 !pb-10' },
    }"
  >
    <template #header>
      <slot name="header">
        <div class="w-full" v-for="(step, index) in steps" :key="index">
          <div class="w-full" v-if="currentPage === index">
            <div class="flex items-center justify-between w-full">
              <div>
                <h6 class="text-flohh-h6 font-flohh-font-bold">
                  {{ step.title }}
                </h6>
                <p class="text-flohh-text-caption font-flohh-font-medium">
                  {{ step.subtitle }}
                </p>
              </div>
              <button
                class="cursor-pointer hover:scale-[1.1]"
                @click="handleClickClose"
              >
                <span v-html="icons.closeIconBlack" />
              </button>
            </div>
            <hr class="mt-6" />
          </div>
        </div>
      </slot>
    </template>
    <slot name="body" />
    <template #footer>
      <hr class="mb-6" />
      <div v-for="(step, index) in steps" :key="index">
        <div v-if="currentPage === index" class="flex justify-between">
          <button
            v-if="currentPage > 0"
            @click="() => handleGoBack(currentPage - 1)"
            class="flex gap-2"
          >
            <span v-html="icons.backArrowIcon" /> Go Back
          </button>
          <span v-else></span>
          <div class="flex justify-end gap-4">
            <SecondaryButton
              v-if="step.secondaryBtnLabel"
              :label="step.secondaryBtnLabel"
              color="flohh-neutral-20"
              @click="handleSecondaryAction"
              class="border-flohh-neutral-20"
            />
            <PrimaryButton
              :label="step.primaryBtnLabel"
              :color="buttonColor"
              @click="handlePrimaryAction"
              class="!mr-0"
              :loading="processing"
              :disabled="processing"
            />
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Model, Component, Prop } from "vue-facing-decorator";
import Dialog from "primevue/dialog";
import { icons } from "@/const/icons";
import { IWizardModalStep } from "@/components/utilities/type";

import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import SecondaryButton from "@/components/utilities/AppButtons/SecondaryButton.vue";

@Component({ components: { Dialog, PrimaryButton, SecondaryButton } })
export default class WizardModal extends Vue {
  icons = icons;

  @Model({
    default: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  steps!: IWizardModalStep[];

  @Prop({
    type: String,
    default: "70vw",
  })
  width!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  fullScreen!: boolean;

  @Prop({
    type: Number,
    default: 0,
  })
  currentPage!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  processing!: boolean;

  @Prop({
    type: String,
    default: "flohh-secondary-red",
  })
  buttonColor!: string;

  handleConfirm() {
    this.$emit("onConfirm");
  }

  handleClickClose() {
    this.$emit("onClose", false);
  }

  handleGoBack(goToPageNumber: number) {
    this.$emit("onGoBack", goToPageNumber);
  }

  handleSecondaryAction() {
    this.$emit("onSecondaryButtonClicked");
  }

  handlePrimaryAction() {
    this.$emit("onPrimaryButtonClicked");
  }
}
</script>

<style lang="scss"></style>
