import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResetPasswordComponent = _resolveComponent("ResetPasswordComponent")!
  const _component_AuthenticationComponent = _resolveComponent("AuthenticationComponent")!

  return (_openBlock(), _createBlock(_component_AuthenticationComponent, { title: "Enter your new password to reset" }, {
    default: _withCtx(() => [
      _createVNode(_component_ResetPasswordComponent)
    ]),
    _: 1
  }))
}