const formatCurrency = (number: number, locale = "en-AU", currency = "AUD") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

/*
    USAGE:
    
    const plan = ActivePlan;
    const type = "monthly";
    const getTotalPrice = false; (optional)
    const withAUD = true; (optional)

    findAndFormatPrice(activePlan, type, getTotalPrice, withAUD)

    Return Type: String
    Return Value: $18.00 AUD | $18.00
*/
import { ActivePlan, Price } from "@/components/PlanAndBilling/type";
import { usePlansStore } from "@/stores/plans";

export const findAndFormatPrice = (
  activePlan: ActivePlan,
  type: "monthly" | "yearly" | "one-time",
  getTotalPrice = false,
  withAUD = false
) => {
  const { pricingTable } = usePlansStore();
  const totalSeat = activePlan.subscription.consumers.length;

  if (pricingTable && (type === "monthly" || type === "yearly")) {
    const selectedPlan = pricingTable[type].find(
      (pricing: Price) => totalSeat <= pricing.maxSeat
    );

    if (selectedPlan) {
      const price = getTotalPrice
        ? totalSeat * selectedPlan.price
        : selectedPlan.price;

      if (withAUD) {
        return `${formatCurrency(price)} AUD`;
      } else {
        return `${formatCurrency(price)}`;
      }
    }
  }
};

/*
    USAGE:
    
    const plan = Plan;
    const withAUD = true; (optional)

    formatPrice(activePlan, withAUD)

    Return Type: String
    Return Value: $18.00 AUD | $18.00
*/
export const formatPrice = (price: number, withAUD = false) => {
  if (withAUD) {
    return `${formatCurrency(price)} AUD`;
  } else {
    return `${formatCurrency(price)}`;
  }
};
