<template>
  <div v-if="token" class="max-w-[350px] flex flex-col gap-10 w-full">
    <OneTimePasswordComponent :token="token" />
  </div>
  <div v-else class="max-w-[350px] flex flex-col gap-10 w-full">
    <h6 class="font-flohh-font-bold text-flohh-h6 text-[#404040] text-center">
      Verify Your Account
    </h6>
    <form
      novalidate
      @submit.prevent="handleSubmit"
      class="flex flex-col w-full gap-6"
    >
      <div>
        <FormInput
          v-model="email"
          type="email"
          label="Email"
          placeholder="Enter Email"
          class="!border-flohh-neutral-85 !py-[10px] !px-6"
        />
        <FormPasswordInput
          v-model="password"
          label="Password"
          placeholder="Enter Password"
          class="!border-flohh-neutral-85 !py-[10px] !px-6"
        />
      </div>
      <div>
        <p class="text-center text-flohh-text-caption">
          Forgot your password?
          <button
            type="button"
            class="underline text-flohh-primary-pink"
            @click="handleRequestResetPassword"
          >
            Reset password
          </button>
        </p>
      </div>
      <div class="flex justify-end gap-2 px-6">
        <SecondaryButton
          color="flohh-neutral-20"
          @click="handleGoBack"
          class="border-flohh-neutral-20"
        >
          <span v-html="icons.backArrowIcon" />Go Back</SecondaryButton
        >
        <PrimaryButton
          color="flohh-secondary-green"
          type="submit"
          :loading="loggingIn"
          :disabled="loggingIn"
        >
          <span
            v-if="!loggingIn"
            v-html="icons.checkIcon"
          />Submit</PrimaryButton
        >
      </div>
    </form>
  </div>
  <FailedModal
    v-model="failedModalOpen"
    :title="failedModalTitle"
    :subtitle="failedModalSubtitle"
    @onClose="failedModalOpen = false"
  />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { icons } from "@/const/icons";
import AuthenticationService from "@/services/AuthenticationService";
import { AxiosResponse, AxiosError } from "axios";
import { ErrorResponseData } from "@/components/Authentication/type";

import FormInput from "@/components/utilities/AppFormFields/FormInput.vue";
import FormPasswordInput from "@/components/utilities/AppFormFields/FormPasswordInput.vue";
import SecondaryButton from "@/components/utilities/AppButtons/SecondaryButton.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";
import OneTimePasswordComponent from "@/components/Authentication/OneTimePassword/OneTimePasswordComponent.vue";
import FailedModal from "@/components/utilities/AppModals/FailedModal.vue";

@Component({
  components: {
    FormInput,
    FormPasswordInput,
    SecondaryButton,
    PrimaryButton,
    OneTimePasswordComponent,
    FailedModal,
  },
})
export default class LoginViaEmail extends Vue {
  private authenticationService: AuthenticationService =
    new AuthenticationService();
  icons = icons;

  email = "";
  emailInvalid = false;

  password = "";
  passwordInvalid = false;

  loggingIn = false;
  token = "";

  failedModalOpen = false;
  failedModalTitle = "";
  failedModalSubtitle = "";

  handleGoBack() {
    this.$router.push({ name: "Login" });
  }

  validateForm() {
    this.emailInvalid = this.email === "";
    this.passwordInvalid = this.password === "";

    return !this.emailInvalid && !this.passwordInvalid;
  }

  async handleSubmit() {
    try {
      const isValid = this.validateForm();

      if (!isValid) {
        this.failedModalTitle = "Unable to Login";
        this.failedModalSubtitle = "Invalid credentials";
        this.failedModalOpen = true;
        return;
      }

      this.loggingIn = true;

      const response: AxiosResponse = await this.authenticationService.login(
        this.email,
        this.password
      );

      if (response && response.data.ok) {
        this.token = response.data.data.accessToken;
      } else {
        throw new Error();
      }
    } catch (error) {
      const errorData = error as AxiosError;
      const response = errorData.response?.data as ErrorResponseData;

      if (response) {
        this.failedModalTitle = "Unable to Login";
        this.failedModalSubtitle = response.message;
        this.failedModalOpen = true;
        console.error(response.message);
      }
    } finally {
      this.loggingIn = false;
      this.password = "";
    }
  }

  handleRequestResetPassword() {
    this.$router.push({ name: "ForgotPassword" });
  }
}
</script>

<style scoped lang="scss"></style>
