import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginComponent = _resolveComponent("LoginComponent")!
  const _component_AuthenticationComponent = _resolveComponent("AuthenticationComponent")!

  return (_openBlock(), _createBlock(_component_AuthenticationComponent, { title: "For added security, please re-enter your password to access your account and billing details." }, {
    default: _withCtx(() => [
      _createVNode(_component_LoginComponent)
    ]),
    _: 1
  }))
}