<template>
  <p
    v-bind="$attrs"
    :class="[
      {
        'font-flohh-font-bold': weight === 'bd',
        'font-flohh-font-medium': weight === 'md',
        'font-flohh-font-regular': weight === 'rg',
        'text-flohh-text-title': size === 'title',
        'text-flohh-text-body': size === 'body',
        'text-flohh-text-subtitle': size === 'subtitle',
        'text-flohh-text-caption': size === 'caption',
        'text-flohh-text-small': size === 'small',
      },
      color,
    ]"
  >
    <slot>{{ label }}</slot>
  </p>
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";

@Component({ components: {} })
export default class Text extends Vue {
  @Prop({
    type: String,
    default: "md",
    validator: (value) => {
      return ["bd", "md", "rg"].includes(value);
    },
  })
  weight!: string;

  @Prop({
    type: String,
    default: "title",
    validator: (value) => {
      return ["title", "body", "subtitle", "caption", "small"].includes(value);
    },
  })
  size!: string;

  @Prop({
    type: String,
    default: "Text Component",
  })
  label!: string;

  @Prop({
    type: String,
    default: "",
  })
  color!: string;
}
</script>
