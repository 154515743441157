<template>
  <div :class="`py-[6px] px-[14px] ${bgColor} rounded-lg inline-block`">
    <Text weight="md" size="small" :label="text ? text : label" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import { statusList } from "@/const/statuses";
import { StatusList } from "@/const/type";

import AppTypographyText from "@/components/utilities/AppTypographies/AppTypographyText.vue";
import Text from "@/components/utilities/AppTypographies/Text.vue";

@Component({ components: { AppTypographyText, Text } })
export default class StatusBadge extends Vue {
  @Prop({
    type: String,
    default: "",
    required: true,
  })
  status!: string;

  @Prop({
    type: String,
    default: "",
  })
  text!: string;

  bgColor = "";
  label = "";

  variants: StatusList[] = statusList;

  @Watch("status")
  statusWatcher(value: string) {
    this.getStatusInfo(value);
  }

  mounted() {
    this.getStatusInfo(this.status);
  }

  getStatusInfo(status: string) {
    const selected = this.variants.find(
      (item: StatusList) => item.status === status
    );
    this.bgColor = selected ? selected.color : "";
    this.label = selected ? selected.label : "";
  }
}
</script>

<style scoped lang="scss"></style>
