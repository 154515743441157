import { Invoice } from "@/components/PlanAndBilling/type";
export const sortBillingHistoryTable = (filter: string, data: Invoice[]) => {
  if (filter === "date_ascending") {
    return data.sort((a: Invoice, b: Invoice) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();

      return dateA - dateB;
    });
  } else if (filter === "date_descending") {
    return data.sort((a: Invoice, b: Invoice) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();

      return dateB - dateA;
    });
  } else if (filter === "amount_descending") {
    return data.sort(
      (a: Invoice, b: Invoice) => b.totalAmount.value - a.totalAmount.value
    );
  } else if (filter === "amount_ascending") {
    return data.sort(
      (a: Invoice, b: Invoice) => a.totalAmount.value - b.totalAmount.value
    );
  }
  return [];
};
