<template>
  <div class="flex flex-wrap h-screen gradient-bg">
    <div class="heading">
      <div class="p-10 z-[1]">
        <h6 class="font-flohh-font-bold text-flohh-h6 text-[#404040]">
          {{ title }}
        </h6>
      </div>
    </div>
    <div class="component">
      <div
        class="px-10 py-8 max-w-[600px] mx-auto flex-2 flex gap-40 flex-col items-center"
      >
        <div class="flex flex-col items-center justify-center w-full gap-10">
          <img src="@/assets/authenticate-page-logo.png" alt="logo" />
          <hr class="w-full max-w-[500px]" />
          <slot></slot>
        </div>
        <div>
          <p class="text-flohh-text-caption font-flohh-font-medium">
            Learn More About Flohh |
            <a
              :href="`${PUBLIC_SITE_URL}/privacy`"
              target="_blank"
              class="cursor-pointer hover:underline"
              >Privacy Policy</a
            >
            |
            <a
              :href="`${PUBLIC_SITE_URL}/terms`"
              target="_blank"
              class="cursor-pointer hover:underline"
              >Terms of Use</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: {},
})
export default class AuthenticationComponent extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  title!: string;

  PUBLIC_SITE_URL = process.env.VUE_APP_PUBLIC_SITE_URL;
}
</script>

<style scoped lang="scss">
.heading {
  flex: 1 375px;
  display: grid;
  place-items: center;
  height: 100%;
}
.component {
  flex: 1 375px;
  background: #ffffff;
  height: 100%;
  overflow: auto;
  display: grid;
  place-items: center;
}
.gradient-bg {
  background-image: url("../../assets/authentication-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
