<template>
  <div class="flex items-center justify-between">
    <h6 class="text-flohh-text-title font-flohh-font-bold">
      Calculate For Your Team
    </h6>
    <div className="flex justify-center gap-2">
      <div class="flex flex-col items-center gap-2">
        <p class="text-flohh-text-body font-flohh-font-semi-bold">
          How many people?
        </p>
        <div class="input-container">
          <FormInput
            v-model="quantity"
            type="number"
            class="!rounded-none !outline-none font-flohh-font-semi-bold text-flohh-text-caption !border-none !p-0 max-w-[100px]"
            :maxNumber="10000"
          />
          <p
            class="absolute pr-[3] text-[11.5px] font-flohh-font-semi-bold top-[50%] translate-y-[-50%] right-4"
          >
            People
          </p>
        </div>
      </div>
      <div class="flex flex-col items-center gap-2">
        <p class="text-flohh-text-body font-flohh-font-semi-bold">
          <span class="text-flohh-secondary-green-dark">Save 6 months</span>
          with yearly
        </p>
        <div class="buttons-container">
          <PrimaryButton
            @click="() => handleTogglePlan('monthly')"
            id="monthly-btn"
            label="Monthly"
            :color="
              planSelected === 'monthly'
                ? 'flohh-secondary-green'
                : 'transparent'
            "
            class="flohh-font-semi-bold min-w-[120px] font-[11.5px]"
          />
          <PrimaryButton
            @click="() => handleTogglePlan('yearly')"
            id="yearly-btn"
            label="Yearly"
            :color="
              planSelected === 'yearly'
                ? 'flohh-secondary-green'
                : 'transparent'
            "
            class="flohh-font-semi-bold min-w-[120px] font-[11.5px]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";

import FormInput from "@/components/utilities/AppFormFields/FormInput.vue";
import PrimaryButton from "@/components/utilities/AppButtons/PrimaryButton.vue";

@Component({
  components: { FormInput, PrimaryButton },
})
export default class CalculateToolsComponent extends Vue {
  quantity!: number;
  planSelected = "monthly";

  @Watch("quantity")
  quantityWatcher(quantity: number) {
    this.$emit("onSeatChange", quantity);
  }

  handleTogglePlan(plan: string) {
    this.planSelected = plan;
    this.$emit("onPlanSelected", plan);
  }
}
</script>

<style scoped lang="scss">
.input-container {
  border: 0.86px solid #394c6026;
  padding-top: 14px;
  padding-right: 64px;
  padding-left: 32px;
  padding-bottom: 14px;
  border-radius: 14px;
  font-size: 11.5px;
  position: relative;
}

.buttons-container {
  border: 0.86px solid #394c6026;
  padding: 7px 7px;
  border-radius: 14px;
  font-size: 11.5px;
  display: flex;
  gap: 15px;
}
</style>
