import { formatWord } from "@/utils/pluralFormatter";

/*
    USAGE:
    
    const dateFrom = "2023-02-15"
    const dateTo =  "2023-02-17"

    calculateDays(dateFrom, dateTo)

    Return Type: String
    Return Value: 2 days
*/
export const calculateDays = (dateFrom: string, dateTo: string) => {
  const currentDate = new Date().getTime();

  const dateAhead =
    dateTo === "current" ? currentDate : new Date(dateTo).getTime();
  const dateLate =
    dateFrom === "current" ? currentDate : new Date(dateFrom).getTime();

  const timeDifference = dateAhead - dateLate;

  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return `${daysDifference} ${formatWord(daysDifference, "day")}`;
};

/*
    USAGE:
    
    const dateString = "2023-07-12"
    const daysBefore =  1

    getDateBefore(dateFrom, dateTo)

    Return Type: String
    Return Value: "2023-07-11"
*/
export const getDateBefore = (
  dateString: string,
  daysBefore: number
): string => {
  const specificDate = new Date(dateString).getTime();

  const daysBeforeInMs = daysBefore * 24 * 60 * 60 * 1000;

  const dateBefore = new Date(specificDate - daysBeforeInMs);

  return dateBefore.toISOString().split("T")[0];
};
