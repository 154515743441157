<template>
  <div
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    class="fixed inset-y-0 flex flex-col animate-nav z-[999] bg-flohh-neutral-100"
  >
    <div
      :class="[
        {
          expanded: isSideNavExpanded,
          collapsed: !isSideNavExpanded,
          'overflow-hidden h-full': isSideNavExpanded,
        },
      ]"
      class="sidenav border-r border-solid border-flohh-neutral-85 px-[8px] pt-[16px] bg-flohh-neutral-100"
    >
      <div
        :class="[
          { 'display-none': !isSideNavExpanded },
          'flex justify-start border-b border-flohh-neutral-85 border-solid pt-[2px] pb-[18px]',
        ]"
        v-tooltip="tooltip.hideMenu"
      >
        <img
          src="@/assets/flohh-logo-visible.svg"
          class="w-[70%] h-[32px]"
          alt="Flohh Logo"
        />
        <div
          v-if="isSideNavHovered"
          class="cursor-pointer w-[32px] h-[32px] border rounded-lg bg-flohh-neutral-95 hover:bg-flohh-neutral-85 flex items-center justify-center mx-auto"
          @click="() => handleToggleSideNav(false)"
        >
          <span v-html="icons.sideNavToggleIcon" />
        </div>
      </div>

      <div
        :class="[
          { 'display-none': isSideNavExpanded },
          'border-b border-flohh-neutral-85 border-solid pt-[2px] pb-[18px]',
        ]"
        v-tooltip="tooltip.showMenu"
      >
        <img
          :class="[
            { 'display-none': isSideNavHovered },
            'w-[100%] h-[32px] mx-auto',
          ]"
          src="@/assets/flohh-logo-hidden.svg"
          alt="Flohh Logo"
        />
        <div
          :class="[
            { 'display-none': !isSideNavHovered },
            'cursor-pointer rotate-180 w-[32px] h-[32px] border rounded-lg bg-flohh-neutral-95 hover:bg-flohh-neutral-85 flex items-center justify-center mx-auto',
          ]"
          @click="() => handleToggleSideNav(true)"
        >
          <span v-html="icons.sideNavToggleIcon" />
        </div>
      </div>

      <SidePanelMenus
        :navigation="navigation"
        :roles="roles"
        :expanded="isSideNavExpanded"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-facing-decorator";
import { icons as AppIcons } from "@/const/icons";
import { Navigation } from "@/components/Layout/type";
import { tooltip } from "@/const/tooltipText";
import { getNavigation } from "@/const/navigation";
import { useActivePlanStore } from "@/stores/activePlan";

import SidePanelMenus from "@/components/Layout/Partials/SidePanelMenus.vue";
import { ActivePlan } from "@/components/PlanAndBilling/type";

@Component({
  components: {
    SidePanelMenus,
  },
})
export default class SidePanelNavigation extends Vue {
  activePlanStore = useActivePlanStore();
  tooltip = tooltip;
  navigation: Navigation[] = [];

  roles!: string[];

  icons = AppIcons;
  isSideNavHovered = false;

  selectedNavigation!: string;
  visible = false;
  navigateTo = "#";
  isSideNavExpanded = false;

  activePlan!: ActivePlan;

  @Watch("activePlanStore.activePlan")
  activePlanWatcher() {
    this.navigation = getNavigation();
  }

  created() {
    this.navigation = getNavigation();
  }

  handleMouseEnter() {
    this.isSideNavHovered = true;
  }

  handleMouseLeave() {
    this.isSideNavHovered = false;
  }

  handleToggleSideNav(value: boolean) {
    this.isSideNavExpanded = value;
    this.$emit("onNavToggle", value);
  }
}
</script>

<style lang="scss" scoped>
.animate-nav {
  transition: 100ms linear;
}
.app-info {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
  p {
    font-size: 10px;
  }
}

.sidenav {
  transition: width 0.5s ease;
  height: 100%;
}

.sidenav.collapsed {
  width: 64px;
}

.sidenav.expanded {
  width: 244px;
}

.display-none {
  display: none;
}
</style>
