import { defineStore } from "pinia";
import { ref } from "vue";
import { AccountBasicInformation } from "@/components/Account/type";
import { AxiosResponse } from "axios";
import AccountService from "@/services/AccountService";

const accountService: AccountService = new AccountService();
const lastFetch = ref<number>(Date.now());
const CACHE_DURATION = 300000; // 5 minutes

export const useAccountInfoStore = defineStore("accountInfo", () => {
  const accountInfo = ref<AccountBasicInformation | null>(null);

  const isLoading = ref(false);
  const error = ref<string | null>(null);

  const fetchAccountInfo = async (skipCache = false) => {
    const now = Date.now();
    if (
      accountInfo.value &&
      now - lastFetch.value < CACHE_DURATION &&
      !skipCache
    ) {
      return accountInfo.value;
    }

    isLoading.value = true;
    error.value = null;
    try {
      const response: AxiosResponse = await accountService.fetchAccountInfo();
      accountInfo.value = response.data.data;
      lastFetch.value = Date.now();
    } catch (err) {
      error.value = "Failed to fetch active plan";
      console.error(err);
    } finally {
      isLoading.value = false;
    }

    return accountInfo.value;
  };

  return {
    accountInfo,
    isLoading,
    error,
    fetchAccountInfo,
  };
});
