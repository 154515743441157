<template>
  <div
    class="sticky top-0 z-[50] flex h-[4.3rem] shrink-0 items-center gap-x-4 border-b border-gray-200 bg-flohh-neutral-100 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
  >
    <div class="flex items-center self-stretch justify-between w-full">
      <div>
        <p class="font-flohh-font-bold">{{ pageTitle }}</p>
        <div class="flex gap-1">
          <div
            v-for="(breadcrumb, index) in breadcrumbs"
            :key="index"
            class="flex gap-1 font-flohh-font-medium text-flohh-text-small"
          >
            <p v-if="index !== 0">/</p>
            <button @click="handleClickBreadcrumb(breadcrumb.name)">
              {{ breadcrumb.label }}
            </button>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-4">
        <p
          v-if="accountInfo"
          class="font-flohh-font-medium text-flohh-text-subtitle"
        >
          Hey {{ accountInfo.profile.firstName }}
          {{ accountInfo.profile.lastName }}!
        </p>
        <UserMenu :accountInfo="accountInfo" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-facing-decorator";
import { useAccountInfoStore } from "@/stores/accountInfo";
import { AccountBasicInformation } from "@/components/Account/type";
import { getNavigation } from "@/const/navigation";
import { Breadcrumb, Navigation } from "@/components/Layout/type";
import { RouteRecordRaw } from "vue-router";

import UserMenu from "@/components/Layout/Partials/UserMenu.vue";

@Component({ components: { UserMenu } })
export default class TopNavigation extends Vue {
  accountInfoStore = useAccountInfoStore();
  accountInfo!: AccountBasicInformation | null;
  navigations = getNavigation();

  pageTitle = "";
  breadcrumbs: Breadcrumb[] = [];

  mounted() {
    this.accountInfo = this.accountInfoStore.accountInfo;
    this.getPageInfo(this.$route.path);
  }

  @Watch("accountInfoStore.accountInfo")
  accountInfoStoreWatcher(accountInfo: AccountBasicInformation) {
    this.accountInfo = accountInfo;
  }

  @Watch("$route")
  routeWatcher(value: RouteRecordRaw) {
    this.getPageInfo(value.path);
  }

  handleClickBreadcrumb(routeName: string) {
    this.$router.push({ name: routeName });
  }

  getPageInfo(path: string) {
    const currentRoute = this.navigations.find(
      (navigation: Navigation) => navigation.link === path
    );
    if (currentRoute) {
      this.pageTitle = currentRoute.label;
      this.breadcrumbs = currentRoute.breadcrumbs;
    }
  }
}
</script>
