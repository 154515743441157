import { defineStore } from "pinia";
import { ref } from "vue";
import { PendingSubscription } from "@/components/PlanAndBilling/type";
import { AxiosResponse } from "axios";

import PlanAndBillingService from "@/services/PlanAndBillingService";

const planAndBillingService = new PlanAndBillingService();
const lastFetch = ref<number>(Date.now());
const CACHE_DURATION = 300000; // 5 minutes

export const usePendingSubscriptionStore = defineStore(
  "pendingSubscription",
  () => {
    const pendingSubscription = ref<PendingSubscription | null>(null);
    const isLoading = ref(false);
    const error = ref<string | null>(null);

    const fetchPendingSubscription = async () => {
      const now = Date.now();
      if (pendingSubscription.value && now - lastFetch.value < CACHE_DURATION) {
        return pendingSubscription.value;
      }

      isLoading.value = true;
      error.value = null;
      try {
        const response: AxiosResponse =
          await planAndBillingService.fetchPendingSubscription();
        pendingSubscription.value = response.data.data;
        lastFetch.value = Date.now();
      } catch (err) {
        error.value = "Failed to fetch pending subscription";
        console.error(err);
      } finally {
        isLoading.value = false;
      }
      return pendingSubscription.value;
    };

    return {
      pendingSubscription,
      isLoading,
      error,
      fetchPendingSubscription,
    };
  }
);
