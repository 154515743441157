import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full relative" }
const _hoisted_2 = {
  key: 0,
  class: "absolute left-[24px] top-[50%] translate-y-[-50%] w-4"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "absolute right-[24px] top-[50%] translate-y-[-50%] w-4"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputText, _mergeProps({
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      style: {
        outline: 'none',
        boxShadow: 'none',
        paddingLeft: `${_ctx.iconPosition === 'left' ? '55px' : '10px'}`,
        paddingRight: `${_ctx.iconPosition === 'right' ? '55px' : '10px'}`,
      },
      class: "border-flohh-neutral-70 rounded-lg border-solid border outline-none py-[8px] text-flohh-neutral-20 w-full"
    }, _ctx.$attrs), null, 16, ["modelValue", "type", "placeholder", "style"]),
    (_ctx.iconPosition === 'left')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", {
            innerHTML: _ctx.icons[_ctx.icon]
          }, null, 8, _hoisted_3)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", {
            innerHTML: _ctx.icons[_ctx.icon]
          }, null, 8, _hoisted_5)
        ]))
  ]))
}