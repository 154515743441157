<template>
  <AuthenticationComponent
    title="For added security, please re-enter your password to access your account and billing details."
  >
    <LoginComponent />
  </AuthenticationComponent>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

import AuthenticationComponent from "@/components/Authentication/AuthenticationComponent.vue";
import LoginComponent from "@/components/Authentication/Login/LoginComponent.vue";

@Component({
  components: { AuthenticationComponent, LoginComponent },
})
export default class LoginView extends Vue {}
</script>

<style scoped lang="scss"></style>
