<template>
  <div class="flex flex-col w-full gap-2">
    <Text v-if="label" color="text-flohh-neutral-35" size="body" weight="md">{{
      label
    }}</Text>
    <Text
      v-if="subLabel"
      color="text-flohh-neutral-35 text-flohh-text-caption"
      size="body"
      weight="md"
      >{{ subLabel }}</Text
    >
    <InputText
      v-model="value"
      :type="type"
      :placeholder="placeholder"
      :style="{ outline: 'none', boxShadow: 'none' }"
      :class="[
        {
          'p-invalid': errorMessage && required,
        },
        'border-flohh-neutral-70 rounded-lg border-solid border outline-none px-[10px] py-[8px] text-flohh-neutral-20 text-flohh-text-body w-full',
      ]"
      :required="required"
      v-bind="$attrs"
      @keydown="blockInvalidKeys"
      @input="handleValidateInput"
      max="10000"
    />
    <p
      v-if="errorMessage"
      class="ml-1 text-flohh-error-red text-flohh-text-caption"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Model, Watch } from "vue-facing-decorator";
import Text from "@/components/utilities/AppTypographies/Text.vue";
import InputText from "primevue/inputtext";

@Component({ components: { Text, InputText } })
export default class FormInput extends Vue {
  @Model({
    type: String,
    default: "",
    required: true,
  })
  value!: string;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;

  @Prop({
    type: String,
    default: "",
  })
  subLabel!: string;

  @Prop({
    type: String,
    default: "text",
    validator: (value) => {
      return ["text", "number", "email"].includes(value);
    },
  })
  type!: string;

  @Prop({
    type: String,
    default: "",
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: "",
  })
  invalidMessage!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  required!: boolean;

  @Prop({
    type: Number,
    default: 0,
  })
  maxNumber!: number; // maximum number if type is number

  errorMessage = "";

  @Watch("invalid")
  invalidWatcher(value: boolean) {
    this.errorMessage = value ? this.invalidMessage : "";
  }

  @Watch("invalidMessage")
  invalidMessageWatcher(value: string) {
    this.errorMessage = value;
  }

  @Watch("value")
  valueWatcher(newValue: string, oldValue: string) {
    if (this.required) {
      if (!newValue && oldValue) {
        this.errorMessage = this.invalidMessage;
      } else if (newValue && !oldValue) {
        this.errorMessage = "";
      }
    }
  }

  blockInvalidKeys(event: KeyboardEvent) {
    if (
      !/[0-9]/.test(event.key) &&
      !["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(event.key) &&
      this.type === "number"
    ) {
      event.preventDefault();
    }
  }

  handleValidateInput(event: Event) {
    if (this.maxNumber > 0 && this.type === "number") {
      const input = event.target as HTMLInputElement;
      if (input.value === "0") {
        input.value = "";
        this.value = "";
      } else if (parseInt(input.value, 10) > this.maxNumber) {
        input.value = this.maxNumber.toString();
        this.value = this.maxNumber.toString();
      } else if (/^0\d+/.test(input.value)) {
        input.value = input.value.slice(1);
        this.value = input.value;
      } else {
        this.value = input.value;
      }
    }
  }
}
</script>
