import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[8px] w-full" }
const _hoisted_2 = {
  key: 1,
  class: "ml-1 text-flohh-error-red text-flohh-text-caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_Text, {
          key: 0,
          color: "text-flohh-neutral-35",
          size: "body",
          weight: "md"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Dropdown, _mergeProps({
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      options: _ctx.options,
      optionLabel: _ctx.optionLabel,
      optionValue: _ctx.optionValue,
      virtualScrollerOptions: { itemSize: _ctx.itemSize },
      placeholder: _ctx.placeholder,
      filter: _ctx.filter,
      filterPlaceholder: "Search",
      style: {
        outline: 'none',
        boxShadow: 'none',
      },
      class: [
        {
          'p-invalid': _ctx.errorMessage && _ctx.required,
        },
        'w-full border-flohh-neutral-70 rounded-lg border-solid border',
      ],
      pt: {
        item: { class: 'text-flohh-text-body text-flohh-neutral-20' },
        input: {
          class: [
            'px-[10px] py-[8px] text-flohh-text-body text-flohh-neutral-20',
          ],
        },
      },
      required: _ctx.required
    }, _ctx.$attrs, { onChange: _ctx.handleUpdate }), null, 16, ["modelValue", "options", "optionLabel", "optionValue", "virtualScrollerOptions", "placeholder", "filter", "class", "required", "onChange"]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}