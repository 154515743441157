<template>
  <div>
    <p class="text-flohh-text-title font-flohh-font-bold">
      Update your billing cycle or request to be billing via invoice
    </p>
    <p class="text-flohh-text-caption text-flohh-neutral-35">
      To change or update your billing cycle or change your payments to a yearly
      invoice. Please contact our accounts team via email at
      <a
        href="mailto: accounts@flohh.com.au"
        class="cursor-pointer text-flohh-primary-pink"
        >accounts@flohh.com.au</a
      >.
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component({
  components: {},
})
export default class BillingCycleComponent extends Vue {}
</script>

<style scoped lang="scss"></style>
