import { defineStore } from "pinia";
import { ref } from "vue";

export const useAuthStore = defineStore("auth", () => {
  const accessToken = ref<string>("");

  const storeAccessToken = async (token: string) => {
    accessToken.value = token;

    return accessToken.value;
  };

  return {
    accessToken,
    storeAccessToken,
  };
});
